<div class="flex flex-col items-center mt-20">
    <app-partner-logo [partner]="partner()"></app-partner-logo>
    <div class="mt-16 text-center">
        <div class="text-primary text-2xl font-semibold leading-8 -tracking-wide">{{ title() }}</div>
        <div class="text-secondary leading-6 mt-4" [innerHTML]="description()"></div>
    </div>
    @if (showExpectedBy() && expectedLabel()) {
        <div class="mt-10 text-primary text-center">
            You can expect {{ isCollect() ? "collection to be ready" : "delivery" }} {{ expectedIsRange() ? "between" : "no later than" }} <span class="font-semibold whitespace-nowrap">{{ expectedLabel() }}</span>
        </div>
    }
</div>