<div class="mt-4 p-4 border rounded-lg" (click)="selectMethod()"
  [ngClass]="(isActive() ? 'border-primary border-2' : '') + ' ' + (pharmacyAddress() ? 'pb-2' : 'pb-4')">
  <div class="h-6 py-1">
    <div class="w-5 h-5 border-2 border-primary rounded-full flex items-center justify-center">
      @if (isActive()) {
        <div class="w-3 h-3 bg-primary rounded-full"></div>
      }
    </div>
  </div>
  <div class="mt-2 flex items-center justify-between font-semibold text-primary leading-5">
    <div>{{ scheduleName() }}</div>
    @if (free()) {
      <div class="uppercase text-green-500">Free</div>
    } @else {
      <div>{{ price() | currency }}</div>
    }
  </div>
  <div class="mt-1 text-sm text-secondary">{{ description() }}</div>
  @if (pharmacyAddress()) {
    <div class="mt-2 pt-2 text-secondary text-xs border-t">
      <ng-icon class="text-base -mb-1" name="heroMapPin"></ng-icon>
      @if (fullFillmentMethod().selectedMethod.method == "clickAndCollect") {
        <span class="ml-1 text-green-500 whitespace-nowrap">({{ pharmacyDistance() | number:'1.1-1' }} km)</span>
      }
      <span class="ml-1">{{ pharmacyAddress() }}</span>
    </div>
  }
  @if (isActive() && (canReschedule() || canSetATL())) {
    <div class="flex items-center mt-2 gap-2">
      @if (canReschedule()) {
        <app-button size="small" (onClick)="onRescheduleClick($event)" label="Reschedule" bgColor="secondary" [outlined]="true" />
      }
      @if (canSetATL()) {
        <app-button size="small" (onClick)="onAtlClick($event)" [label]="atl() ? 'Authority to leave' : 'Someone will be home'" bgColor="secondary" [outlined]="true" />
      }
    </div>
  }
</div>

@if (showingReschedule()) {
  <app-simple-modal heading="Schedule Delivery" (close)="closeReschedule()" styleClass="h-full">
    <div class="h-full">
      <div class="pt-4">
        <div class="flex w-full overflow-x-auto px-4 gap-4 no-scrollbar">
          @for (day of scheduleDays(); track $index) {
            <div
            class="flex flex-col items-start justify-center border-primary bg-white rounded-lg whitespace-nowrap p-4 text-primary"
              [ngClass]="selectedDate()?.day === day.day ? 'border-2' : 'border'"
              (click)="selectDate($index)">
              <div class="text-lg font-medium">{{ day.day }}</div>
              <div class="text-sm">{{ day.date }}</div>
            </div>
          }
        </div>
        <div class="flex flex-col mt-4 px-4">
          @for (shift of scheduleDayShifts(); track $index) {
            <div class="flex items-center justify-start gap-2 py-2" [ngClass]="$last ? '' : 'border-b'" (click)="selectShift(shift.id)">
              <app-radio [canToggle]="false" [checked]="selectedShiftId() === shift.id" />
              <div class="label">{{ shift.shiftString }}</div>
            </div>            
          }
        </div>
      </div>
      <div class="absolute bottom-0 inset-x-0">
        <app-button (onClick)="saveReschedule()" [disabled]="selectedShiftId() == undefined" label="Update"></app-button>
      </div>
    </div>
  </app-simple-modal>
}

@if (showingAtl()) {
  <app-simple-modal heading="Delivery Details" (close)="closeAtl()" styleClass="h-full">
    <div class="h-full">
      <div class="py-3">
        <app-radio-select [(ngModel)]="atlChecked" [options]="[{ label: 'Someone will be at home', value: false }, { label: 'Authorise to leave', value: true }]" />
        @if (atlChecked()) {
          <app-longtext-input class="pl-11" [(ngModel)]="atlInstructions" label="Where should it be left?" [required]="true"></app-longtext-input>
          <app-info-box class="mt-6" type="warning" color="yellow" label="Only provide authority to leave in a private and secure location. We take no responsibility for lost items."></app-info-box>
        }
      </div>
      <div class="absolute bottom-0 inset-x-0">
        <app-button (onClick)="saveAtl()" [disabled]="atlChecked() && (atlInstructions() == undefined || atlInstructions()?.trim()?.length == 0)" label="Save"></app-button>
      </div>
    </div>
  </app-simple-modal>
}