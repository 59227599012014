"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Order = void 0;
const _BaseParseObject_1 = require("./_BaseParseObject");
const config_1 = require("../config");
const local_parse_1 = require("../local-parse");
class Order extends _BaseParseObject_1.BaseParseObject {
  get allocatedShift() {
    var _a;
    const methodName = (_a = this.fulfillmentDetails) === null || _a === void 0 ? void 0 : _a.selectedMethod.method;
    if (methodName === "OnDemand") {
      return this.fulfillmentDetails.allocatedShift;
    } else {
      return this.getTyped("allocatedShift");
    }
  }
  set allocatedShift(value) {
    var _a;
    const methodName = (_a = this.fulfillmentDetails) === null || _a === void 0 ? void 0 : _a.selectedMethod.method;
    if (methodName === "OnDemand") {
      this.fulfillmentDetails = Object.assign(Object.assign({}, this.fulfillmentDetails), {
        allocatedShift: value
      });
    }
    this.setTyped("allocatedShift", value);
  }
  get fulfillmentDetails() {
    return this.getTyped('fulfillmentDetails');
  }
  set fulfillmentDetails(value) {
    this.setTyped('fulfillmentDetails', value);
    if ((value === null || value === void 0 ? void 0 : value.selectedMethod.method) == "OnDemand") {
      const allocatedShift = value.allocatedShift;
      if (allocatedShift) {
        this.setTyped("allocatedShift", allocatedShift);
      }
    }
  }
  isComplete() {
    return this.status === 'Delivered';
  }
  isUnpaid() {
    return this.status === 'Unpaid';
  }
  isApproved() {
    return this.status === 'Approved';
  }
  isPaid() {
    return this.status === 'Paid';
  }
  isPharmacyApproved() {
    return this.status === 'Pharmacy Approved';
  }
  isReady() {
    return this.status === 'Ready';
  }
  isShippedOut() {
    return this.status === 'Shipped Out';
  }
  isDelivered() {
    return this.status === 'Delivered';
  }
  isCancelled() {
    return this.status === 'Cancelled';
  }
  isOzempic() {
    var _a;
    return ((_a = this.prescriptions) === null || _a === void 0 ? void 0 : _a.filter(p => p.status.type !== 'Rejected').some(p => p.items.some(i => i.productName.toLowerCase().includes('ozempic')))) || false;
  }
  isMounjaro() {
    var _a;
    return ((_a = this.prescriptions) === null || _a === void 0 ? void 0 : _a.filter(p => p.status.type !== 'Rejected').some(p => p.items.some(i => i.productName.toLowerCase().includes('mounjaro')))) || false;
  }
  isWegovy() {
    var _a;
    return ((_a = this.prescriptions) === null || _a === void 0 ? void 0 : _a.filter(p => p.status.type !== 'Rejected').some(p => p.items.some(i => i.productName.toLowerCase().includes('wegovy')))) || false;
  }
  isWeightLoss() {
    var _a;
    return ((_a = this.prescriptions) === null || _a === void 0 ? void 0 : _a.filter(p => p.status.type !== 'Rejected').some(p => p.items.some(i => ['ozempic', 'semaglutide', 'wegovy', 'rybelsus', 'mounjaro', 'tirzepatide'].some(wItem => i.productName.toLowerCase().includes(wItem))))) || false;
  }
  get addressGeoPoint() {
    if (this.shipping && this.shipping.address && this.shipping.address.geometry && this.shipping.address.geometry.location) {
      const location = this.shipping.address.geometry.location;
      return new local_parse_1.Parse.GeoPoint(location.lat, location.lng);
    } else {
      return undefined;
    }
  }
  get paymentTotals() {
    const paymentObjects = this.get('paymentObjects');
    let total = 0;
    if (paymentObjects) {
      for (let payment of paymentObjects.filter(po => po.status !== 'FAILED')) {
        if (payment.type == 'payment') {
          total += payment.approvedMoney.amount / 100;
        }
        if (payment.type == 'refund') {
          if (payment.hasOwnProperty('amountMoney')) {
            total -= payment.amountMoney.amount / 100;
            continue;
          }
          if (payment.hasOwnProperty('amount_money')) {
            total -= payment.amountMoney.amount / 100;
            continue;
          }
        }
      }
    }
    return total;
  }
  get pharmacyContactPhone() {
    let phone = '';
    let pharmacy = this.get('pharmacy');
    if (pharmacy) {
      let contact = pharmacy.contact;
      if (contact) {
        phone = contact.phone;
      } else {
        phone = "Not Provided";
      }
    } else {
      phone = "No Pharmacy Selected";
    }
    return phone;
  }
  get pharmacyContactEmail() {
    let email = '';
    let pharmacy = this.get('pharmacy');
    if (pharmacy) {
      let contact = pharmacy.contact;
      if (contact) {
        email = contact.email;
      } else {
        email = "Not Provided";
      }
    } else {
      email = "No Pharmacy Selected";
    }
    return email;
  }
  get pharmacyContactName() {
    let name = '';
    let pharmacy = this.get('pharmacy');
    if (pharmacy) {
      let contact = pharmacy.contact;
      if (contact) {
        name = contact.first_name + " " + contact.last_name;
      } else {
        name = "Not Provided";
      }
    } else {
      name = "No Pharmacy Selected";
    }
    return name;
  }
  get allItems() {
    var _a, _b;
    return (((_a = this.get('prescriptions')) === null || _a === void 0 ? void 0 : _a.length) || 0) + (((_b = this.get('items')) === null || _b === void 0 ? void 0 : _b.length) || 0);
  }
  get scriptsTotal() {
    let scripts = this.get('prescriptions') || [];
    let total = 0;
    for (let script of scripts) {
      if (script.status.type == 'Approved') {
        for (let item of script.items) {
          if (item.productStatus == 'Approved') {
            total += item.productQty * item.productPrice;
          }
        }
      }
    }
    return total;
  }
  get otcTotal() {
    let items = this.get('items') || [];
    let total = 0;
    for (let item of items) {
      total += item.qty * item.price;
    }
    return total;
  }
  get itemQtyTotal() {
    const items = this.get('items') || [];
    return items.reduce((a, b) => a + b.qty, 0);
  }
  get prescriptionItems() {
    const prescriptions = this.get('prescriptions') || [];
    let count = 0;
    for (const p of prescriptions) {
      if (p.status.type == 'Approved') {
        for (const item of p.items) {
          if (item.productStatus == 'Approved') {
            count += 1;
          }
        }
      }
    }
    return count;
  }
  get creditCardFee() {
    return this.creditCardPercentage * (this.get("subtotal") || 0);
  }
  get creditCardPercentage() {
    return .022;
  }
  get contact_name() {
    const contact = this.get('contact');
    return (contact === null || contact === void 0 ? void 0 : contact.first_name) + ' ' + (contact === null || contact === void 0 ? void 0 : contact.last_name);
  }
  get earnings() {
    return (this.get("subtotal") || 0) - this.creditCardFee || 0;
  }
  get paymentList() {
    const paymentObjects = this.get('paymentObjects');
    const payments = [];
    if (!paymentObjects) return [];
    for (let payment of paymentObjects) {
      if (payment.type == 'payment') {
        payments.push({
          payment: payment,
          refund: []
        });
      }
    }
    for (let payment of paymentObjects) {
      if (payment.type == 'refund' && (payment.status == 'COMPLETED' || payment.status == 'PENDING')) {
        for (let p of payments) {
          if (p.payment != null && p.payment.id == payment.paymentId) {
            p.refund.push(payment);
          }
        }
      }
    }
    return payments;
  }
  get excessAmount() {
    const total = this.total ? this.total : 0;
    let excessAmount = this.paymentTotals - total ? this.paymentTotals - total : 0;
    if (excessAmount < 0) {
      excessAmount = 0;
    }
    return excessAmount;
  }
  get amountMissing() {
    const total = this.total ? this.total : 0;
    let missingAmount = total - this.paymentTotals;
    if (missingAmount < 0) {
      missingAmount = 0;
    }
    return missingAmount;
  }
  constructor(attributes) {
    super("Order", attributes);
  }
  static async getByOrderID(orderID) {
    const order = await new local_parse_1.Parse.Query(Order).equalTo("orderID", orderID).descending("createdAt").first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
    return order;
  }
  static pointer(id) {
    return {
      __type: "Pointer",
      className: "Order",
      objectId: id
    };
  }
}
exports.Order = Order;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "additionalNote", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "associatedCard", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "cancelledAt", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "card", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "charge", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "charges", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "collectionFee", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "completedAt", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "concierge", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "conciergePostCode", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "contact", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "customer", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "deletedAt", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "deliveryDetails", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "discount", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Boolean)], Order.prototype, "flagged", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "items", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "metadata", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "number", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "orderID", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "paymentMethod", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "paymentObject", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "paymentObjects", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "pharmacy", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "prescriptions", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "programFee", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "promotion", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "promotionCustomer", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "promotionObject", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "regionalFee", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "sentNotification", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "sentPharmacyEmail", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "shipping", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "shippingFee", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "source", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "status", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "subtotal", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "total", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "totals", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "type", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "views", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Order.prototype, "tags", void 0);
local_parse_1.Parse.Object.registerSubclass("Order", Order);
