import C2U from '@chemist2u/types-client';
import { inject, Injectable } from '@angular/core';
import { TgetERXFromURLResult } from '@chemist2u/types-client/C2U/Cloud/erx';
import { TGooglePlacesPredictions } from '@chemist2u/types-client/C2U/Google/default';
import { TAllocatedShift, TOrderFulfillmentMethod, TSessionAddress, TWeekDays } from '@chemist2u/types-client/C2U/Interfaces';
import { Parse } from '@chemist2u/types-client/C2U/local-parse';
import { User } from '@chemist2u/types-client/C2U/ParseObjects';
import { PlaceAutocompleteResponseData, PlaceDetailsResponseData } from '@googlemaps/google-maps-services-js';
import { firstValueFrom, from, map } from 'rxjs';
import { ErrorService } from './error.service';
import { Cloud } from '@chemist2u/types-client/C2U/Cloud';

@Injectable({
  providedIn: 'root'
})
export class CloudService {
  private $error = inject(ErrorService);

  // Auth 
  public async doesUsernameExist(username: string) {
    return await this.$error.parseErrorWrapper(() => C2U.Cloud.run("doesUserExist", { username }));
  }
  public async logIn(username: string, password: string) {
    return await this.$error.parseErrorWrapper(() => User.logIn(username, password));
  }
  public async logOut() {
    return await this.$error.parseErrorWrapper(() => User.logOut());
  }
  public async signUp(username: string, password: string) {
    return await this.$error.parseErrorWrapper(async () => {
      await new User({ username, password }).signUp();
      await User.logIn(username, password);
    });
  }

  // Partner/Concierge
  public async fetchPartnerBrand(params: Cloud.FetchPartnerBrandParams): Promise<Cloud.FetchPartnerBrandResponse> {
    return await this.$error.parseErrorWrapper(() => C2U.Cloud.run("fetchPartnerBrand", params));
  }
  public async createPartnerOrder(params: Cloud.CreatePartnerOrderParams): Promise<Cloud.CreatePartnerOrderResponse> {
    return await this.$error.parseErrorWrapper(() => C2U.Cloud.run("createPartnerOrder", params));
  }
  public async createConciergeOrder(params: Cloud.CreateConciergeOrderParams): Promise<Cloud.CreateConciergeOrderResponse> {
    return await this.$error.parseErrorWrapper(() => C2U.Cloud.run("createConciergeOrder", params));
  }
  public async validateOrderHash(params: Cloud.ValidateOrderHashParams): Promise<Cloud.ValidateOrderHashResponse> {
    return await this.$error.parseErrorWrapper(() => C2U.Cloud.run("validateOrderHash", params));
  }
  public async completeConciergeOrder(params: Cloud.CompleteConciergeOrderParams): Promise<Cloud.CompleteConciergeOrderResponse> {
    return await this.$error.parseErrorWrapper(() => C2U.Cloud.run("completeConciergeOrder", params));
  }
  public async setPartnerOrderAtl(params: Cloud.SetPartnerOrderAtlParams): Promise<Cloud.SetPartnerOrderAtlResponse> {
    return await this.$error.parseErrorWrapper(() => C2U.Cloud.run("setPartnerOrderAtl", params));
  }
  public async confirmPartnerOrderShipping(params: Cloud.ConfirmPartnerOrderShippingParams): Promise<Cloud.ConfirmPartnerOrderShippingResponse> {
    return await this.$error.parseErrorWrapper(() => C2U.Cloud.run("confirmPartnerOrderShipping", params));
  }
  public async payPartnerOrder(params: Cloud.PayPartnerOrderParams): Promise<Cloud.PayPartnerOrderResponse> {
    return await this.$error.parseErrorWrapper(() => C2U.Cloud.run("payPartnerOrder", params));
  }

  // Address
  public async getGooglePlacesPredictions(searchString: string): Promise<PlaceAutocompleteResponseData> {
    return await this.$error.parseErrorWrapper(() => C2U.Cloud.run('googlePlacesPredictions', { searchString } as TGooglePlacesPredictions));
  }

  public async getGooglePlaceDetails(placeId: string): Promise<PlaceDetailsResponseData> {
    return await this.$error.parseErrorWrapper(() => C2U.Cloud.run('googlePlaceDetails', { placeId } as C2U.Google.TGooglePlaceDetails));
  }

  // OTP
  public async generateAuthyCode(username: string): Promise<string> {
    return await this.$error.parseErrorWrapper(() => C2U.Cloud.run('authyNewUser', { newCellPhone: username }));
  }
  public async verifyAuthyCode(code: string, newCellPhone: string): Promise<any> {
    return await this.$error.parseErrorWrapper(() => C2U.Cloud.run('authyVerify', { code, newCellPhone }));
  }

  // Token
  public async getErxData(code: string): Promise<TgetERXFromURLResult> {
    return await this.$error.parseErrorWrapper(() => C2U.Cloud.run('getERXFromURL', { code }));
  }
  
  // Serviceability
  public async userServiceability(pharmacyUserId: string, serviceable: boolean): Promise<TOrderFulfillmentMethod[]> {
    return await this.$error.parseErrorWrapper(() => Parse.Cloud.run('userServiceability', { pharmacyUserId, serviceable }));
  }

  public async getPharmacyBracketModel(address: TSessionAddress): Promise<C2U.Cloud.TgetPharmacyBracketModelResult> {
    return await this.$error.parseErrorWrapper(() => Parse.Cloud.run('getPharmacyBracketModel', { address }));
  }

  public async getOrderFulfillmentMethodsForPharmacy(address: TSessionAddress): Promise<TOrderFulfillmentMethod[]> {
    return await this.$error.parseErrorWrapper(async () => {
      const pharmacyBracketModel = await this.getPharmacyBracketModel(address);
      if (!pharmacyBracketModel?.id) return [];
      try {
        const orderFulfillmentMethods = await this.userServiceability(pharmacyBracketModel.id, pharmacyBracketModel.found);
        return orderFulfillmentMethods.sort((a, b) => {
          const order = {
            "OnDemand": 1,
            "Standard": 2,
            "clickAndCollect": 3,
            "Postal": 4,
            "PostalTemperatureControlled": 5,
          };
        
          const methodA = order[a.selectedMethod.method] || 4;
          const methodB = order[b.selectedMethod.method] || 4;
        
          return methodA - methodB;
        });
      } catch (error) {
        return [];
      }
    });
  }

  // Pharmacy Shifts
  public async getFormattedShiftsForPharmacy(pharmacyUserId: string): Promise<{ day: TWeekDays, date: string, shifts: TAllocatedShift[] }[]> {
    return await this.$error.parseErrorWrapper(() => {
      const result = from(Parse.Cloud.run('getShiftsForPharmacy', { pharmacyId: pharmacyUserId })).pipe(map(shifts => {
        const formattedShifts: { day: TWeekDays, date: string, shifts: TAllocatedShift[] }[] = [];
        for (let index = 0; index < shifts.simpleShifts.length; index++) {
          const shift = shifts.simpleShifts[index];
          const shiftIndex = formattedShifts.findIndex(s => s.day == shift.day)
          shift['shiftString'] = this.getDeliveryString(shift);
          if (this.dateCompare(shift.cutoff, new Date())) continue;
          if (shiftIndex !== -1) {
            formattedShifts[shiftIndex].shifts.push(shift)
          }
          else formattedShifts.push({
            day: shift.day,
            date: this.formatDate(shift.date),
            shifts: [shift]
          })
        }
        return formattedShifts.slice(0, 3);
      }));
      return firstValueFrom(result);
    });
  }

  // Private Date Util Functions
  private formatDate(shiftDate: Date): string {
    const dateObj = shiftDate;
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString("default", { month: "long" });

    const nthNumber = (number: number) => {
      if (number > 3 && number < 21) return "th";
      switch (number % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const date = `${day}${nthNumber(day)} ${month} `;
    return date;
  }

  private getDeliveryString(shift: TAllocatedShift): any {
    const startString = this.formatTime(shift.pickup.getHours(), shift.pickup.getMinutes());
    const endString = this.formatTime(shift.dropoff.getHours(), shift.dropoff.getMinutes());
    return `${startString} to ${endString}`
  }

  private formatTime(hours: number, minutes: number): string {
    const hour = hours % 24;
    return (hour % 12 || 12) + ":" + minutes.toString().padStart(2, '0') + (hour < 12 ? " AM" : " PM");
  }

  private dateCompare(date1: Date, date2: Date): boolean {
    return new Date(date2) > new Date(date1);
  }
}
