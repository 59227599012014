<app-title-block heading="Create medical profile"></app-title-block>
<div class="mt-2">
  <div class="text-primary text-lg font-semibold leading-7">Personal details</div>
  <div class="text-secondary text-base leading-6 mt-2">Medical profiles are used by our pharmacists to ensure correct dispensing of your medication.</div>
</div>
<div class="mt-4">
  <app-input [(value)]="fullName" type="text" label="Full name" placeholder="Your full name" [required]="true"></app-input>
</div>
<div class="mt-6">
  <app-input [(value)]="dateOfBirth" type="date" label="Date of birth" placeholder="dd/mm/yyyy" [required]="true"></app-input>
</div>
@if (age() != -1 && age() < 13) {
  <div class="mt-6">
    <app-input [(value)]="weight" type="number" label="Weight (kg)" placeholder="Weight (kg)" [required]="true"></app-input>
  </div>
}
<div class="mt-6 border-b"></div>
<div class="mt-4">
  <div class="text-primary text-lg font-semibold leading-7">Medicare</div>
  <div class="text-secondary text-base leading-6 mt-2">Adding a Medicare number will enable you to receive government PBS subsidies.</div>
</div>
<div class="mt-4">
  <app-medicare-input #medicareInput [(ngModel)]="medicare"></app-medicare-input>
</div>
<div class="mt-6 border-b"></div>
<div class="mt-4">
  <div class="text-primary text-lg font-semibold leading-7">Concessions</div>
  <div class="text-secondary text-base leading-6 mt-2">All Concession and Safety Net discounts are available to card holders.</div>
</div>
<div class="my-4">
  <div class="py-3">
    <app-checkbox #safetyNetCheck label="I have a Safety Net number"></app-checkbox>
    @if (safetyNetCheck.checked()) {
      <app-input [(value)]="safetyNet" class="mt-3" label="Safety Net number" [required]="true"></app-input>
    }
  </div>
  <div class="py-3">
    <app-checkbox #concessionCheck label="I have a Concession card"></app-checkbox>
    @if (concessionCheck.checked()) {
      <app-input [(value)]="concession" class="mt-3" label="Concession card number" [required]="true"></app-input>
    }
  </div>
</div>