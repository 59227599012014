import { computed, inject, Injectable, signal } from '@angular/core';
import { Order, User } from '@chemist2u/types-client/C2U/ParseObjects';
import { CloudService } from '../../../services/cloud.service';
import { RxState } from './rx-state.service';
import { ICustomerMedicalProfile, TOrderFulfillmentMethod, TSessionAddress } from '@chemist2u/types-client/C2U/Interfaces';
import { AmplitudeService } from '../../../services/amplitude.service';
import { Cloud } from '@chemist2u/types-client/C2U/Cloud';

@Injectable()
export class RxOrderService {
  private $cloud = inject(CloudService);
  private $amplitude = inject(AmplitudeService);

  public order = signal<Order | undefined>(undefined);
  public orderHash = signal<string | undefined>(undefined);
  public orderStatus = computed<"incomplete" | "pending" | "priced" | "paid" | "complete">(() => {
    if (!this.orderHash() || !this.order()?.shipping) {
      return "incomplete";
    } else if (this.order()?.shipping && this.order()?.status == "Unpaid") {
      return "pending";
    } else if (this.order()?.status == "Approved") {
      return "priced";
    } else if (this.order()?.status == "Paid") {
      return "paid";
    } else {
      return "complete";
    }
  });

  // Weight loss present
  public orderHasWeightLossScriptItems = computed(() => {
    const weightLossExceptionItems: Cloud.TWeightLossItems[] = ['ozempic', 'semaglutide', 'wegovy', 'rybelsus', 'mounjaro', 'tirzepatide', 'nicovape', 'veev', 'vape'];
    const prescriptions = this.order()?.prescriptions || [];
    return prescriptions?.some(prescription =>
        prescription.items.some(item =>
          weightLossExceptionItems.some(ozempicItem =>
            item.productName.toLowerCase().includes(ozempicItem.toLowerCase())
          )
        )
      );
  });

  public async createOrder(state: RxState, partnerId: string): Promise<boolean> {
    this.$amplitude.track('ORDER_CREATE_START', { partnerId });
    const address: TSessionAddress = this.buildAddressPayload(state);
    const medicalProfile = this.buildMedicalProfilePayload(state);

    try {
      if (this.orderHash()) {
        return await this.updateExistingOrder(address, medicalProfile);
      } else {
        return await this.createNewOrder(address, medicalProfile, state, partnerId);
      }
    } catch (error) {
      this.$amplitude.track('ORDER_CREATE_FAIL', { error: (error as Error).message });
      console.error('Order creation failed:', error);
      return false;
    }
  }

  private buildAddressPayload(state: RxState): TSessionAddress {
    return {
      deliveryToDoor: false,
      businessName: '',
      atl: false,
      selectedAddress: state.addressSearch?.prediction!,
      address: state.addressSearch?.placeData!,
      aptSuiteFloor: state.addressDetails?.unitNumber || "",
      deliveryNote: state.addressDetails?.deliveryNotes || "",
    };
  }

  private buildMedicalProfilePayload(state: RxState): ICustomerMedicalProfile {
    const personal = state.medicalProfilePersonal!;
    const clinical = state.medicalProfileClinical!;
    const medicare = personal.medicare;
    const cleanMedicare = medicare?.medicareCardNumber && medicare?.irn ? 
      parseInt([medicare.medicareCardNumber, medicare.irn].join("")) : 
      undefined;

    return {
      name: personal.fullName,
      dateOfBirth: personal.dateOfBirth,
      medicare_card_number: cleanMedicare,
      safetyNet: personal.safetyNet,
      concession_card_number: personal.concession,
      other_medication: clinical.hasOtherMeds,
      allergic_to_medication: clinical.hasAllergies,
      other_medication_notes: clinical.otherMeds,
      allergic_to_medication_notes: clinical.allergies,
      ...(personal.weight && { weight: personal.weight }),
    };
  }

  private async updateExistingOrder(
    address: TSessionAddress, 
    medicalProfile: ICustomerMedicalProfile
  ): Promise<boolean> {
    const orderId = this.order()!.id;
    this.$amplitude.track('ORDER_UPDATE_START', { orderId });
    
    try {
      const result = await this.$cloud.completeConciergeOrder({
        orderId,
        address,
        medicalProfileObj: medicalProfile,
      });
      
      this.updateOrderState(result.order);
      this.$amplitude.track('ORDER_UPDATE_SUCCESS', { orderId });
      return true;
    } catch (error) {
      this.$amplitude.track('ORDER_UPDATE_FAIL', { orderId, error: (error as Error).message });
      throw error;
    }
  }

  private async createNewOrder(
    address: TSessionAddress,
    medicalProfile: ICustomerMedicalProfile,
    state: RxState,
    partnerId: string
  ): Promise<boolean> {
    try {
      const result = await this.$cloud.createPartnerOrder({
        partnerShortId: partnerId,
        tokenData: state.tokenData || [],
        address,
        medicalProfileObj: medicalProfile,
      });

      this.updateOrderState(result.order);
      this.orderHash.set(result.hash);
      this.$amplitude.track('ORDER_CREATE_SUCCESS', { orderId: result.order.id });
      return true;
    } catch (error) {
      this.$amplitude.track('ORDER_CREATE_FAIL', { error: (error as Error).message });
      throw error;
    }
  }

  private updateOrderState(order: Order) {
    this.order.set(order);
  }
  
  public async setOrderAtl(atl: boolean, instructions: string | undefined) {
    const order = this.order();
    if (!order) throw new Error();
    const result = await this.$cloud.setPartnerOrderAtl({ orderId: order.id, atl, deliveryNote: instructions });
    this.updateOrderState(result.order);
  }

  public async updateFulfillmentMethod(method: TOrderFulfillmentMethod): Promise<boolean> {
    try {
      const order = this.order();
      if (!order) return false;

      const result = await this.$cloud.confirmPartnerOrderShipping({ 
        orderId: order.id, 
        fulfillmentDetails: method 
      });
      
      this.updateOrderState(result.order);
      return true;
    } catch (error) {
      console.error('Failed to update fulfillment method:', error);
      return false;
    }
  }
  
  public async payForOrder(squareCard: any) {
    const user = await User.currentAsync();
    const order = this.order();
    if (!order || !user) throw new Error();

    const orderId = order.id;
    this.$amplitude.track('ORDER_PAY_START', { orderId });

    try {
      const tokenizeResult = await squareCard.tokenize();
      if (tokenizeResult.status !== 'OK') {
        this.$amplitude.track('CARD_TOKENIZATION_FAIL');
        throw new Error('Card tokenization failed');
      }

      const result = await this.$cloud.payPartnerOrder({ 
        orderId, 
        nonce: tokenizeResult.token, 
        customerId: user.id 
      });
      
      this.updateOrderState(result.order);
      this.$amplitude.track('ORDER_PAY_SUCCESS', { orderId });
    } catch (error) {
      this.$amplitude.track('ORDER_PAY_FAIL', { orderId, error: (error as Error).message });
      throw error;
    }
  }
} 