import { Injectable, signal, inject } from '@angular/core';
import { AddressType, PlaceAutocompleteResult, PlaceData } from '@googlemaps/google-maps-services-js';
import { TMedicalProfilePersonalValue } from '../steps/medical-profile-personal/medical-profile-personal.step';
import { TMedicalProfileClinicalValue } from '../steps/medical-profile-clinical/medical-profile-clinical.step';
import { AmplitudeService } from '../../../services/amplitude.service';
import { Cloud } from '@chemist2u/types-client/C2U/Cloud';

export interface RxState {
  addressSearch?: { 
    prediction: PlaceAutocompleteResult; 
    placeData: PlaceData;
  };
  addressDetails?: { 
    unitNumber: string; 
    deliveryNotes: string;
  };
  mobileNumber?: string;
  mobileNumberVerified?: boolean;
  tokenData?: Cloud.TTokenData[];
  medicalProfilePersonal?: TMedicalProfilePersonalValue;
  medicalProfileClinical?: TMedicalProfileClinicalValue;
}

@Injectable()
export class RxStateService {
  private $amplitude = inject(AmplitudeService);

  public state = signal<RxState>({});
  
  public otp = signal<string>("");
  public termsAccepted = signal<boolean>(false);
  public passwordValid = signal<boolean>(false);
  public mobileNumberValid = signal<boolean>(false);
  public medicalProfilePersonalValid = signal<boolean>(false);
  public medicalProfileClinicalValid = signal<boolean>(false);

  public updateState(updates: Partial<RxState>) {
    this.state.update(s => ({ ...s, ...updates }));
  }

  public onAddressSelect(data: { prediction: PlaceAutocompleteResult, placeData: PlaceData }) {
    const addressComponents = data.placeData.address_components || [];
    const postalCode = addressComponents.find(comp => 
      comp.types.includes(AddressType.postal_code))?.long_name || '';
    
    this.$amplitude.track('ADDRESS_SELECTED_SEARCH', { postalCode });

    const unitNumber = addressComponents.find(comp => 
      comp.types.includes(AddressType.subpremise))?.long_name || '';

    const oldState = this.state();
    const changes = {
      addressSearch: data,
      addressDetails: {
        deliveryNotes: "",
        ...oldState.addressDetails,
        unitNumber,
      },
    };
    this.updateState(changes);
  }

  public onAddressDetailsChange(data: { unitNumber: string, deliveryNotes: string }) {
    if (data.deliveryNotes) {
      this.$amplitude.track('ADDRESS_DELIVERY_NOTES', { deliveryNotes: data.deliveryNotes });
    }
    this.updateState({ addressDetails: data });
  }

  public onMobileNumberChange(data: { value: string, valid: boolean }) {
    if (data.valid) {
      this.$amplitude.track('AUTH_LOGIN_PHONE_ENTERED', { mobileNumber: data.value });
    }
    this.mobileNumberValid.set(data.valid);
    this.updateState({
      mobileNumber: data.value,
      mobileNumberVerified: false,
    });
  }

  public onTokenDataChange(value: Cloud.TTokenData[]) {
    this.updateState({ tokenData: value });
  }

  public onMedicalProfilePersonalChange(data: { value: TMedicalProfilePersonalValue, valid: boolean }) {
    this.medicalProfilePersonalValid.set(data.valid);
    this.updateState({ medicalProfilePersonal: data.value });
  }

  public onMedicalProfileClinicalChange(data: { value: TMedicalProfileClinicalValue, valid: boolean }) {
    this.medicalProfileClinicalValid.set(data.valid);
    this.updateState({ medicalProfileClinical: data.value });
  }
} 