<div class="py-6">
  <div class="text-primary font-semibold text-2xl leading-8">
    {{ heading() }}
  </div>
  <div class="text-brand text-base mt-2">
    Powered by Chemist2U
  </div>
  @if (description()) {
    <div class="mt-2 text-xs">
      {{ description() }}
    </div>
  }
</div>

<div class="py-7">
  <app-script-slider></app-script-slider>
</div>

<div>
  <app-info-box class="-mx-3" [sanitized]="true" title="e-Script Required" [label]="infoBoxContent()"></app-info-box>
</div>

<div class="notice">
  <b>Important:</b> You are now on a Chemist2U website and subject to Chemist2U's <a [ngStyle]="{ color: rxTheme.linkColor() }"
    href="https://chemist2u.com.au/privacy-policy/" target="_blank">Privacy Policy</a> and <a [ngStyle]="{ color: rxTheme.linkColor() }"
    href="https://chemist2u.com.au/terms-conditions/" target="_blank">Terms and Conditions</a>
</div>