import { CommonModule } from '@angular/common';
import { Component, computed, input, inject } from '@angular/core';
import { PartnerLogoComponent } from "../../../../components/partner-logo/partner-logo.component";
import { Order } from '@chemist2u/types-client/C2U/ParseObjects';
import { TOrderFulfillmentMethod, TOrderFulfillmentMethodClickAndCollect, TOrderFulfillmentMethodOnDemand, TOrderFulfillmentMethodStandard } from '@chemist2u/types-client/C2U/Interfaces';
import { addMinutes } from 'date-fns';
import { AmplitudeService } from '../../../../services/amplitude.service';
import { Cloud } from '@chemist2u/types-client/C2U/Cloud';

@Component({
  selector: 'step-thank-you',
  standalone: true,
  imports: [CommonModule, PartnerLogoComponent],
  templateUrl: './thank-you.step.html',
  styleUrl: './thank-you.step.scss'
})
export class ThankYouStep {
  private $amplitude = inject(AmplitudeService);

  public partner = input<Cloud.FetchPartnerBrandResponse>();
  public order = input.required<Order>();
  public orderStatus = input.required<"incomplete" | "pending" | "priced" | "paid" | "complete">();

  private locale = 'en-AU';

  private content = new Map<"incomplete" | "pending" | "priced" | "paid" | "complete", { title: string, description: string }>([
    ["pending", { title: "Order submitted!", description: "Your order is now being reviewed and priced.<br>You'll then receive an SMS with a payment link." }],
    ["paid", { title: "Thank you!", description: "Your order is now being processed.<br>You'll will receive updates by SMS." }],
    ["complete", { title: "Order complete!", description: "Your order is complete.<br>Place another one below!" }],
  ]);

  public title = computed<string>(() => {
    const content = this.content.get(this.orderStatus());
    return content ? content.title : "Thank you!";
  });

  public description = computed<string>(() => {
    const content = this.content.get(this.orderStatus());
    return content ? content.description : "";
  });

  public showExpectedBy = computed<boolean>(() => this.orderStatus() == "paid");
  public expectedIsRange = computed<boolean>(() => ["Postal", "PostalTemperatureControlled"].includes(this.order().fulfillmentDetails?.selectedMethod.method || ""));
  public isCollect = computed<boolean>(() => this.order().fulfillmentDetails?.selectedMethod.method == "clickAndCollect");

  public expectedLabel = computed<string | undefined>(() => {
    const method = this.order().fulfillmentDetails;
    const methodName = method?.selectedMethod.method;
    if (methodName == "OnDemand" || methodName == "Standard") {
      const shift = this.getShift(method!)!;
      const pickupTime = this.formatTime(shift.pickup);
      const dropoffTime = this.formatTime(shift.dropoff);
      const formattedDate = this.formatDate(shift.date)
      return `${formattedDate} (${pickupTime} - ${dropoffTime})`;
    } else if (this.isCollect()) {
      const expectedPickupDate = (method as TOrderFulfillmentMethodClickAndCollect)!.expectedPickupDate!;
      const nextAvailableTime = addMinutes(expectedPickupDate, 0);
      const formattedDate = new Intl.DateTimeFormat(this.locale, { day: '2-digit', month: 'short' }).format(nextAvailableTime);
      const timeFormatOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
      const formattedTime = nextAvailableTime.toLocaleTimeString(this.locale, timeFormatOptions).toLowerCase();
      return `${formattedDate} at ${formattedTime}.`;
    } else if (this.expectedIsRange()) {
      const start = new Date();
      const end = new Date();
      start.setDate(start.getDate() + 1);
      end.setDate(end.getDate() + 2);
      const formattedStart = new Intl.DateTimeFormat(this.locale, { day: '2-digit', month: 'short' }).format(start);
      const formattedEnd = new Intl.DateTimeFormat(this.locale, { day: '2-digit', month: 'short' }).format(end);
      return `${formattedStart} - ${formattedEnd}`;
    }
    return undefined;
  });

  constructor() {
    this.$amplitude.track('GENERIC_NAVIGATE', { to_page: 'thank_you' });
  }

  private formatDate(date: Date) {
    return new Intl.DateTimeFormat(this.locale, { day: '2-digit', month: 'short' }).format(date);
  }

  private formatTime(date: Date) {
    return new Intl.DateTimeFormat(this.locale, { hour: 'numeric', hour12: true, minute: 'numeric' }).format(date).toLowerCase();
  }

  private getShift(method: TOrderFulfillmentMethod) {
    const methodName = method.selectedMethod.method;
    if (methodName == "OnDemand") {
      const shift = (method as TOrderFulfillmentMethodOnDemand)!.allocatedShift;
      return {
        pickup: shift?.pickup!,
        dropoff: shift?.dropoff!,
        date: shift?.date!,
      };
    } else if (methodName == "Standard") {
      const expectedDeliveryDate = (method as TOrderFulfillmentMethodStandard)!.expectedDeliveryDate!;
      return {
        pickup: expectedDeliveryDate,
        dropoff: new Date(expectedDeliveryDate.getTime() + 4 * 60 * 60 * 1000),
        date: expectedDeliveryDate,
      };
    }
    return undefined;
  }

}
