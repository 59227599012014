<app-title-block heading="Enter your mobile number" description="You will receive important notifications (including a payment link) via SMS."></app-title-block>
<div class="py-4">
  <app-input
    type="tel"
    icon="heroDevicePhoneMobile"
    placeholder="e.g. 0400 000 000"
    [showClearButtonOption]="true"
    [externalError]="mobileNumberError()"
    [value]="mobileNumber()"
    (valueChange)="onMobileNumberChange($event)" />
</div>