<div appSwipe (swipeLeft)="onSwipeLeft()" (swipeRight)="onSwipeRight()">
    <div class="w-full overflow-hidden">
        <div class="flex w-full h-[15.25rem] transition-all duration-300" [ngStyle]="{ transform: 'translateX(-' + (activeIndex() * 100) + '%)' }">
            @for (slide of slides(); track $index) {
                <div class="flex min-w-full flex-col items-center">
                    <img class="w-32 h-32 object-contain" [src]="slide.image">
                    <div class="mt-8 text-lg leading-5 text-center">
                        <div class="font-semibold text-secondary">{{ slide.title }}</div>
                        <div class="mt-2 text-black h-10 line-clamp-2 max-w-52">{{ slide.description }}</div>
                    </div>
                </div>
            }
        </div>
    </div>
    <div class="mt-4 flex gap-2 justify-center">
        @for (dot of slides(); track $index) {
        <div class="w-2 h-2 cursor-pointer rounded-full" (click)="goToSlide($index)"[ngClass]="$index == activeIndex() ? 'bg-secondary' : 'bg-neutral'"></div>
        }
    </div>
</div>