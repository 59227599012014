import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
}

@Component({
  selector: 'app-address-row',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './address-row.component.html',
  styleUrls: ['./address-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressRowComponent {
  public first = input<boolean>(false);
  public addressComponents = input<AddressComponent[] | undefined>(undefined);
  public structuredFormattingInp = input<StructuredFormatting | undefined>(undefined, { alias: 'structuredFormatting' });
  public includeUnitNumber = input<boolean>(true);

  private structuredFormatting = computed<StructuredFormatting>(() => {
    const addressComponents = this.addressComponents();
    const structuredFormatting = this.structuredFormattingInp();
    if (addressComponents != undefined) {
      return this.mapAddressComponentsToStructuredFormatting(addressComponents, this.includeUnitNumber());
    } else if (structuredFormatting != undefined) {
      return structuredFormatting;
    } else {
      throw new Error('No address inputs provided.');
    }
  });

  public addressMainText = computed<string>(() => {
    return this.structuredFormatting().main_text;
  });

  public addressSecondaryText = computed<string>(() => {
    return this.structuredFormatting().secondary_text;
  });

  private mapAddressComponentsToStructuredFormatting(components: AddressComponent[], includeUnitNumber: boolean = true): StructuredFormatting {
    let mainText = '';
    let secondaryText = '';
    
    const streetNumber = components.find(comp => comp.types.includes('street_number'))?.long_name || '';
    const route = components.find(comp => comp.types.includes('route'))?.long_name || '';
    const unitNumber = includeUnitNumber ? components.find(comp => comp.types.includes('subpremise'))?.long_name : '';
    const locality = components.find(comp => comp.types.includes('locality'))?.long_name || '';
    const adminAreaLevel1 = components.find(comp => comp.types.includes('administrative_area_level_1'))?.short_name || '';
    const country = components.find(comp => comp.types.includes('country'))?.long_name || '';
    const postalCode = components.find(comp => comp.types.includes('postal_code'))?.long_name || '';
  
    // Include unit number in the main text if it exists
    if (unitNumber) {
      mainText = `${unitNumber}/${streetNumber} ${route}`.trim();
    } else {
      mainText = `${streetNumber} ${route}`.trim();
    }
    
    secondaryText = [locality, adminAreaLevel1, country, postalCode].filter(Boolean).join(', ');
  
    return {
      main_text: mainText,
      secondary_text: secondaryText,
    };
  }
}