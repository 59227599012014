import { CommonModule, CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { NgIconComponent } from '@ng-icons/core';

type ColorType = "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "dark" | "medium" | "light" | "white";
type SizeType = "small" | "default" | "large";

type Style = { [klass: string]: any; } | null | undefined;
type Klass = string | string[] | Set<string> | Style;

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  imports: [CommonModule, NgIconComponent],
  providers: [CurrencyPipe],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  private currencyPipe = inject(CurrencyPipe);
  
  public bgColor = input<ColorType>("primary");
  public color = input<ColorType>("white");
  public size = input<SizeType>("default");
  public bold = input<boolean>(false);
  public label = input<string>("");
  public outlined = input<boolean>(false);
  public disabled = input<boolean>(false);
  public isPrice = input<boolean>(false);

  public icon = input<string | undefined>();
  public iconPosition = input<"left" | "right">("left");
  public textRight = input<string | undefined>(undefined);

  public buttonStyleOverrides = input<Style>();
  public buttonStyleClass = input<Klass>();
  public iconStyleClass = input<Klass>();

  public onClick = output<MouseEvent>();

  public textRightDisplay = computed(() => {
    return this.isPrice() ? this.currencyPipe.transform(this.textRight()) : this.textRight();
  });

  public buttonClasses = computed(() => ({
    [`btn-bg-${this.bgColor()}`]: true,
    [`btn-color-${this.color()}`]: true,
    [`btn-${this.size()}`]: true,
    [`btn-bold`]: this.bold(),
    [`btn-disabled`]: this.disabled(),
    [`btn-outlined`]: this.outlined(),
  }));

  handleClick(event: MouseEvent) {
    if (this.disabled()) return;
    this.onClick.emit(event);
  }

}