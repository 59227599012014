"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EventTracking = void 0;
const Order_1 = require("../ParseObjects/Order");
const Pharmacy_1 = require("../ParseObjects/Pharmacy");
const User_1 = require("../ParseObjects/User");
const _BaseParseObject_1 = require("./_BaseParseObject");
const config_1 = require("../config");
const local_parse_1 = require("../local-parse");
class EventTracking extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("EventTracking", attributes);
  }
  static async saveEventDetails(action, order, user, payload, source, pharmacy, customer) {
    try {
      const eventTracking = new EventTracking();
      eventTracking.set("action", action);
      eventTracking.set("payload", payload);
      if (user) {
        eventTracking.set("user", User_1.User.pointer(user.id));
        eventTracking.set("userName", user.get("username"));
      }
      if (source) eventTracking.set("source", source);
      if (order) eventTracking.set("order", Order_1.Order.pointer(order.id));
      if (pharmacy) eventTracking.set("pharmacy", Pharmacy_1.Pharmacy.pointer(pharmacy.id));
      if (customer) eventTracking.set("customer", User_1.User.pointer(customer.id));
      await eventTracking.save(null, {
        useMasterKey: config_1.C2UConfig.useMasterKey
      });
    } catch (error) {
      throw new local_parse_1.Parse.Error(local_parse_1.Parse.Error.OTHER_CAUSE, JSON.stringify(error));
    }
  }
}
exports.EventTracking = EventTracking;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], EventTracking.prototype, "order", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], EventTracking.prototype, "pharmacy", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], EventTracking.prototype, "customer", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], EventTracking.prototype, "action", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], EventTracking.prototype, "user", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], EventTracking.prototype, "payload", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], EventTracking.prototype, "userName", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], EventTracking.prototype, "source", void 0);
local_parse_1.Parse.Object.registerSubclass("EventTracking", EventTracking);
