"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Category = void 0;
const config_1 = require("../config");
const _BaseParseObject_1 = require("./_BaseParseObject");
const local_parse_1 = require("../local-parse");
class Category extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("Category", attributes);
  }
  static async getSubCategories(name) {
    const category = await new local_parse_1.Parse.Query(Category).equalTo("name", name).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
    if (!category) {
      throw new local_parse_1.Parse.Error(local_parse_1.Parse.Error.OBJECT_NOT_FOUND, "Did not find the category for name provided");
    }
    const subcategories = category.subcategories;
    if (!subcategories) {
      return [];
    }
    return await subcategories.query().equalTo("status", "Active").find({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async getCategoryByName(name) {
    return await new local_parse_1.Parse.Query(Category).equalTo("name", name).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async getCount() {
    return await new local_parse_1.Parse.Query(Category).count({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async getAll() {
    return await new local_parse_1.Parse.Query(Category).limit(await this.getCount()).find({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async getCategories(status, limit = 500, useMasterKey = true) {
    return await new local_parse_1.Parse.Query(Category).equalTo("status", status).limit(limit).find({
      useMasterKey: config_1.C2UConfig.useMasterKey && useMasterKey
    });
  }
  static pointer(id) {
    return {
      __type: "Pointer",
      className: "Category",
      objectId: id
    };
  }
}
exports.Category = Category;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Category.prototype, "canonical", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Category.prototype, "imageThumb", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Category.prototype, "name", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Category.prototype, "slug", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Category.prototype, "isFeatured", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Category.prototype, "status", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Category.prototype, "image", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Category.prototype, "subCategoryCount", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Category.prototype, "catId", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Category.prototype, "subcategories", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Category.prototype, "itemCount", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Category.prototype, "count", void 0);
local_parse_1.Parse.Object.registerSubclass("Category", Category);
