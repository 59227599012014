import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { SwipeDirective } from '../../directives/swipe.directive';

@Component({
  selector: 'app-script-slider',
  standalone: true,
  imports: [CommonModule, SwipeDirective],
  templateUrl: './script-slider.component.html',
  styleUrl: './script-slider.component.scss'
})
export class ScriptSliderComponent implements OnInit {
  private slideInterval?: any;
  public activeIndex = signal(0);
  public slides = signal([
    { title: 'Step 1', description: 'Confirm address and delivery window.', image: './assets/scooter.svg' },
    { title: 'Step 2', description: 'Add your eScript(s).', image: './assets/script.svg' },
    { title: 'Step 3', description: 'Receive payment link via SMS.', image: './assets/alert.svg' },
  ]);

  ngOnInit() {
    this.automateSlide();
  }

  public automateSlide(duration: number = 5000) {
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
      this.slideInterval = undefined;
    }
    this.slideInterval = setInterval(() => {
      this.activeIndex.update(c => c == this.slides().length - 1 ? 0 : c + 1);
    }, duration);
  }

  public goToSlide(index: number) {
    this.activeIndex.set(index);
    this.automateSlide();
  }

  public onSwipeLeft() {
    this.goToSlide(Math.max(this.activeIndex() - 1, 0));
  }

  public onSwipeRight() {
    this.goToSlide(Math.min(this.activeIndex() + 1, this.slides().length - 1));
  }
}
