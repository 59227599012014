import * as Sentry from "@sentry/angular";
import { environment } from "../environments/environment";

const host = environment.hostIsShop ? "shop" : "rx";
const env = environment.production ? "production" : "development";
const sentryEnv = `${env}:web:${host}`;

Sentry.init({
  dsn: environment.sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: sentryEnv,
});