import e from "axios";
function r(r) {
  return (r = r || e).interceptors.response.use(n, s);
}
function t(r, t) {
  (t = t || e).interceptors.response.eject(r);
}
function n(e) {
  return e;
}
function o(e) {
  var r = [];
  if (e) return Array.isArray(e) ? e : ("object" == typeof e && Object.keys(e).forEach(function (t) {
    "number" == typeof t && (r[t] = e[t]);
  }), r);
}
function s(r) {
  if (e.isCancel(r)) return Promise.reject(r);
  var t = i(r) || {};
  if (t.currentRetryAttempt = t.currentRetryAttempt || 0, t.retry = "number" == typeof t.retry ? t.retry : 3, t.retryDelay = "number" == typeof t.retryDelay ? t.retryDelay : 100, t.instance = t.instance || e, t.backoffType = t.backoffType || "exponential", t.httpMethodsToRetry = o(t.httpMethodsToRetry) || ["GET", "HEAD", "PUT", "OPTIONS", "DELETE"], t.noResponseRetries = "number" == typeof t.noResponseRetries ? t.noResponseRetries : 2, t.checkRetryAfter = "boolean" != typeof t.checkRetryAfter || t.checkRetryAfter, t.maxRetryAfter = "number" == typeof t.maxRetryAfter ? t.maxRetryAfter : 3e5, t.statusCodesToRetry = o(t.statusCodesToRetry) || [[100, 199], [429, 429], [500, 599]], r.config = r.config || {}, r.config.raxConfig = Object.assign({}, t), !(t.shouldRetry || f)(r)) return Promise.reject(r);
  var n = new Promise(function (e, n) {
      var o = 0;
      if (t.checkRetryAfter && r.response && r.response.headers["retry-after"]) {
        var s = function (e) {
          var r = Number(e);
          if (!Number.isNaN(r)) return 1e3 * r;
          var t = Date.parse(e);
          return Number.isNaN(t) ? void 0 : t - Date.now();
        }(r.response.headers["retry-after"]);
        if (!(s && s > 0 && s <= t.maxRetryAfter)) return n(r);
        o = s;
      }
      r.config.raxConfig.currentRetryAttempt += 1;
      var f = r.config.raxConfig.currentRetryAttempt;
      0 === o && (o = "linear" === t.backoffType ? 1e3 * f : "static" === t.backoffType ? t.retryDelay : (Math.pow(2, f) - 1) / 2 * 1e3, "number" == typeof t.maxRetryDelay && (o = Math.min(o, t.maxRetryDelay))), setTimeout(e, o);
    }),
    s = t.onRetryAttempt ? Promise.resolve(t.onRetryAttempt(r)) : Promise.resolve();
  return Promise.resolve().then(function () {
    return n;
  }).then(function () {
    return s;
  }).then(function () {
    return t.instance.request(r.config);
  });
}
function f(e) {
  var r = e.config.raxConfig;
  if (!r || 0 === r.retry) return !1;
  if (!e.response && (r.currentRetryAttempt || 0) >= r.noResponseRetries) return !1;
  if (!e.config.method || r.httpMethodsToRetry.indexOf(e.config.method.toUpperCase()) < 0) return !1;
  if (e.response && e.response.status) {
    for (var t = !1, n = 0, o = r.statusCodesToRetry; n < o.length; n += 1) {
      var s = o[n],
        f = e.response.status;
      if (f >= s[0] && f <= s[1]) {
        t = !0;
        break;
      }
    }
    if (!t) return !1;
  }
  return r.currentRetryAttempt = r.currentRetryAttempt || 0, !(r.currentRetryAttempt >= r.retry);
}
function i(e) {
  if (e && e.config) return e.config.raxConfig;
}
export { r as attach, t as detach, f as shouldRetryRequest, i as getConfig };
