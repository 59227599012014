import { CommonModule } from '@angular/common';
import { Component, input, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroXCircle } from '@ng-icons/heroicons/outline';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'app-otp',
  standalone: true,
  imports: [CommonModule, FormsModule, NgIconComponent, SpinnerComponent],
  providers: [provideIcons({ heroXCircle })],
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent {
  otpCode = model<string>('');
  isLoading = input<boolean>(false);
}