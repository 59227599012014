import { CurrencyPipe } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { ICalculateTotalsResult } from '@chemist2u/types-client/C2U/Interfaces';
import { Order } from '@chemist2u/types-client/C2U/ParseObjects';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroLockClosedSolid } from '@ng-icons/heroicons/solid';

@Component({
  selector: 'app-order-totals',
  standalone: true,
  imports: [NgIconComponent, CurrencyPipe],
  providers: [provideIcons({ heroLockClosedSolid })],
  templateUrl: './order-totals.component.html',
  styleUrls: ['./order-totals.component.scss'],
})
export class OrderTotalsComponent {
  public order = input.required<Order>();
  private lineTotals: (keyof ICalculateTotalsResult)[] = [
    "ltOTCItems",
    "ltSavings",
    "ltPrescriptions",
    "ltCollectionFee",
    "ltShippingFee",
    "ltRegionalFee",
    "ltProgramFee",
    "ltPromotionDiscount",
    "ltConcessionDiscount",
    "ltCollectionFeeDiscount",
    "ltSubTotal",
    "ltTotal",
  ];
  public totals = computed(() => {
    const totals = this.order().totals;
    return this.lineTotals.map(lt => {
      const total = totals ? totals[lt] : undefined;
      const extraCondition = ["ltOTCItems", "ltSubTotal"].includes(lt) ? true
        : lt == "ltOTCItems" ? totals?.ltOTCItems?.value
        : lt == "ltSubTotal" ? totals?.ltSubTotal?.value && totals?.ltTotal && !totals?.ltTotal?.isReady
        : true;
      return {
        ...total,
        isDiscount: total?.type == "discount",
        title: lt == "ltTotal",
        price: (total?.type == "discount" ? -1 : 1) * (total?.value || 0),
        condition: total && extraCondition,
      };
    });
  });

}