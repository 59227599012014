<app-title-block heading="Confirm mobile" [description]="'Enter the code we sent by SMS to ' + this.mobileNumber()"></app-title-block>
<div class="py-4">
  <app-otp [isLoading]="isLoading()" [(otpCode)]="otp"></app-otp>
  <div class="flex items-center justify-between leading-4 mt-5">
    <div class="text-sm font-medium">Didn't get SMS?</div>
    <button class="resend-btn" [ngStyle]="{ color: rxTheme.linkColor() }" [ngClass]="{ disabled: cantSend() || isLoading() }" (click)="onResendOtp()">
      <ng-icon class="text-xl" name="heroArrowPath"></ng-icon>
      <div class="text-sm">Resend</div>
    </button>
  </div>
</div>