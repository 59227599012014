import { Injectable } from '@angular/core';
import { LatLngLiteral } from '@googlemaps/google-maps-services-js';

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
}

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  public mapAddressComponentsToStructuredFormatting(components: AddressComponent[], includeUnitNumber: boolean = true): StructuredFormatting {
    let mainText = '';
    let secondaryText = '';
    
    const streetNumber = components.find(comp => comp.types.includes('street_number' as any))?.long_name || '';
    const route = components.find(comp => comp.types.includes('route' as any))?.long_name || '';
    const unitNumber = includeUnitNumber ? components.find(comp => comp.types.includes('subpremise' as any))?.long_name : '';
    const locality = components.find(comp => comp.types.includes('locality' as any))?.long_name || '';
    const adminAreaLevel1 = components.find(comp => comp.types.includes('administrative_area_level_1' as any))?.short_name || '';
    const country = components.find(comp => comp.types.includes('country' as any))?.long_name || '';
    const postalCode = components.find(comp => comp.types.includes('postal_code' as any))?.long_name || '';
  
    // Include unit number in the main text if it exists
    if (unitNumber) {
      mainText = `${unitNumber}/${streetNumber} ${route}`.trim();
    } else {
      mainText = `${streetNumber} ${route}`.trim();
    }
    
    secondaryText = [locality, adminAreaLevel1, country, postalCode].filter(Boolean).join(', ');
  
    return {
      main_text: mainText,
      secondary_text: secondaryText,
    };
  }

  public geopointDistance(
    point1: LatLngLiteral,
    point2: LatLngLiteral,
    unit: 'km' | 'miles' | 'meters' = 'km'
  ): number {
    const toRadians = (degrees: number): number => degrees * (Math.PI / 180);

    const R = {
      km: 6371,          // Earth's radius in kilometers
      miles: 3958.8,     // Earth's radius in miles
      meters: 6371000,   // Earth's radius in meters
    };

    const lat1 = toRadians(point1.lat);
    const lat2 = toRadians(point2.lat);
    const deltaLat = toRadians(point2.lat - point1.lat);
    const deltaLon = toRadians(point2.lng - point1.lng);

    const a = Math.sin(deltaLat / 2) ** 2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLon / 2) ** 2;
    const c = 2 * Math.asin(Math.sqrt(a));
    return R[unit] * c;
  }

}
