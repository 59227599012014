"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.User = void 0;
const default_1 = require("../default");
const Role_1 = require("./Role");
const _BaseParseObject_1 = require("./_BaseParseObject");
const config_1 = require("../config");
const local_parse_1 = require("../local-parse");
class User extends _BaseParseObject_1.BaseParseUser {
  get objectId() {
    return this.id;
  }
  constructor(user) {
    super(user);
  }
  get phoneNumberForSMS() {
    var _a;
    if (process.env.NODE_ENV !== "production") {
      return process.env.DEV_SMS_NUMBER;
    }
    if (this.userType === "Chemist2U" || this.userType === undefined) {
      return this.username;
    } else if (this.userType === "Bupa") {
      return (_a = this.meta) === null || _a === void 0 ? void 0 : _a.mobile;
    } else {
      return undefined;
    }
  }
  get name() {
    return `${this.first_name} ${this.last_name}`;
  }
  get emailToSend() {
    var _a;
    if (process.env.NODE_ENV !== "production") {
      return process.env.DEV_SES_EMAIL;
    }
    if (this.userType === "Chemist2U" || this.userType === undefined) {
      return this.email;
    } else if (this.userType === "Bupa") {
      return (_a = this.meta) === null || _a === void 0 ? void 0 : _a.email;
    } else {
      return undefined;
    }
  }
  static async getUserByUsername(username) {
    return new local_parse_1.Parse.Query(User).equalTo("username", username).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async getUserByEmail(email) {
    return new local_parse_1.Parse.Query(User).equalTo("email", email.trim()).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async isUserType(user, roleName) {
    if (!user) return false;
    const userObject = user instanceof User || user instanceof local_parse_1.Parse.User ? user : await this.getUserByUsername(user);
    if (!userObject) throw new local_parse_1.Parse.Error(local_parse_1.Parse.Error.OBJECT_NOT_FOUND, "Could not find the user record");
    const isRole = await new local_parse_1.Parse.Query(Role_1.Role).equalTo("name", roleName).equalTo("users", userObject).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
    return !!isRole;
  }
  static async getUserType(user) {
    return await new local_parse_1.Parse.Query(local_parse_1.Parse.Role).equalTo("users", user).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async isAdmin(username) {
    return await this.isUserType(username, default_1.ERoles.Admin);
  }
  static async isCustomer(username) {
    return await this.isUserType(username, default_1.ERoles.Customer);
  }
  static async isPharmacy(username) {
    return await this.isUserType(username, default_1.ERoles.Pharmacy);
  }
  static async permissionCheck(user, roleName, master) {
    if (!user) throw Error("Not Authorized");
    const isInRole = this.isUserType(user, roleName);
    if (!(isInRole || master)) throw Error("Not Authorized");
  }
  static async getByID(id, useMasterKey = true) {
    return await new local_parse_1.Parse.Query(User).get(id, {
      useMasterKey: config_1.C2UConfig.useMasterKey && useMasterKey
    });
  }
  static async getFByID(id, useMasterKey = true) {
    return await new local_parse_1.Parse.Query(User).equalTo("objectId", id).first({
      useMasterKey: config_1.C2UConfig.useMasterKey && useMasterKey
    });
  }
  static async validatePin(user, pin) {
    return (await user.get("pin")) === pin;
  }
  static pointer(id) {
    return {
      __type: "Pointer",
      className: "_User",
      objectId: id
    };
  }
}
exports.User = User;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], User.prototype, "username", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], User.prototype, "role", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)({
  read: false
}), __metadata("design:type", String)], User.prototype, "password", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], User.prototype, "email", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)({
  write: false
}), __metadata("design:type", Object)], User.prototype, "emailVerified", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], User.prototype, "authData", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], User.prototype, "photo", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], User.prototype, "status", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], User.prototype, "concierge", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], User.prototype, "canonical", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], User.prototype, "last_name", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], User.prototype, "first_name", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], User.prototype, "sqID", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], User.prototype, "incomplete", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], User.prototype, "inHubspot", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], User.prototype, "meta", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], User.prototype, "userType", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], User.prototype, "tags", void 0);
local_parse_1.Parse.Object.registerSubclass("_User", User);
