<div class="fixed inset-x-0 inset-y-0 bg-black/60 flex items-center justify-center px-6 z-20" (click)="close($event)">
  <div class="w-full max-w-96 h-[36rem] rounded-md shadow-md overflow-hidden bg-white">
    <app-wave-bg heightClass="h-2/5" bgClass="bg-white"></app-wave-bg>
    <div class="absolute inset-x-0 inset-y-0">
      <div class="flex px-4 pt-6 pb-2 text-white">
        <div class="w-1/5 text-left"></div>
        <div class="w-3/5 text-center text-2xl leading-8 font-semibold">eScript Upload</div>
        <div class="w-1/5 text-right text-2xl cursor-pointer">
          <ng-icon name="heroXMark" (click)="close($event)"></ng-icon>
        </div>
      </div>
      <div class="flex justify-center">
        <img class="-translate-x-6" src="assets/escript-bg.svg">
      </div>
      <div class="text-xl leading-6 mt-8 px-6 text-center text-black">
        {{ message() || "Invalid token. Check you've correctly entered the token." }}
      </div>
      <div class="absolute bottom-6 left-4 right-4">
        <app-button bgColor="secondary" label="Got it"></app-button>
      </div>
    </div>
  </div>
</div>