import { Component, input, signal } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroCheckMini } from '@ng-icons/heroicons/mini';

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [NgIconComponent],
  providers: [
    provideIcons({ heroCheckMini }),
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: CheckboxComponent
    }
  ],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements ControlValueAccessor, Validator {
  public checked = signal<boolean>(false);
  public touched = signal<boolean>(false);
  public disabled = signal<boolean>(false);
  public label = input<string | undefined>(undefined);

  toggleValue() {
    if (this.disabled()) return;
    this.checked.update(v => !v);
    this.onChange(this.checked());
  }

  // Form Control

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: boolean) {
    this.checked.set(value);
  }
  
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched()) {
      this.onTouched();
      this.touched.set(true);
    }
  }

  setDisabledState?(disabled: boolean) {
    this.disabled.set(disabled);
  }
  
  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    return null;
  }
}