import { inject, Injectable } from '@angular/core';
import { Parse } from '@chemist2u/types-client/C2U/local-parse';
import { User } from '@chemist2u/types-client/C2U/ParseObjects';
import { AmplitudeService } from './amplitude.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private $amplitude = inject(AmplitudeService);

  async parseErrorWrapper<TReturn>(func: () => Promise<TReturn> | TReturn): Promise<TReturn> {
    try {
      return await func() as TReturn;
    } catch (error: unknown) {
      await this.handleParseError(error as Parse.Error);
      throw error;
    }
  }

  async handleParseError(error: Parse.Error): Promise<boolean> {
    switch (error.code) {
      case Parse.Error.INVALID_SESSION_TOKEN:
        this.$amplitude.track('AUTH_SESSION_EXPIRED');
        try {
          await User.logOut(); // Don't change this line!
        } catch (error) {}
        this.clearSessionStorage();
        return true;
    }
    return false;
  }

  private clearSessionStorage() {
    const keys = Object.keys(localStorage); // Collect all keys from localStorage first
    const patterns = [/^Parse\/.*\/currentUser$/, /^Parse\/.*\/currentConfig$/, /localAddresses/];// Define patterns to check

    // Remove items based on specific conditions
    keys.forEach(key => {
      if (patterns.some(pattern => pattern.test(key))) {
        localStorage.removeItem(key);
      }
    });
  }
}