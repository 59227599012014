"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.C2UConfig = void 0;
;
const serverConfig = {
  env: "server",
  useMasterKey: true
};
const clientConfig = {
  env: "client",
  useMasterKey: false
};
exports.C2UConfig = "client" == "server" ? serverConfig : clientConfig;
