<app-title-block heading="Delivery address"></app-title-block>
<div class="py-4">
  @if (placeData()) {
    <div class="flex items-start gap-2 border rounded-lg p-4 mb-6">
      <ng-icon class="text-2xl text-primary" name="heroMapPinSolid"></ng-icon>
      <div class="text-base text-primary">
        <div class="font-semibold">{{ addressMainText() }}</div>
        <div class="mt-1">{{ addressSecondaryText() }}</div>
      </div>
    </div>
  }
  <div class="mb-6">
    <app-input label="Unit number (optional)" [value]="unitNumber()" (valueChange)="unitNumberChange($event)"></app-input>
  </div>
  <div>
    <app-input label="Delivery instructions" [value]="deliveryNotes()" (valueChange)="deliveryNotesChange($event)" placeholder="Add any delivery instructions"></app-input>
  </div>
</div>