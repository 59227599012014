import { CommonModule } from '@angular/common';
import { Component, inject, input, output, signal } from '@angular/core';
import { TitleBlockComponent } from '../../../../components/title-block/title-block.component';
import { Order } from '@chemist2u/types-client/C2U/ParseObjects';
import { SpinnerComponent } from '../../../../components/spinner/spinner.component';
import { ScriptLoaderService } from '../../../../services/script-loader.service';
import { OrderTotalsComponent } from "../../../../components/order-totals/order-totals.component";
import { RxThemeService } from '../../services/rx-theme.service';
import { environment } from '../../../../../environments/environment';
import { AmplitudeService } from '../../../../services/amplitude.service';

declare var Square: any;

@Component({
  selector: 'step-payment',
  standalone: true,
  imports: [CommonModule, TitleBlockComponent, SpinnerComponent, OrderTotalsComponent],
  templateUrl: './payment.step.html',
  styleUrl: './payment.step.scss'
})
export class PaymentStep {
  private $amplitude = inject(AmplitudeService);
  private $script = inject(ScriptLoaderService);
  public rxTheme = inject(RxThemeService);

  public order = input.required<Order>();
  public squareCard = output<any>();

  public isLoading = signal<boolean>(true);

  // cardStyles
  public cardStyles = {
    '.input-container': {
      borderColor: '#97A6BD',
      borderRadius: '6px',
    },
    '.input-container.is-focus': {
      borderColor: '#4E6887',
    },
    '.input-container.is-error': {
      borderColor: '#FF1675',
    },
    '.message-text': {
      color: '#97A6BD',
    },
    '.message-icon': {
      color: '#97A6BD',
    },
    '.message-text.is-error': {
      color: '#FF1675',
    },
    '.message-icon.is-error': {
      color: '#FF1675',
    },
    input: {
      backgroundColor: '#FFFFFF',
      color: '#003E63',
      fontFamily: 'helvetica neue, sans-serif',
    },
    'input::placeholder': {
      color: '#D2D8E2',
    },
    'input.is-error': {
      color: '#ff1600',
    },
  };

  constructor() {
    this.$amplitude.track('GENERIC_NAVIGATE', { to_page: 'payment' });
    this.$script.load("square").then(() => this.init());
  }

  async init() {
    const payments = Square.payments(environment.squareAppId, environment.squareLocationId);
    const card = await payments.card({ style: this.cardStyles });
    try {
      await card.attach('#rx-flow-payment-step');
      this.squareCard.emit(card);
    } catch (error) {
      console.error(error);
    }
    this.isLoading.set(false);
  }
}
