import { computed, Injectable, signal } from '@angular/core';
import { Cloud } from '@chemist2u/types-client/C2U/Cloud';

@Injectable()
export class RxThemeService {
  private partner = signal<Cloud.FetchPartnerBrandResponse | undefined>(undefined);
  
  private readonly brandColor = '#D92050';
  private readonly brandTextColor = '#FFFFFF';

  private _buttonStyleOverrides = computed<(isOutlined: boolean) => ({ [klass: string]: any; })>(() => (isOutlined: boolean) => ({
    backgroundColor: isOutlined ? "transparent" : this.primaryColor(),
    color: isOutlined ? this.primaryColor() : this.buttonTextColor(),
    ...(isOutlined && { borderColor: this.primaryColor() }),
  }));
  
  public primaryColor = computed<string>(() => this.partner()?.buttonColor || this.brandColor);
  public buttonTextColor = computed<string>(() => this.partner()?.buttonTextColor || this.brandTextColor);
  public linkColor = computed<string>(() => this.partner()?.linkColor || this.brandColor);

  public buttonStyleOverrides = computed<{ [klass: string]: any; }>(() => this._buttonStyleOverrides()(false));
  public outlinedButtonStyleOverrides = computed<{ [klass: string]: any; }>(() => this._buttonStyleOverrides()(true));

  setPartner(partner: Cloud.FetchPartnerBrandResponse) {
    this.partner.set(partner);
  }
} 