import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, output } from '@angular/core';
import { TitleBlockComponent } from '../../../../components/title-block/title-block.component';
import { PlaceData } from '@googlemaps/google-maps-services-js';
import { InputComponent } from '../../../../components/input/input.component';
import { UtilService } from '../../../../services/util.service';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroMapPinSolid } from '@ng-icons/heroicons/solid';
import { AmplitudeService } from '../../../../services/amplitude.service';

@Component({
  selector: 'step-address-details',
  standalone: true,
  imports: [CommonModule, TitleBlockComponent, InputComponent, NgIconComponent],
  providers: [provideIcons({ heroMapPinSolid })],
  templateUrl: './address-details.step.html',
  styleUrl: './address-details.step.scss'
})
export class AddressDetailsStep {
  private $util = inject(UtilService);
  private $amplitude = inject(AmplitudeService);

  public placeData = input<PlaceData | undefined>();
  public addressDetails = input<{ unitNumber: string, deliveryNotes: string }>();
  public addressDetailsChange = output<{ unitNumber: string, deliveryNotes: string }>();

  public unitNumber = computed<string>(() => this.addressDetails()?.unitNumber || "");
  public deliveryNotes = computed<string>(() => this.addressDetails()?.deliveryNotes || "");

  public addressMainText = computed<string>(() => {
    const addressComponents = this.placeData()?.address_components || [];
    const structuredFormatting = this.$util.mapAddressComponentsToStructuredFormatting(addressComponents, false);
    return structuredFormatting.main_text;
  });

  public addressSecondaryText = computed<string>(() => {
    const addressComponents = this.placeData()?.address_components || [];
    const structuredFormatting = this.$util.mapAddressComponentsToStructuredFormatting(addressComponents, false);
    return structuredFormatting.secondary_text;
  });

  constructor() {
    this.$amplitude.track('GENERIC_NAVIGATE', { to_page: 'address_details' });
  }

  public unitNumberChange(value: string | Date | number) {
    this.emitChanges({ unitNumber: value as string });
  }

  public deliveryNotesChange(value: string | Date | number) {
    this.emitChanges({ deliveryNotes: value as string });
  }

  public emitChanges({ unitNumber = this.unitNumber(), deliveryNotes = this.deliveryNotes() }) {
    this.addressDetailsChange.emit({ unitNumber, deliveryNotes });
  }

}
