"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Cart = void 0;
const config_1 = require("../config");
const _BaseParseObject_1 = require("./_BaseParseObject");
const local_parse_1 = require("../local-parse");
class Cart extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("Cart", attributes);
  }
  get otcItemCount() {
    if (this.items) {
      return this.items.reduce((acc, curr) => acc + curr.qty, 0);
    } else {
      return 0;
    }
  }
  get prescriptionItemCount() {
    if (this.prescriptions) {
      const items = this.prescriptions.reduce((acc, curr) => acc + (curr.items ? curr.items.length : 0), 0);
      return items;
    }
    return 0;
  }
  get hasScriptsInReview() {
    if (this.prescriptions) {
      return this.prescriptions.some(p => p.status && p.status.type && (p.status.type === "Pending" || p.status.type === "Submitted"));
    }
    return false;
  }
  get combinedItemCount() {
    return this.otcItemCount + this.prescriptionItemCount;
  }
  static async getByUser(user) {
    return new local_parse_1.Parse.Query(Cart).equalTo("customer", user).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async createCartForUser(user) {
    const cart = new Cart({
      customer: user
    });
    return await cart.save(null, {
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async destroyCartOnOrder(customer) {
    try {
      const carts = await new local_parse_1.Parse.Query(Cart).equalTo("customer", customer).find({
        useMasterKey: config_1.C2UConfig.useMasterKey
      });
      if (carts && carts.length > 0) {
        for (let cart of carts) {
          await cart.destroy({
            useMasterKey: config_1.C2UConfig.useMasterKey
          });
        }
      }
      const newCart = new Cart({
        customer
      });
      newCart.customer = customer;
      await newCart.save(null, {
        useMasterKey: config_1.C2UConfig.useMasterKey
      });
    } catch (err) {
      throw err;
    }
  }
  isEmpty() {
    if (this.items && this.items.length > 0 || this.prescriptions && this.prescriptions.length > 0) {
      return false;
    }
    return true;
  }
}
exports.Cart = Cart;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Cart.prototype, "items", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Cart.prototype, "customer", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Cart.prototype, "totals", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Cart.prototype, "totalsList", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Cart.prototype, "status", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Cart.prototype, "total", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Cart.prototype, "subtotal", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Cart.prototype, "prescriptions", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Cart.prototype, "pharmacy", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Cart.prototype, "promotion", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Cart.prototype, "discount", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Cart.prototype, "emailTriggerCount", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Cart.prototype, "lastItemAddedAt", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Cart.prototype, "meta", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Cart.prototype, "address", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Cart.prototype, "fulfillmentDetails", void 0);
local_parse_1.Parse.Object.registerSubclass("Cart", Cart);
