import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, model, signal } from '@angular/core';
import { TitleBlockComponent } from '../../../../components/title-block/title-block.component';
import { Order } from '@chemist2u/types-client/C2U/ParseObjects';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroMapPinSolid } from '@ng-icons/heroicons/solid';
import { UtilService } from '../../../../services/util.service';
import { CheckboxComponent } from "../../../../components/checkbox/checkbox.component";
import { TAllocatedShift, TOrderFulfillmentMethod, TOrderFulfillmentMethodOnDemand } from '@chemist2u/types-client/C2U/Interfaces';
import { toObservable } from '@angular/core/rxjs-interop';
import { CloudService } from '../../../../services/cloud.service';
import { FormsModule } from '@angular/forms';
import { DeliveryMethodOptionComponent } from "./delivery-method-option/delivery-method-option.component";
import { heroMapPin } from '@ng-icons/heroicons/outline';
import { RxThemeService } from '../../services/rx-theme.service';
import { AmplitudeService } from '../../../../services/amplitude.service';
import { RxOrderService } from '../../services/rx-order.service';

@Component({
  selector: 'step-review',
  standalone: true,
  imports: [CommonModule, FormsModule, TitleBlockComponent, NgIconComponent, CheckboxComponent, DeliveryMethodOptionComponent],
  providers: [provideIcons({ heroMapPinSolid, heroMapPin })],
  templateUrl: './review.step.html',
  styleUrl: './review.step.scss'
})
export class ReviewStep {
  private $cloud = inject(CloudService);
  private $util = inject(UtilService);
  private $amplitude = inject(AmplitudeService);
  public rxTheme = inject(RxThemeService);
  public rxOrder = inject(RxOrderService);

  public order = input.required<Order>();

  public termsCheckbox = model<boolean>(false, { alias: 'terms' });
  public fulfilmentMethod = model<TOrderFulfillmentMethod | undefined>(undefined);
  public atlDetails = model<{ atl: boolean, instructions: string | undefined }>({ atl: false, instructions: undefined });
  public availableMethods = signal<TOrderFulfillmentMethod[]>([]);

  public addressMainText = computed<string>(() => {
    const addressComponents = this.order().shipping?.address?.address_components || [];
    const structuredFormatting = this.$util.mapAddressComponentsToStructuredFormatting(addressComponents, false);
    return structuredFormatting.main_text;
  });

  public addressSecondaryText = computed<string>(() => {
    const addressComponents = this.order().shipping?.address?.address_components || [];
    const structuredFormatting = this.$util.mapAddressComponentsToStructuredFormatting(addressComponents, false);
    return structuredFormatting.secondary_text;
  });

  public showShippingOptions = computed<boolean>(() => {
    // TODO: this needs to be in definitions
    const medicationNames = (this.order().prescriptions || []).flatMap(script => script.items.flatMap(item => item.productName.toLocaleLowerCase() || '')).filter(name => name !== '');
    const weightLossExclusiveItems = ['ozempic', 'semaglutide', 'wegovy','mounjaro','tirzepatide','rybelsus',];

    // Nicovape => Postal
    if (medicationNames.includes("nicovape")) {
      return false;
    }

    // WeightLoss => PostalTemperatureControlled
    if (weightLossExclusiveItems.some(item => medicationNames.includes(item))) {
      return false;
    }

    return true;
  });

  constructor() {
    this.$amplitude.track('GENERIC_NAVIGATE', { to_page: 'review' });
    toObservable(this.order).subscribe(async order => {
      if (!order.shipping) return;
      const selectedMethod = this.order().fulfillmentDetails;
      if (!this.fulfilmentMethod()) {
        const availableMethods = (await this.$cloud.getOrderFulfillmentMethodsForPharmacy(order.shipping)).map(method => {
          if (!selectedMethod) return method;
          const isOnDemand = selectedMethod.selectedMethod.method == "OnDemand";
          const isSameMethod = method.selectedMethod.method == selectedMethod.selectedMethod.method;
          if (isOnDemand && isSameMethod) {
            const cutoff = (selectedMethod as TOrderFulfillmentMethodOnDemand).allocatedShift?.cutoff || undefined
            if (cutoff && cutoff > new Date()) {
              return selectedMethod;
            }
          }
          return method;
        });
        this.availableMethods.set(availableMethods);
        this.fulfilmentMethod.set(selectedMethod || availableMethods[0]);
      }
    });
  }

  public selectMethod(method: TOrderFulfillmentMethod) {
    this.fulfilmentMethod.set(method);
  }

  public setShift(shift: TAllocatedShift, index: number) {
    let selectedMethod: TOrderFulfillmentMethod | undefined;
    this.availableMethods.update(methods => {
      return methods.map((method, i) => {
        if (i == index) {
          method = { ...method, allocatedShift: shift }
          selectedMethod = method;
        }
        return method;
      });
    });
    this.$amplitude.track('ADDRESS_FULFILLMENT_METHOD', { methodName: selectedMethod?.selectedMethod.method || ''  });
    this.selectMethod(selectedMethod!);
  }

  public setAtl(event: { atl: boolean, instructions: string | undefined }) {
    this.$amplitude.track('ADDRESS_DELIVERY_NOTES', { deliveryNotes: event.instructions || '' });
    this.atlDetails.set(event);
  }
}
