import { Injectable } from '@angular/core';
import { ToastComponent } from '../components/toast/toast.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastComponent!: ToastComponent;
  
  register(toastComponent: ToastComponent) {
    this.toastComponent = toastComponent;
  }

  show(type: 'success' | 'error', message: string, duration: number = 3000) {
    this.toastComponent.showToast(message, type);
  }
}
