import { Component, computed, inject, input, output, signal } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { TAllocatedShift, TOrderFulfillmentMethod, TOrderFulfillmentMethodClickAndCollect, TOrderFulfillmentMethodOnDemand, TOrderFulfillmentMethodStandard, TWeekDays } from '@chemist2u/types-client/C2U/Interfaces';
import { LatLngLiteral } from '@googlemaps/google-maps-services-js';
import { TimeService } from '../../../../../services/time.service';

import { addMinutes } from 'date-fns';
import { Order } from '@chemist2u/types-client/C2U/ParseObjects';
import { NgIconComponent } from '@ng-icons/core';
import { UtilService } from '../../../../../services/util.service';
import { ButtonComponent } from '../../../../../components/button/button.component';
import { SimpleModalComponent } from '../../../../../components/simple-modal/simple-modal.component';
import { LongtextInputComponent } from "../../../../../components/longtext-input/longtext-input.component";
import { InfoBoxComponent } from "../../../../../components/info-box/info-box.component";
import { FormsModule } from '@angular/forms';
import { RadioSelectComponent } from "../../../../../components/radio-select/radio-select.component";
import { CloudService } from '../../../../../services/cloud.service';
import { RadioComponent } from "../../../../../components/radio/radio.component";

@Component({
  selector: 'app-delivery-method-option',
  standalone: true,
  imports: [CommonModule, CurrencyPipe, NgIconComponent, ButtonComponent, SimpleModalComponent, LongtextInputComponent, InfoBoxComponent, FormsModule, RadioSelectComponent, RadioComponent],
  templateUrl: './delivery-method-option.component.html',
  styleUrls: ['./delivery-method-option.component.scss'],
})
export class DeliveryMethodOptionComponent {
  private $cloud = inject(CloudService);
  private $time = inject(TimeService);
  private $util = inject(UtilService);

  public isActive = input<boolean>(false, { alias: 'active' });
  public order = input.required<Order>();
  public fullFillmentMethod = input.required<TOrderFulfillmentMethod>();
  public selectEvent = output<TOrderFulfillmentMethod>({ alias: 'select' });
  public setShift = output<TAllocatedShift>({ alias: 'shiftChange' });
  public setAtl = output<{ atl: boolean, instructions: string | undefined }>({ alias: 'atlChange' });

  public pharmacyAddress = computed(() => {
    if (this.fullFillmentMethod()?.selectedMethod.method !== "clickAndCollect") return undefined;
    if (!this.order().pharmacy?.googleAddress?.formatted_address) return undefined;
    return this.order().pharmacy!.googleAddress!.formatted_address;
  });

  public pharmacyDistance = computed(() => {
    if (this.fullFillmentMethod()?.selectedMethod.method !== "clickAndCollect") return undefined;
    const pharmacyLocation = this.order().pharmacy?.location;
    const customerAddress = this.order().shipping;
    if (!pharmacyLocation || !customerAddress) return undefined;
    const pharmacyGeoPoint: LatLngLiteral = { lat: pharmacyLocation.latitude, lng: pharmacyLocation.longitude };
    const customerGeoPoint: LatLngLiteral = customerAddress.address.geometry.location;
    if (!pharmacyGeoPoint || !customerGeoPoint) return undefined;
    return this.$util.geopointDistance(pharmacyGeoPoint, customerGeoPoint);
  });

  public canReschedule = computed<boolean>(() => {
    const methodName = this.fullFillmentMethod().selectedMethod.method;
    return methodName == "OnDemand";
  });
  public showingReschedule = signal<boolean>(false);
  public scheduleDays = signal<{ day: TWeekDays, date: string, shifts: TAllocatedShift[] }[]>([]);
  public scheduleDayShifts = signal<(TAllocatedShift & { shiftString?: string })[]>([]);
  public selectedDate = signal<{ day: TWeekDays, date: string, shifts: TAllocatedShift[] } | undefined>(undefined);
  public selectedShiftId = signal<string | undefined>(undefined);

  public atl = computed<boolean>(() => this.order().shipping?.atl || false);
  public canSetATL = computed<boolean>(() => {
    const methodName = this.fullFillmentMethod().selectedMethod.method;
    return methodName == "OnDemand" || methodName == "Standard";
  });
  public atlBtnLabel = computed(() => {
    return this.atl() ? "Authority to leave" : "Someone will be home";
  });
  public showingAtl = signal<boolean>(false);
  public atlChecked = signal<boolean>(false);
  public atlInstructions = signal<string | undefined>(undefined);

  selectMethod() {
    this.selectEvent.emit(this.fullFillmentMethod()!);
  }

  public scheduleName = computed(() => {
    if (this.fullFillmentMethod()?.selectedMethod.method === "OnDemand") {
      const allocatedShift = (this.fullFillmentMethod() as TOrderFulfillmentMethodOnDemand)!.allocatedShift;
      return this.$time.formatShiftLabel(allocatedShift!);
    }

    if (this.fullFillmentMethod()?.selectedMethod.method === "Standard") {
      const expectedDeliveryDate = (this.fullFillmentMethod() as TOrderFulfillmentMethodStandard)!.expectedDeliveryDate!;
      const psuedoShift = {
        pickup: expectedDeliveryDate,
        dropoff: new Date(expectedDeliveryDate.getTime() + 4 * 60 * 60 * 1000),
        day: this.$time.formatDay(expectedDeliveryDate),
        date: expectedDeliveryDate,
      } as unknown as TAllocatedShift;
      return this.$time.formatShiftLabel(psuedoShift);
    }

    if (this.fullFillmentMethod()?.selectedMethod.method === "clickAndCollect") {
      return "Click & Collect";
    }

    if(this.fullFillmentMethod()?.selectedMethod.method === "Postal") {
      return "Postal";
    }

    if(this.fullFillmentMethod()?.selectedMethod.method === "PostalTemperatureControlled") {
      return "Postal Temperature Controlled";
    }

    return "Unknown";
  });

  public description = computed(() => {
    if (this.fullFillmentMethod()?.selectedMethod.method === "OnDemand") {
      const allocatedShift = (this.fullFillmentMethod() as TOrderFulfillmentMethodOnDemand)!.allocatedShift;
      return this.formatCutoffSublabel(allocatedShift!);
    }

    if (this.fullFillmentMethod()?.selectedMethod.method === "Standard") {
      const expectedDeliveryDate = (this.fullFillmentMethod() as TOrderFulfillmentMethodStandard)!.expectedDeliveryDate!;
      return this.formatPaymentDescription((this.fullFillmentMethod() as TOrderFulfillmentMethodStandard)!.selectedMethod.standardDeliveryCutoffMinutes!, expectedDeliveryDate);
    }

    if (this.fullFillmentMethod()?.selectedMethod.method === "clickAndCollect") {
      return this.formatPickupAvailability((this.fullFillmentMethod() as TOrderFulfillmentMethodClickAndCollect)!.expectedPickupDate!, (this.fullFillmentMethod() as TOrderFulfillmentMethodClickAndCollect)!.selectedMethod.clickAndCollectBufferMinutes!);
    }

    if (this.fullFillmentMethod()?.selectedMethod.method === "Postal") {
      return "Express post 1-2 business days if paid by midday.";
    }

    if (this.fullFillmentMethod()?.selectedMethod.method === "PostalTemperatureControlled") {
      return "Express post 1-2 business days if paid by midday. Temperature controlled packaging.";
    }

    return "Description";
  });

  public free = computed(() => {
    return this.fullFillmentMethod()?.selectedMethod.isFree || this.fullFillmentMethod()?.selectedMethod.deliveryFee === 0;
  });

  public price = computed(() => {
    return this.fullFillmentMethod()?.selectedMethod.deliveryFee;
  });

  formatPickupAvailability(expectedPickupDate: Date, clickAndCollectBufferMinutes: number): string {
    // Calculate the next available pickup time by adding the buffer to the expectedPickupDate
    const nextAvailableTime = addMinutes(expectedPickupDate, 0);

    // Determine the day label ('today' or 'tomorrow') based on the time difference
    const today = new Date();
    const isToday = nextAvailableTime.getDate() === today.getDate() &&
      nextAvailableTime.getMonth() === today.getMonth() &&
      nextAvailableTime.getFullYear() === today.getFullYear();
    const dayLabel = isToday ? 'today' : 'tomorrow';

    // Format the next available pickup time
    const timeFormatOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedTime = nextAvailableTime.toLocaleTimeString('en-US', timeFormatOptions).toLowerCase();

    // Determine the buffer label (in hours or minutes)
    let bufferLabel;
    if (clickAndCollectBufferMinutes % 60 === 0) {
      const hours = clickAndCollectBufferMinutes / 60;
      bufferLabel = hours === 1 ? '1 hour' : `${hours} hours`;
    } else {
      bufferLabel = `${clickAndCollectBufferMinutes} minutes`;
    }

    return `Available ${bufferLabel} after payment, next available pickup ${dayLabel} ${formattedTime}.`;
  }

  formatPaymentDescription(standardDeliveryCutoffMinutes: number, day: Date): string {
    const hours = Math.floor(standardDeliveryCutoffMinutes / 60);
    const minutes = standardDeliveryCutoffMinutes % 60;
    const amPm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours > 12 ? hours - 12 : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const dayLabel = this.$time.formatDay(day);
    
    const cutoffTime = `${formattedHours}:${formattedMinutes} ${amPm} ${dayLabel}`;

    return `If payment is received before ${cutoffTime}. Delivery fee waived if order is $75+ or 3+ scripts.`;
  }

  formatCutoffSublabel(shift: TAllocatedShift): string {
    const formatOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const locale = 'en-AU'; // Adjust locale if necessary

    const cutoffTime = shift.cutoff.toLocaleTimeString(locale, formatOptions).toLowerCase();
    const dayLabel = this.$time.formatDay(shift.date);

    return `If payment is received before ${cutoffTime} ${dayLabel}.`;
  }

  // Reschedule
  public async onRescheduleClick(event: MouseEvent) {
    event.stopPropagation();
    this.showingReschedule.set(true);
    const pharmacyUserId = this.order().pharmacy?.user?.objectId;
    if (pharmacyUserId) {
      const formattedShifts = await this.$cloud.getFormattedShiftsForPharmacy(pharmacyUserId);
      this.scheduleDays.set(formattedShifts);
      this.selectDate(0);
    }
  }

  public selectDate(index: number) {
    this.selectedDate.set(this.scheduleDays()[index]);
    this.scheduleDayShifts.set(this.scheduleDays()[index].shifts);
    this.selectedShiftId.set(undefined);
  }

  public selectShift(id: string) {
    this.selectedShiftId.set(id);
  }

  public saveReschedule() {
    const shift = this.scheduleDayShifts().find(shift => shift.id === this.selectedShiftId());
    // @ts-ignore
    delete shift['shiftString'];
    this.setShift.emit(shift as TAllocatedShift);
    this.closeReschedule();
  }

  public closeReschedule() {
    this.showingReschedule.set(false);
  }

  // ATL
  public async onAtlClick(event: MouseEvent) {
    event.stopPropagation();
    this.showingAtl.set(true);
    this.atlChecked.set(this.order().shipping?.atl || false);
    this.atlInstructions.set(this.order().shipping?.deliveryNote || undefined);
  }

  public saveAtl() {
    this.setAtl.emit({
      atl: this.atlChecked(),
      instructions: this.atlInstructions(),
    });
    this.closeAtl();
  }

  public closeAtl() {
    this.showingAtl.set(false);
  }

}