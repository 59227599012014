<app-title-block heading="eScript upload" description="Copy and paste the token from your eScript. Alternatively, you can upload an image."></app-title-block>
<div class="-mx-6">
  <img class="w-full h-48" src="./assets/escript-step-bg.svg">
</div>
<div class="mt-3">
  <app-input label="Token" [required]="true" [disabled]="isLoading()" [value]="token()" (valueChange)="onTokenChange($event)" [showClearButtonOption]="true"></app-input>
  <div class="absolute top-0 right-0 leading-4 flex items-center gap-2 cursor-pointer" [ngStyle]="{ color: rxTheme.linkColor() }" (click)="pasteToken()">
    <div class="text-sm font-medium">Paste</div>
    <ng-icon class="text-xl" name="heroClipboard"></ng-icon>
  </div>
</div>
<div class="mt-5 flex justify-center">
  <div class="flex gap-2 cursor-pointer text-primary" (click)="onQRScan()">
    <ng-icon class="text-xl" name="heroCameraSolid"></ng-icon>
    <div>Upload or scan QR code</div>
  </div>
</div>

@if (tokenData() && (tokenData() || []).length > 0) {
  <div class="py-4 mt-4 text-primary">
    <div class="text-lg font-semibold leading-6">Prescriptions</div>
    @for (token of tokenData(); track $index) {
      <div class="mt-4">
        <div class="p-4 border rounded-lg">
          <div class="flex items-start gap-3">
            <img src="./assets/script-icon.svg" alt="">
            <div class="overflow-hidden flex-1">
              <div class="font-semibold leading-5 text-ellipsis overflow-hidden whitespace-nowrap">{{ token.medicationName }}</div>
              <div class="text-right mt-1"><ng-icon (click)="removeToken($index)" class="cursor-pointer text-xl" name="heroTrash"></ng-icon></div>
            </div>
          </div>
          <div class="mt-5 px-2 text-sm leading-4 flex flex-col">
            <div class="flex items-start gap-6 pt-2 border-b">
              <app-checkbox label="This a new medication."
                [ngModel]="token.newMedication"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onCheckEdit($index, 'newMedication', !token.newMedication)"
                name="newMed"
                class="h-8"
              /> 
            </div>
            <div class="flex items-start gap-6 pt-2">
              <app-checkbox label="I'd prefer a lower-cost generic brand, if it's available."
                [ngModel]="token.subGenerics"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onCheckEdit($index, 'subGenerics', !token.subGenerics)"
                name="genericPref"
                class="h-8"
              />
            </div>
          </div>
        </div>
      </div>
    }
  </div>
  <div class="py-4 mt-2">
    <div class="text-lg font-semibold leading-6 text-primary">What's Next?</div>
    <ol class="mt-4 leading-6 text-base text-grey">
      <div class="flex items-start gap-2">
        <div class="w-4">1.</div><div>You'll be asked to create a medical profile.</div>
      </div>
      <div class="flex items-start gap-2">
        <div class="w-4">2.</div><div>Our pharmacy team will review your script and medical details.</div>
      </div>
      <div class="flex items-start gap-2">
        <div class="w-4">3.</div><div>You'll receive an SMS to review your priced order and pay.</div>
      </div>
    </ol>
  </div>
}

@if (invalidModal()) {
  <app-script-modal [message]="invalidModalMsg()" (close)="onHideInvalidModal()"></app-script-modal>
}