"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AutoMessageConfiguration = void 0;
const config_1 = require("../config");
const _BaseParseObject_1 = require("./_BaseParseObject");
const local_parse_1 = require("../local-parse");
class AutoMessageConfiguration extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("AutoMessageConfiguration", attributes);
  }
  static async getRecordByStatus(type, partner = 'Chemist2U', status, orderStatus, programType = "Normal") {
    return await new local_parse_1.Parse.Query(AutoMessageConfiguration).equalTo("type", type).equalTo("partner", partner).equalTo("programType", programType).equalTo("status", status).equalTo("orderStatus", orderStatus).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
}
exports.AutoMessageConfiguration = AutoMessageConfiguration;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], AutoMessageConfiguration.prototype, "type", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Array)], AutoMessageConfiguration.prototype, "alertConfiguration", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], AutoMessageConfiguration.prototype, "orderStatus", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], AutoMessageConfiguration.prototype, "status", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], AutoMessageConfiguration.prototype, "partner", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], AutoMessageConfiguration.prototype, "programType", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], AutoMessageConfiguration.prototype, "createdBy", void 0);
local_parse_1.Parse.Object.registerSubclass("AutoMessageConfiguration", AutoMessageConfiguration);
