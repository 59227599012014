<div class="fixed inset-x-0 inset-y-0 z-20 flex items-center justify-center p-6">
    <div class="absolute inset-x-0 inset-y-0 bg-black/40" (click)="close.emit()"></div>
    <div class="w-full max-w-screen-sm bg-white rounded-lg max-h-full overflow-y-auto flex flex-col" [ngClass]="styleClass()">
        <div class="w-full border-b flex items-center justify-between py-3 px-4">
            <h1 class="text-lg font-semibold">{{ heading() }}</h1>
            <div class="h-full flex flex-col items-center justify-center" (click)="close.emit()">
                <ng-icon class="text-2xl text-primary" name="heroXMark"></ng-icon>
            </div>
        </div>
        <div class="w-full flex-grow p-4 text-sm">
            <ng-content />
        </div>
    </div>
</div>