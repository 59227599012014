"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserSuggestions = void 0;
const _BaseParseObject_1 = require("./_BaseParseObject");
const local_parse_1 = require("../local-parse");
class UserSuggestions extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("UserSuggestions", attributes);
  }
}
exports.UserSuggestions = UserSuggestions;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], UserSuggestions.prototype, "user", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], UserSuggestions.prototype, "username", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], UserSuggestions.prototype, "area", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], UserSuggestions.prototype, "description", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], UserSuggestions.prototype, "importance", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], UserSuggestions.prototype, "messages", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], UserSuggestions.prototype, "completed", void 0);
local_parse_1.Parse.Object.registerSubclass("UserSuggestions", UserSuggestions);
