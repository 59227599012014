import { Component, Injector, OnInit, Renderer2, inject, signal } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { InputComponent } from "../../components/input/input.component";
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroClipboard, heroDevicePhoneMobile, heroTrash } from '@ng-icons/heroicons/outline';
import { ButtonComponent } from "../../components/button/button.component";
import { toObservable } from '@angular/core/rxjs-interop';
import { CloudService } from '../../services/cloud.service';
import { SpinnerComponent } from "../../components/spinner/spinner.component";
import { ToastService } from '../../services/toast.service';
import { SimpleModalComponent } from '../../components/simple-modal/simple-modal.component';
import { deliveryModal, privacyModal, termsModal } from './doctor-order.data';
import { User } from '@chemist2u/types-client/C2U/ParseObjects';
import { AmplitudeService } from '../../services/amplitude.service';
import { Cloud } from '@chemist2u/types-client/C2U/Cloud';

@Component({
    selector: 'doctor-order',
    standalone: true,
    imports: [CommonModule, RouterModule, FormsModule, InputComponent, NgIconComponent, ButtonComponent, SpinnerComponent, SimpleModalComponent],
    providers: [provideIcons({ heroDevicePhoneMobile, heroClipboard, heroTrash })],
    templateUrl: './doctor-order.component.html',
    styleUrl: './doctor-order.component.scss',
})
export class DoctorOrderComponent implements OnInit {
    private $cloud = inject(CloudService);
    private $toast = inject(ToastService);
    private $renderer = inject(Renderer2);
    private $title = inject(Injector).get(Title);
    private $meta = inject(Injector).get(Meta);
    private $amplitude = inject(AmplitudeService);

    public isLoading = signal<boolean>(false);
    public disabledForm = signal<boolean>(false);
    public invalidForm = signal<boolean>(false);
    public mobile = signal<string>("");
    public mobileError = signal<string>("");
    public token = signal<string>("");
    public tokenData = signal<Cloud.TTokenData[]>([]);

    public showingModal = signal<boolean>(false);
    public modalHeading = signal<string>("");
    public modalContent = signal<string>("");

    constructor() {
        toObservable(this.mobile).subscribe(() => this.invalidForm.set(!this.validateForm()));
    }

    async ngOnInit() {
        if (User.current()) {
            await this.$cloud.logOut();
        }
        const title = 'Script Concierge - Doctor';
        this.$title.setTitle(title);
        this.$meta.updateTag({ property: 'og:image:alt', content: title });
        this.$meta.updateTag({ name: 'twitter:title', content: title });
        this.$meta.updateTag({ name: 'twitter:text:title', content: title });
        this.$meta.updateTag({ name: 'twitter:image:alt', content: title });
    }

    public async pasteToken() {
        const token = await navigator.clipboard.readText();
        this.token.set(token);
        this.$amplitude.track('DOCTOR_TOKEN_PASTE', { token });
    }

    public clearAll() {
        const mobileNumber = this.mobile();
        this.mobile.set("");
        this.mobileError.set("");
        this.token.set("");
        this.tokenData.set([]);
        this.$amplitude.track('DOCTOR_FORM_CLEAR', { mobileNumber });
    }

    private disableForm() {
        this.disabledForm.set(true);
    }

    private enableForm() {
        this.disabledForm.set(false);
    }

    private validateForm() {
        let mobileError = "";
        if (this.mobile().length == 0) {
            mobileError = "You must enter a mobile phone";
        } else if (!new RegExp('^04[0-9]{8}$').test(this.mobile())) {
            mobileError = "The mobile number is not in a correct format";
        }
        this.mobileError.set(mobileError);
        if (mobileError) {
            return false;
        }
        return this.tokenData().length > 0;
    }

    public async submitForm() {
        const mobileNumber = this.mobile();
        try {
            const valid = this.validateForm();
            if (!valid) return;
            this.$amplitude.track('DOCTOR_ORDER_START', { mobileNumber });
            this.isLoading.set(true);
            this.disableForm();
            await this.createOrder();
            this.$toast.show("success", "Order created successfully!");
            this.$amplitude.track('DOCTOR_ORDER_SUCCESS', { mobileNumber });
            this.clearAll();
        } catch (error) {
            this.$toast.show("error", "Error creating order!");
            this.$amplitude.track('DOCTOR_ORDER_FAIL', { 
                mobileNumber, 
                error: error instanceof Error ? error.message : 'Unknown error' 
            });
        } finally {
            this.enableForm();
            this.isLoading.set(false);
        }
    }

    public async addToken() {
        const token = this.token();
        try {
            this.$amplitude.track('DOCTOR_TOKEN_ADD_START', { token });
            this.isLoading.set(true);
            const newTokenData = await this.$cloud.getErxData(token);
            const foundCode = this.tokenData().find(x => x.code == newTokenData.code);
            if (foundCode) {
                throw new Error("DUP");
            }
            this.tokenData.update(tk => {
                tk.push(newTokenData);
                return tk;
            });
            this.invalidForm.set(!this.validateForm());
            this.$toast.show("success", "Token added successfully!");
            this.$amplitude.track('DOCTOR_TOKEN_ADD_SUCCESS', { token });
        } catch (error) {
            let message = "Error adding token!";
            if (error instanceof Error && error.message == "DUP") {
                message = "Token already added!";
            }
            this.$toast.show("error", message);
            this.$amplitude.track('DOCTOR_TOKEN_ADD_FAIL', { 
                token, 
                error: error instanceof Error ? error.message : 'Unknown error' 
            });
        } finally {
            this.isLoading.set(false);
            this.token.set("");
        }
    }

    public removeToken(index: number) {
        const token = this.tokenData()[index].code;
        this.tokenData.update(td => {
            td.splice(index, 1);
            return td;
        });
        this.invalidForm.set(!this.validateForm());
        this.$amplitude.track('DOCTOR_TOKEN_DELETE', { token });
    }

    private async createOrder() {
        await this.$cloud.createConciergeOrder({
            mobileNumber: this.formatNumber(this.mobile()),
            partnerShortId: "c2u",
            tokenData: this.tokenData(),
        });
    }

    private formatNumber(number: string) {
        let cleanedNumber = number.replace(/\s+/g, '');
        if (!(/^\+614\d{8}$/.test(cleanedNumber) || /^04\d{8}$/.test(cleanedNumber))) {
            return "Invalid number";
        }
        if (cleanedNumber.startsWith('+61')) {
            return '0' + cleanedNumber.substring(3);
        }
        return cleanedNumber;
    }

    showModal(type: "delivery" | "privacy" | "terms") {
        this.$renderer.addClass(document.body, 'overflow-hidden');
        this.showingModal.set(true);
        this.$amplitude.track('DOCTOR_MODAL_SHOW', { modalType: type });
        if (type == 'delivery') {
            this.modalHeading.set(deliveryModal.heading);
            this.modalContent.set(deliveryModal.content);
        } else if (type == 'privacy') {
            this.modalHeading.set(privacyModal.heading);
            this.modalContent.set(privacyModal.content);
        } else if (type == 'terms') {
            this.modalHeading.set(termsModal.heading);
            this.modalContent.set(termsModal.content);
        }
    }

    closeModal() {
        const modalType = this.modalHeading() === deliveryModal.heading ? 'delivery' 
            : this.modalHeading() === privacyModal.heading ? 'privacy' 
            : 'terms';
        this.showingModal.set(false);
        this.modalHeading.set("");
        this.modalContent.set("");
        this.$renderer.removeClass(document.body, 'overflow-hidden');
        this.$amplitude.track('DOCTOR_MODAL_CLOSE', { modalType });
    }
}


