import { CommonModule } from '@angular/common';
import { Component, inject, input, model, output, signal } from '@angular/core';
import { TitleBlockComponent } from "../../../../components/title-block/title-block.component";
import { InputComponent } from "../../../../components/input/input.component";
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroClipboard, heroTrash } from '@ng-icons/heroicons/outline';
import { heroCameraSolid } from '@ng-icons/heroicons/solid';
import { CheckboxComponent } from '../../../../components/checkbox/checkbox.component';
import { toObservable } from '@angular/core/rxjs-interop';
import { CloudService } from '../../../../services/cloud.service';
import { FormsModule } from '@angular/forms';
import { ScriptModalComponent } from "../../../../components/script-modal/script-modal.component";
import { RxThemeService } from '../../services/rx-theme.service';
import { AmplitudeService } from '../../../../services/amplitude.service';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import jsQR from 'jsqr';
import { Cloud } from '@chemist2u/types-client/C2U/Cloud';

@Component({
  selector: 'step-script',
  standalone: true,
  imports: [CommonModule, TitleBlockComponent, InputComponent, CheckboxComponent, NgIconComponent, FormsModule, ScriptModalComponent, NgxScannerQrcodeModule],
  providers: [provideIcons({ heroClipboard, heroCameraSolid, heroTrash })],
  templateUrl: './script.step.html',
  styleUrl: './script.step.scss'
})
export class ScriptStep {
  private $cloud = inject(CloudService);
  private $amplitude = inject(AmplitudeService);
  public rxTheme = inject(RxThemeService);

  public isLoading = model<boolean>(false, { alias: 'loading' });
  public tokenData = input<Cloud.TTokenData[]>();
  public tokenDataChange = output<Cloud.TTokenData[]>();

  public token = signal<string>("");
  public invalidModal = signal<boolean>(false);
  public invalidModalMsg = signal<string | undefined>(undefined);

  private fileInput: HTMLInputElement | null = null;

  constructor() {
    this.$amplitude.track('GENERIC_NAVIGATE', { to_page: 'script' });
    toObservable(this.token).subscribe(v => {
      this.$amplitude.track('ADD_SCRIPT_TOKEN_TYPE', { token: v });
      if (v.length === 18 || v.length === 19) {
        this.emitToken(v);
      }
    });
  }

  public async pasteToken() {
    const token = await navigator.clipboard.readText();
    this.token.set(token);
    this.$amplitude.track('ADD_SCRIPT_TOKEN_PASTE', { token });
  }

  public onTokenChange(token: string | Date | number) {
    const value = token as string;
    this.token.set(value);
  }

  public async emitToken(token = this.token()) {
    try {
      const tokenData = this.tokenData() || [];
      const exists = tokenData.find(d => d.code == token);
      if (exists) return this.onShowInvalidModal("Invalid token. You've already entered this token.");
      this.isLoading.set(true);
      const newTokenData = await this.$cloud.getErxData(token);
      tokenData.push({ ...newTokenData, newMedication: false, subGenerics: false });
      this.token.set("");
      this.tokenDataChange.emit(tokenData);
      this.$amplitude.track('ADD_SCRIPT_TOKEN_VALIDATION_SUCCESS', { token });
    } catch (error) {
      this.$amplitude.track('ADD_SCRIPT_TOKEN_VALIDATION_FAIL', { token: this.token(), error: (error as Error).message });
      this.onShowInvalidModal();
    } finally {
      this.isLoading.set(false);
    }
  }

  public removeToken(index: number) {
    const tokenData = this.tokenData() || [];
    tokenData.splice(index, 1);
    this.tokenDataChange.emit(tokenData);
    this.$amplitude.track('ADD_SCRIPT_DELETE', { token: this.token() });
  }

  public onCheckEdit(index: number, checkbox: 'newMedication' | 'subGenerics', value: boolean) {
    const tokenData = this.tokenData() || [];
    tokenData[index][checkbox] = value;
    this.tokenDataChange.emit(tokenData);
  }
  
  public onShowInvalidModal(message?: string) {
    this.invalidModal.set(true);
    this.invalidModalMsg.set(message);
  }

  public onHideInvalidModal() {
    this.invalidModal.set(false);
    this.invalidModalMsg.set(undefined);
  }

  public async onQRScan() {
    this.$amplitude.track('ADD_SCRIPT_QR_TOKEN_SCAN_START');
    
    if (!this.fileInput) {
      this.fileInput = document.createElement('input');
      this.fileInput.type = 'file';
      this.fileInput.accept = 'image/*';
      this.fileInput.style.display = 'none';
      
      this.fileInput.addEventListener('change', async (event) => {
        const file = (event.target as HTMLInputElement).files?.[0];
        if (file) {
          try {
            const result = await this.processQRCode(file);
            if (result) {
              this.token.set(result);
              this.$amplitude.track('ADD_SCRIPT_QR_TOKEN_SCAN_SUCCESS', { token: result });
              await this.emitToken(result);
            } else {
              throw new Error('No QR code found in image');
            }
          } catch (error) {
            console.error('QR processing error:', error);
            this.$amplitude.track('ADD_SCRIPT_QR_TOKEN_SCAN_FAIL', { error: (error as Error).message });
            this.onShowInvalidModal('Could not read QR code from image. Please try again or enter the token manually.');
          }
        }
      });
      
      document.body.appendChild(this.fileInput);
    }
    
    this.fileInput.click();
  }

  private async processQRCode(file: File): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = async () => {
        try {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          if (!context) {
            reject(new Error('Could not create canvas context'));
            return;
          }

          canvas.width = img.width;
          canvas.height = img.height;
          context.drawImage(img, 0, 0);

          const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
          const code = jsQR(imageData.data, imageData.width, imageData.height);
          resolve(code ? code.data : null);
        } catch (error) {
          reject(error);
        }
      };
      img.onerror = () => reject(new Error('Failed to load image'));
      img.src = URL.createObjectURL(file);
    });
  }

  ngOnDestroy() {
    if (this.fileInput) {
      document.body.removeChild(this.fileInput);
    }
  }
}
