"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PharmacyDay = void 0;
const config_1 = require("../config");
const _BaseParseObject_1 = require("./_BaseParseObject");
const local_parse_1 = require("../local-parse");
class PharmacyDay extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("PharmacyDay", attributes);
  }
  static async getDaysForPharmacy(user) {
    return await new local_parse_1.Parse.Query(PharmacyDay).equalTo("pharmacy", user).ascending("displayOrder").include("shifts").find({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
}
exports.PharmacyDay = PharmacyDay;
PharmacyDay.daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], PharmacyDay.prototype, "day", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], PharmacyDay.prototype, "displayOrder", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], PharmacyDay.prototype, "pharmacy", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], PharmacyDay.prototype, "shifts", void 0);
local_parse_1.Parse.Object.registerSubclass("PharmacyDay", PharmacyDay);
