<app-stepper-scaffold>
  <div id="header" class="w-full flex items-center justify-between px-6">
    <div class="w-8">
      @if (rxStep.step() != "intro" && rxStep.step() != "end") {
        <ng-icon (click)="openSidemenu()" class="text-3xl cursor-pointer" name="heroBars3"></ng-icon>
      }
    </div>
    <div class="w-full flex justify-center" [ngClass]="{ invisible: rxStep.step() == 'end' }">
      <app-partner-logo [partner]="partner()"></app-partner-logo>
    </div>
    <div class="w-8"></div>
  </div>

  <div id="content" class="w-full h-full overflow-hidden">
    <div class="flex h-full">
      <div class="min-w-full min-h-full overflow-y-auto px-6">
        @switch (rxStep.step()) {
          @case ("intro") {
            @if (partner()) {
              <step-intro [partner]="partner()!"></step-intro>
            }
          } @case ("address_search") {
            <step-address-search (addressSelect)="rxState.onAddressSelect($event)"></step-address-search>
          } @case ("address_details") {
            <step-address-details [placeData]="rxState.state().addressSearch?.placeData" [addressDetails]="rxState.state().addressDetails" (addressDetailsChange)="rxState.onAddressDetailsChange($event)"></step-address-details>
          } @case ("mobile") {
            <step-mobile [mobileNumber]="rxState.state().mobileNumber || ''" (mobileNumberChange)="rxState.onMobileNumberChange($event)"></step-mobile>
          } @case ("mobile_confirm") {
            <step-mobile-confirm [(loading)]="otpLoading" [mobileNumber]="rxState.state().mobileNumber!" [lastSent]="otpLastSent()" [otp]="rxState.otp()" (otpChange)="onOtpChange($event)" (resendOtp)="sendOtp()"></step-mobile-confirm>
          } @case ("password") {
            <step-password [mode]="passwordMode()" [(password)]="password" (validation)="rxState.passwordValid.set($event)"></step-password>
          } @case ("script") {
            <step-script [(loading)]="isLoading" [tokenData]="rxState.state().tokenData" (tokenDataChange)="rxState.onTokenDataChange($event)"></step-script>
          } @case ("medical_profile_personal") {
            <step-medical-profile-personal (valueChange)="rxState.onMedicalProfilePersonalChange($event)"></step-medical-profile-personal>
          } @case ("medical_profile_clinical") {
            <step-medical-profile-clinical (valueChange)="rxState.onMedicalProfileClinicalChange($event)"></step-medical-profile-clinical>
          } @case ("review") {
            @if (rxOrder.order()) {
              <step-review [order]="rxOrder.order()!" [(fulfilmentMethod)]="fulfilmentMethod" [atlDetails]="atlDetails()" (atlDetailsChange)="onAtlDetailsChange($event)" [(terms)]="rxState.termsAccepted"></step-review>
            }
          } @case ("payment") {
            @if (rxOrder.order()) {
              <step-payment [order]="rxOrder.order()!" (squareCard)="squareCard.set($event)"></step-payment>
            }
          } @case ("end") {
            @if (rxOrder.order()) {
              <step-thank-you [partner]="partner()" [order]="rxOrder.order()!" [orderStatus]="rxOrder.orderStatus()"></step-thank-you>
            }
          }
        }
      </div>
    </div>
  </div>

  <div id="footer" class="flex items-center gap-2" [ngClass]="{ 'flex-col': rxStep.step() == 'end' }">
    @if (rxStep.step() != "end") {
      @if (rxStep.showPrevBtn()) {
        <app-button (onClick)="prevStep()"
        [disabled]="rxStep.disablePrevBtn()"
        [outlined]="true"
        [buttonStyleOverrides]="rxTheme.outlinedButtonStyleOverrides()"
        buttonStyleClass="max-w-[3.75rem]"
        icon="heroArrowLeftMini"
        size="large"></app-button>
      }
      <app-button (onClick)="nextStep()"
      [disabled]="rxStep.disableNextBtn()"
      class="w-full"
      [buttonStyleOverrides]="rxTheme.buttonStyleOverrides()"
      [label]="rxStep.labelNextBtn()"
      iconPosition="right"
      [icon]="rxStep.iconNextBtn()"
      size="large"></app-button>
    } @else if (rxStep.step() == "end") {
      <a class="w-full" [routerLink]="'/rx/' + partner()?.shortId">
        <app-button label="Add another order" [buttonStyleOverrides]="rxTheme.buttonStyleOverrides()" size="large"></app-button>
      </a>
      @if (storeLink()) {
        <a class="w-full" [href]="storeLink()">
          <app-button label="Download app to track your order" size="default" buttonStyleClass="border-none" [buttonStyleOverrides]="rxTheme.outlinedButtonStyleOverrides()" [outlined]="true"></app-button>
        </a>
      }
    }
  </div>
</app-stepper-scaffold>

@if (isLoading() || opaqueLoading()) {
  <div class="z-10 fixed inset-x-0 inset-y-0 flex items-center justify-center" [ngClass]="opaqueLoading() ? 'bg-white' : 'bg-white/20'">
    <app-spinner [color]="rxTheme.primaryColor()" size="large"></app-spinner>
  </div>
}

@if (medicareConfirm()) {
  <app-medicare-confirm [visible]="medicareConfirm()" (visibleChange)="medicareConfirm.set($event)" (continue)="onMedicareConfirmSkip()"></app-medicare-confirm>
}

@if (sidemenuActive()) {
  <div class="sidemenu flex justify-center">
    <div class="max-w-screen-sm w-full px-6">
      <ng-icon (click)="closeSidemenu()" class="sidemenu-close" name="heroXMark"></ng-icon>
      <div class="sidemenu-items">
        @for (step of rxStep.compactSteps(); track $index) {
          <div class="sidemenu-item">
            <div class="anchor" [ngClass]="$index < rxStep.compactStepIndex() ? 'bg-green-500' : 'bg-lighttint'">
              @if ($index < rxStep.compactStepIndex()) {
                <ng-icon name="heroCheck"></ng-icon>
              } @else {
                {{ $index + 1 }}
              }
            </div>
            <div class="sidemenu-text">{{ rxStep.getCompactStepLabel(step) }}</div>
          </div>
        }
      </div>
    </div>
  </div>
}