"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.run = run;
const local_parse_1 = require("../local-parse");
function run(name, data, options) {
  return local_parse_1.Parse.Cloud.run(name, data, options);
}
