import { Component,  inject,  model, output, signal } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroArrowRight, heroXMark } from '@ng-icons/heroicons/outline';
import { toObservable } from '@angular/core/rxjs-interop';
import { RxThemeService } from '../../pages/rx/services/rx-theme.service';

@Component({
  selector: 'app-medicare-confirm',
  standalone: true,
  imports: [ButtonComponent, NgIconComponent],
  providers: [provideIcons({ heroXMark, heroArrowRight })],
  templateUrl: './medicare-confirm.component.html',
  styleUrls: ['./medicare-confirm.component.scss'],
})
export class MedicareConfirmComponent {
  public rxTheme = inject(RxThemeService);

  public visible = model<boolean>(false);
  public _continue = output({ alias: 'continue' });

  public shown = signal<boolean>(false);

  constructor() {
    toObservable(this.visible).subscribe(v => v && !this.shown() ? setTimeout(() => this.shown.set(true)) : null);
  }

  close() {
    this.shown.set(false);
    setTimeout(() => this.visible.set(false), 150);
  }

  continue() {
    this._continue.emit();
  }
}
