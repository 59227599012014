import { Injectable, computed, inject, signal } from '@angular/core';
import { RxStateService } from './rx-state.service';
import { RxOrderService } from './rx-order.service';
import { AmplitudeService } from '../../../services/amplitude.service';

export type RxStep = "intro" | "address_search" | "address_details" | "mobile" | 
  "mobile_confirm" | "password" | "script" | "medical_profile_personal" | 
  "medical_profile_clinical" | "review" | "payment" | "end";

export type RxCompactStep = "intro" | "login" | "address" | "script" | 
  "medical_profile" | "end";

@Injectable()
export class RxStepService {
  private $amplitude = inject(AmplitudeService);
  private rxState = inject(RxStateService);
  private rxOrder = inject(RxOrderService);

  public step = signal<RxStep>("intro");
  public isLoggedIn = signal<boolean>(false);
  public orderStatus = this.rxOrder.orderStatus
  public orderHash = this.rxOrder.orderHash;

  public steps = computed<RxStep[]>(() => {
    return [
      "intro",
      ...(this.isLoggedIn() ? [] : ["mobile", "mobile_confirm", "password"]) as RxStep[],
      ...(this.orderStatus() == "incomplete" ? ["address_search", "address_details"] : []) as RxStep[],
      ...(!this.orderHash() ? ["script"] : []) as RxStep[],
      ...(this.orderStatus() == "incomplete" ? ["medical_profile_personal", "medical_profile_clinical"] : []) as RxStep[],
      ...(this.orderStatus() == "priced" ? ["review", "payment"] : []) as RxStep[],
      "end",
    ];
  });

  public compactSteps = computed<RxCompactStep[]>(() => 
    ["intro", "login", "address", "script", "medical_profile", "end"]
  );

  public stepIndex = computed<number>(() => 
    this.steps().findIndex(s => s == this.step())
  );
  
  public compactStepIndex = computed<number>(() => {
    const currStep = this.step();
    const stepMap: [RxStep[], RxCompactStep][] = [
      [["intro"], "intro"],
      [["mobile", "mobile_confirm", "password"], "login"],
      [["address_search", "address_details"], "address"],
      [["script"], "script"],
      [["medical_profile_personal", "medical_profile_clinical"], "medical_profile"],
      [["review", "payment", "end"], "end"],
    ];
    let currCompactStep: RxCompactStep;
    for (const i of stepMap) {
      if (i[0].includes(currStep)) {
        currCompactStep = i[1];
        break;
      }
    }
    return this.compactSteps().findIndex(s => s == currCompactStep);
    
  });

  public showPrevBtn = computed<boolean>(() => 
    this.stepIndex() > 0 && !["review", "payment", "end"].includes(this.step())
  );
  
  public disablePrevBtn = computed<boolean>(() => false);
  public disableNextBtn = computed<boolean>(() => {
    switch (this.step()) {
      case "mobile": return !this.rxState.mobileNumberValid();
      case "mobile_confirm": return this.rxState.otp().length != 6 && !this.rxState.state().mobileNumberVerified;
      case "password": return !this.rxState.passwordValid();
      case "address_search": return this.rxState.state().addressSearch == undefined;
      case "script": return !this.rxState.state().tokenData || this.rxState.state().tokenData?.length == 0;
      case "medical_profile_personal": return !this.rxState.medicalProfilePersonalValid();
      case "medical_profile_clinical": return !this.rxState.medicalProfileClinicalValid();
      case "review": return this.orderStatus() != "priced" || (this.rxOrder.orderHasWeightLossScriptItems() && !this.rxState.termsAccepted());
      default: return false;
    }
  });

  public labelNextBtn = computed<string>(() => {
    if (this.step() == "medical_profile_clinical") {
      return "Submit";
    } else if (this.step() == "review") {
      return "Proceed to payment";
    } else if (this.step() == "payment") {
      return "Submit order";
    } else {
      return "Continue";
    }
  });

  public iconNextBtn = computed<string | undefined>(() => {
    if (["medical_profile_clinical", "payment"].includes(this.step())) {
      return "undefined";
    } else {
      return "heroArrowRightMini";
    }
  });

  public getCompactStepLabel(step: RxCompactStep) {
    return {
      "intro": "Start",
      "login": "Phone number",
      "address": "Delivery address",
      "script": "eScript upload",
      "medical_profile": "Medical profile",
      "end": "Done",
    }[step];
  }

  public setStep(step: RxStep) {
    this.step.set(step);
  }
  
  public getNextStep(): RxStep | undefined {
    const currentStep = this.step();
    const index = this.stepIndex();

    if (currentStep === "password") {
      this.$amplitude.track('AUTH_LOGIN_SUCCESS', { mobileNumber: this.rxState.state().mobileNumber || "unknown" });
      return this.orderStatus() === "incomplete" 
        ? "address_search"
        : this.orderStatus() === "priced" ? "review" : "end";
    }

    if (currentStep === "medical_profile_clinical") {
      return this.orderStatus() === "priced" ? "review" : "end";
    }

    if (index === -1) return;
    return this.steps()[index + 1];
  }

  public getPrevStep(): RxStep | undefined {
    const index = this.stepIndex();
    return this.steps()[index - 1];
  }
} 