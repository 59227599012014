"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubCategory = void 0;
const config_1 = require("../config");
const _BaseParseObject_1 = require("./_BaseParseObject");
const local_parse_1 = require("../local-parse");
class SubCategory extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("SubCategory", attributes);
  }
  static async getSubCategoryCount(category, ignoreDeletedAt) {
    const query = new local_parse_1.Parse.Query(SubCategory);
    query.equalTo("category", category);
    if (!ignoreDeletedAt) {
      query.doesNotExist("deletedAt");
    }
    return await query.count();
  }
  static async getCount() {
    return await new local_parse_1.Parse.Query(SubCategory).count({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async getAll() {
    return await new local_parse_1.Parse.Query(SubCategory).findAll({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async getSubCategories(status, limit = 500, useMasterKey = true) {
    return await new local_parse_1.Parse.Query(SubCategory).equalTo("status", status).limit(limit).find({
      useMasterKey: config_1.C2UConfig.useMasterKey && useMasterKey
    });
  }
  static pointer(id) {
    return {
      __type: "Pointer",
      className: "SubCategory",
      objectId: id
    };
  }
}
exports.SubCategory = SubCategory;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], SubCategory.prototype, "name", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], SubCategory.prototype, "category", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], SubCategory.prototype, "slug", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], SubCategory.prototype, "canonical", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], SubCategory.prototype, "status", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], SubCategory.prototype, "catId", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], SubCategory.prototype, "parentId", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], SubCategory.prototype, "deletedAt", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], SubCategory.prototype, "imageThumb", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], SubCategory.prototype, "image", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], SubCategory.prototype, "itemCount", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], SubCategory.prototype, "count", void 0);
local_parse_1.Parse.Object.registerSubclass("SubCategory", SubCategory);
