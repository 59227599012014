"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Partner = void 0;
const config_1 = require("../config");
const _BaseParseObject_1 = require("./_BaseParseObject");
const local_parse_1 = require("../local-parse");
class Partner extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("Partner", attributes);
  }
  static async getByPartnerID(id, useMasterKey = true) {
    return await new local_parse_1.Parse.Query(Partner).equalTo("shortId", id).first({
      useMasterKey: config_1.C2UConfig.useMasterKey && useMasterKey
    });
  }
  static pointer(id) {
    return {
      __type: "Pointer",
      className: "Partner",
      objectId: id
    };
  }
}
exports.Partner = Partner;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], Partner.prototype, "name", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Number)], Partner.prototype, "serviceFee", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], Partner.prototype, "shortId", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Partner.prototype, "userServiceFee", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Boolean)], Partner.prototype, "reviewToggle", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], Partner.prototype, "title", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], Partner.prototype, "description", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Partner.prototype, "webhookConfiguration", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Partner.prototype, "logo", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Partner.prototype, "options", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Partner.prototype, "notificationLayout", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Partner.prototype, "widgetLayoutConfiguration", void 0);
local_parse_1.Parse.Object.registerSubclass("Partner", Partner);
