"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseParseUser = exports.BaseParseObject = exports.Accessor = void 0;
const local_parse_1 = require("../local-parse");
const config_1 = require("../config");
const defaultAccessorOptions = {
  read: true,
  write: true
};
const Accessor = (options = {}) => {
  options = Object.assign(Object.assign({}, defaultAccessorOptions), options);
  return function (target, propertyKey) {
    Object.defineProperty(target, propertyKey, Object.assign(Object.assign(Object.assign({}, options.read && {
      get: function () {
        return this.getTyped(propertyKey);
      }
    }), options.write && {
      set: function (value) {
        this.setTyped(propertyKey, value);
      }
    }), {
      enumerable: true,
      configurable: true
    }));
  };
};
exports.Accessor = Accessor;
class BaseParseObject extends local_parse_1.Parse.Object {
  constructor(className, attributes) {
    super(className, attributes);
  }
  getTyped(key) {
    return this.get(key);
  }
  setTyped(key, value) {
    this.set(key, value);
  }
  static Query() {
    return new local_parse_1.Parse.Query(this);
  }
  static async getByID(id) {
    const query = new local_parse_1.Parse.Query(this);
    return await query.get(id, {
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async getFByID(id, includes = []) {
    const query = new local_parse_1.Parse.Query(this);
    if (includes.length > 0) {
      for (let include of includes) {
        query.include(include);
      }
    }
    return await query.equalTo("objectId", id).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
}
exports.BaseParseObject = BaseParseObject;
class BaseParseUser extends local_parse_1.Parse.User {
  constructor(attributes) {
    super(attributes);
  }
  getTyped(key) {
    return this.get(key);
  }
  setTyped(key, value) {
    this.set(key, value);
  }
}
exports.BaseParseUser = BaseParseUser;
