import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { WaveBgComponent } from '../wave-bg/wave-bg.component';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroXMark } from '@ng-icons/heroicons/outline';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-script-modal',
  standalone: true,
  imports: [
    CommonModule,
    WaveBgComponent,
    NgIconComponent,
    ButtonComponent,
  ],
  providers: [provideIcons({ heroXMark })],
  templateUrl: './script-modal.component.html',
  styleUrl: './script-modal.component.scss'
})
export class ScriptModalComponent {

  public message = input<string | undefined>();
  public onClose = output<void>({ alias: 'close' });

  close(event: MouseEvent) {
    event.stopPropagation();
    this.onClose.emit();
  }

}
