"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __exportStar = this && this.__exportStar || function (m, exports) {
  for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initParse = void 0;
const local_parse_1 = require("../local-parse");
__exportStar(require("./default"), exports);
const initParse = options => {
  local_parse_1.Parse.CoreManager.set('SERVER_URL', options.SERVER_URL);
  if (options.LIVEQUERY_SERVER_URL) {
    local_parse_1.Parse.CoreManager.set('LIVEQUERY_SERVER_URL', options.LIVEQUERY_SERVER_URL);
  }
  if (options.MASTER_KEY) {
    local_parse_1.Parse.initialize(options.APP_ID, '', options.MASTER_KEY);
  } else {
    local_parse_1.Parse.initialize(options.APP_ID);
  }
};
exports.initParse = initParse;
