import { CommonModule } from '@angular/common';
import { Component, computed, ElementRef, input, Input, OnDestroy, OnInit, Optional, output, Self, signal, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormsModule, NgControl, ValidatorFn, Validators } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroEye, heroEyeSlash, heroXCircle } from '@ng-icons/heroicons/outline';

export type TInputTypes = 'text' | 'password' | 'tel' | 'email' | 'number' | 'date';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [CommonModule, FormsModule, NgIconComponent],
  providers: [provideIcons({ heroXCircle, heroEye, heroEyeSlash })],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('inputElement', { static: false }) inputElement!: ElementRef<HTMLInputElement>;

  // Inputs
  public icon = input<string | undefined>(undefined);
  public customIcon = input<boolean>(false);
  public label = input<string | undefined>(undefined);
  public help = input<string | undefined>(undefined);
  public type = input<TInputTypes>('text');
  public debounceTime = input<number>(0);
  public placeholder = input<string | undefined>(undefined);
  public isRequired = input<boolean>(false, { alias: 'required' });
  public step = input<number | null>(null);
  public showPasswordEyeOption = input<boolean>(false);
  public showClearButtonOption = input<boolean>(false);
  public externalError = input<string | undefined>(undefined);
  public hasAction = input<boolean>(false);
  public actionLabel = input<string | undefined>(undefined);
  public actionIcon = input<"copy-outline" | undefined>(undefined);
  public autocomplete = input<string | undefined>(undefined);

  public autocompleteValue = computed(() => this.autocomplete() || "off");

  // Outputs
  public iconClick = output<MouseEvent>();
  public actionClick = output<void>();

  public min = input<number | null>(null);
  public max = input<number | null>(null);

  @Input() set disabled(value: boolean) {
    this.setDisabledState(value);
  }

  @Input() set value(val: string | number | Date) {
    this.writeValue(val);
  }

  get value(): string | number | Date {
    return this._value();
  }

  //@Output() valueChange = new EventEmitter<string | number | Date>();
  public valueChange = output<string | number | Date>();

  showPasswordEye = computed(() => {
    return this.showPasswordEyeOption() && this.type() == "password";
  });

  showClearButton = computed(() => {
    return this.showClearButtonOption() && this.canClear();
  });

  canClear = computed(() => {
    return (this.type() === 'text' || this.type() === 'email' || this.type() === 'tel' || this.type() === 'password') && this.value !== '';
  });


  private _value = signal<string | number | Date>('');
  public displayValue = signal<string>('');
  public isDisabled = signal(false);
  public showError = signal(false);
  public touched = signal(false);
  public showExternalError = computed<boolean>(() => !!this.externalError() && this.touched());
  
  public passwordPeekActive = signal(false);
  public errorMessage = signal<string | undefined>(undefined);
  public isFocused = signal(false);

  get inputType(): string {
    if (this.type() == 'password' && this.passwordPeekActive()) return 'text';
    return ['password' , 'tel' , 'number' , 'date'].includes(this.type()) ? this.type() : 'text';
  }

  private onTouched: () => void = () => { };
  private onChange: (value: string | number | Date) => void = () => { };
  private inputSubject = new Subject<string>();
  private destroy$ = new Subject<void>();

  constructor(@Optional() @Self() private ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.ngControl && this.ngControl.control) {
      this.setupControlSubscriptions(this.ngControl.control);
    }
    this.setupInputSubscription();
  }

  containerClass = computed(() => ({
    'container': true,
    'disabled': this.disabled,
    'invalid': this.showError,
    'focused': this.isFocused()
  }));

  clear(): void {
    if(this.ngControl && this.ngControl.control) {
      this.ngControl.control?.reset();
      //this.ngControl.control?.markAsTouched();
      this.ngControl.control?.setValue('');
      this.inputElement.nativeElement.focus();
    } else {
      this.writeValue('');
      this.valueChange.emit('');
      this.inputElement.nativeElement.focus();
    
    }

  }

  action() {
    this.actionClick.emit();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupInputSubscription() {
    this.inputSubject.pipe(
      takeUntil(this.destroy$),
      debounceTime(this.debounceTime()),
      distinctUntilChanged()
    ).subscribe(value => {
      this.updateValue(value);
      this.onChange(this._value());
      this.valueChange.emit(this._value());
      this.updateErrors();
    });
  }

  togglePasswordPeek() {
    const state = this.passwordPeekActive();
    this.passwordPeekActive.set(!state);
  }

  onClearTouchStart(event: TouchEvent): void {
    // Prevent default touch behavior for the clear button
    // event.preventDefault();
    this.clear();
  }

  writeValue(value: string | number | Date): void {
    this._value.set(value);
    this.updateDisplayValue();
  }

  registerOnChange(fn: (value: string | number | Date) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  onInput(event: Event): void {
    // const inputValue = (event.target as HTMLInputElement).value;
    // this.updateValue(inputValue);
    // this.onChange(this._value());
    // this.valueChange.emit(this._value());
    // this.updateErrors();
    const inputValue = (event.target as HTMLInputElement).value;
    this.inputSubject.next(inputValue);
  }

  onFocus(): void {
    this.isFocused.set(true);
  }

  onBlur(): void {
    this.touched.set(true);
    this.onTouched();
    this.updateErrors();
    this.isFocused.set(false);
  }

  private setupControlSubscriptions(control: AbstractControl) {
    control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateErrors();
    });

    control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value !== this._value()) {
        this.writeValue(value);
        this.valueChange.emit(this._value());
      }
    });
  }

  private updateValue(inputValue: string): void {
    switch (this.type()) {
      case 'number':
        this.writeValue(inputValue === '' ? '' : parseFloat(inputValue));
        break;
      case 'date':
        this.writeValue(inputValue ? new Date(inputValue) : '');
        break;
      default:
        this.writeValue(inputValue);
    }
  }

  private updateDisplayValue(): void {
    if (this.type() === 'date' && this._value() instanceof Date) {
      this.displayValue.set(this.formatDate(this._value() as Date));
    } else if (this.type() === 'number' && typeof this._value() === 'number') {
      this.displayValue.set(this._value().toString());
    } else {
      this.displayValue.set(this._value() as string);
    }
  }

  private formatDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  private updateErrors(): void {
    if (this.ngControl && this.ngControl.control) {
      const control = this.ngControl.control;
      this.showError.set((control.touched || control.dirty) && control.invalid);
      this.errorMessage.set(this.showError() ? this.getErrorMessage(control.errors) : undefined);
    } else {
      this.showError.set(false);
      this.errorMessage.set(undefined);
    }
  }

  private getErrorMessage(errors: any): string {
    if (!errors) return '';
    if (errors['required']) return 'This field is required.';
    if (errors['email']) return 'Please enter a valid email address.';
    if (errors['minlength']) return `Minimum length is ${errors['minlength'].requiredLength} characters.`;
    if (errors['min']) return `Minimum value is ${errors['min'].min}.`;
    if (errors['max']) return `Maximum value is ${errors['max'].max}.`;
    if (errors['matDatepickerParse']) return 'Invalid date format.';
    if (errors['notANumber']) return 'Please enter a valid number.';
    if (errors['futureDate']) return 'Future date is not allowed.';
    if (errors['invalidConcessionCard']) return 'Please enter a valid concession card number.';
    return 'Invalid input.';
  }

  private addValidators(): void {
    if (this.ngControl && this.ngControl.control) {
      const validators: ValidatorFn[] = [];

      if (this.isRequired()) {
        validators.push(Validators.required);
      }

      if (this.type() === 'number') {
        validators.push(this.numberValidator());
        if (this.min() !== null) validators.push(Validators.min(this.min()!));
        if (this.max() !== null) validators.push(Validators.max(this.max()!));
      }

      if (validators.length > 0) {
        this.ngControl.control.setValidators(validators);
        this.ngControl.control.updateValueAndValidity();
      }
    }
  }

  private numberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value === null || value === '') return null;  // Allow empty values
      const isValid = !isNaN(parseFloat(value)) && isFinite(value);
      return isValid ? null : { 'notANumber': { value: value } };
    };
  }

  clickedIcon(event: MouseEvent): void {
    this.iconClick.emit(event);
  }
}
