"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PharmacyComment = void 0;
const Order_1 = require("./Order");
const User_1 = require("./User");
const _BaseParseObject_1 = require("./_BaseParseObject");
const config_1 = require("../config");
const local_parse_1 = require("../local-parse");
class PharmacyComment extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("PharmacyComment", attributes);
  }
  static async savePharmacyCommentDetails(order, comment) {
    try {
      const user = await User_1.User.getUserByUsername('admin');
      if (!user) throw new local_parse_1.Parse.Error(local_parse_1.Parse.Error.OBJECT_NOT_FOUND, "No user found by that username.");
      const pharmacyComment = new PharmacyComment();
      pharmacyComment.comment = comment;
      pharmacyComment.order = order;
      pharmacyComment.username = "Server";
      pharmacyComment.user = user;
      await pharmacyComment.save(null, {
        useMasterKey: config_1.C2UConfig.useMasterKey
      });
    } catch (error) {
      throw new local_parse_1.Parse.Error(local_parse_1.Parse.Error.OTHER_CAUSE, JSON.stringify(error));
    }
  }
}
exports.PharmacyComment = PharmacyComment;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], PharmacyComment.prototype, "comment", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], PharmacyComment.prototype, "user", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Order_1.Order)], PharmacyComment.prototype, "order", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], PharmacyComment.prototype, "username", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Boolean)], PharmacyComment.prototype, "resolved", void 0);
local_parse_1.Parse.Object.registerSubclass("PharmacyComment", PharmacyComment);
