import { Component, input } from '@angular/core';
import { Cloud } from '@chemist2u/types-client/C2U/Cloud';

@Component({
  selector: 'app-partner-logo',
  standalone: true,
  imports: [],
  templateUrl: './partner-logo.component.html',
  styleUrl: './partner-logo.component.scss'
})
export class PartnerLogoComponent {
  public partner = input<Cloud.FetchPartnerBrandResponse>();
}
