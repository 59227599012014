<app-title-block heading="Password" [description]="mode() == 'set' ? 'Please set your password' : 'Please enter your password, you may have received a temporary password by SMS.'"></app-title-block>
<div class="py-4">
  <app-input type="password" [required]="true" label="Password" [(value)]="password" [showPasswordEyeOption]="true" />
  @if (mode() == "set") {
    <div class="mt-2">
      <app-password-rules [password]="password()" (validation)="validPattern.set($event)" />
    </div>
    <div class="mt-4">
      <app-input type="password" [required]="true" label="Confirm Password" [(value)]="confirmPassword" [showPasswordEyeOption]="true" />
      @if (password() != confirmPassword()) {
        <div class="mt-2">
          <div class="text-red text-sm">Passwords do not match!</div>
        </div>
      }
    </div>
  }
</div>