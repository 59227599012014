import C2U from '@chemist2u/types-client';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, inject, viewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ToastComponent } from "./components/toast/toast.component";
import { ToastService } from './services/toast.service';
import { AmplitudeService } from './services/amplitude.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ToastComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements AfterViewInit {
  private $amplitude = inject(AmplitudeService);
  private $toast = inject(ToastService);

  public toastComponent = viewChild(ToastComponent);

  constructor() {
    this.initParse();
    this.$amplitude.init();
  }

  private initParse() {
    C2U.initParse({
      APP_ID: environment.parseAppId,
      SERVER_URL: environment.parseServerUrl,
    });
  }

  ngAfterViewInit(): void {
    this.$toast.register(this.toastComponent()!);
  }
}