import { CommonModule } from '@angular/common';
import { Component, computed, inject, output, signal, viewChild } from '@angular/core';
import { TitleBlockComponent } from "../../../../components/title-block/title-block.component";
import { toObservable } from '@angular/core/rxjs-interop';
import { InputComponent } from '../../../../components/input/input.component';
import { MedicareInputComponent } from '../../../../components/medicare-input/medicare-input.component';
import { CheckboxComponent } from '../../../../components/checkbox/checkbox.component';
import { FormsModule } from '@angular/forms';
import { AmplitudeService } from '../../../../services/amplitude.service';

export type TMedicalProfilePersonalValue = {
  fullName: string;
  dateOfBirth: Date;
  age: number;
  weight?: number;
  medicare?: TMedicare;
  safetyNet?: string;
  concession?: string;
};

export type TMedicare = { medicareCardNumber: number, irn: number };

@Component({
  selector: 'step-medical-profile-personal',
  standalone: true,
  imports: [
    CommonModule,
    TitleBlockComponent,
    InputComponent,
    MedicareInputComponent,
    CheckboxComponent,
    FormsModule,
  ],
  templateUrl: './medical-profile-personal.step.html',
  styleUrl: './medical-profile-personal.step.scss'
})
export class MedicalProfilePersonalStep {
  private $amplitude = inject(AmplitudeService);

  public medicareInput = viewChild<MedicareInputComponent>('medicareInput');
  public safetyNetCheck = viewChild<CheckboxComponent>('safetyNetCheck');
  public concessionCheck = viewChild<CheckboxComponent>('concessionCheck');

  public valueChange = output<{ value: TMedicalProfilePersonalValue, valid: boolean }>();

  public fullName = signal<string | Date | number>("");
  public dateOfBirth = signal<string | Date | number>("");
  public weight = signal<string | Date | number>("");
  public medicare = signal<{ medicareCardNumber: number, irn: number } | undefined>(undefined);
  public safetyNet = signal<string | Date | number>("");
  public concession = signal<string | Date | number>("");

  public age = computed<number>(() => {
    const date = this.dateOfBirth() as Date;
    if (!date) return -1;
    const today = new Date();
    const diffMs = today.getTime() - date.getTime(); // Difference in ms
    const ageYears = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365.25)); // Convert ms to years
    return ageYears;
  })

  public values = computed<TMedicalProfilePersonalValue>(() => {
    return {
      fullName: this.fullName() as string,
      dateOfBirth: this.dateOfBirth() as Date,
      age: this.age(),
      ...(this.age() != -1 && this.age() < 13 && {
        weight: this.weight() as number,
      }),
      medicare: this.medicare() as TMedicare,
      ...(this.safetyNetCheck()?.checked() && {
        safetyNet: this.safetyNet() as string,
      }),
      ...(this.concessionCheck()?.checked() && {
        concession: this.concession() as string,
      }),
    };
  });

  constructor() {
    this.$amplitude.track('GENERIC_NAVIGATE', { to_page: 'medical_profile_personal' });
    toObservable(this.values).subscribe(v => {
      this.valueChange.emit({ value: v, valid: this.checkValidity() });
    });
  }

  public checkValidity() {
    const values = this.values();
    const fullNameValid = (values.fullName as string).trim().length > 0;
    const dateOfBirthValid = values.dateOfBirth as Date && (values.dateOfBirth as Date) < new Date();
    const weightValid = (values.age >= 13 || values.weight) as boolean;
    const medicareValid = (!this.medicareInput()?.errors() || (this.medicareInput()?.errors() && this.medicareInput()?.errors().length == 0)) as boolean;
    const safetyNetValid = !values.hasOwnProperty("safetyNet") || (values.safetyNet as string).trim().length > 0;
    const concessionCardValid = !values.hasOwnProperty("concession") || /^[0-9]{9}[A-Za-z]$/.test(values.concession as string);
    return fullNameValid && dateOfBirthValid && weightValid && medicareValid && safetyNetValid && concessionCardValid;
  }
}
