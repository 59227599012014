"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const defaultMod = __importStar(require("./C2U/default"));
const Agenda_1 = require("./C2U/Agenda");
const Amplitude_1 = require("./C2U/Amplitude");
const AppUpdates_1 = require("./C2U/AppUpdates");
const Cloud_1 = require("./C2U/Cloud");
const DoorDash_1 = require("./C2U/DoorDash");
const Elastic_1 = require("./C2U/Elastic");
const Email_1 = require("./C2U/Email");
const Google_1 = require("./C2U/Google");
const MenuLog_1 = require("./C2U/MenuLog");
const Messaging_1 = require("./C2U/Messaging");
const Reports_1 = require("./C2U/Reports");
const Serviceability_1 = require("./C2U/Serviceability");
const Socket_1 = require("./C2U/Socket");
const Square_1 = require("./C2U/Square");
const Uber_1 = require("./C2U/Uber");
const _ParseObjects = __importStar(require("./C2U/ParseObjects/index.client"));
const _Interfaces = __importStar(require("./C2U/Interfaces"));
var C2U;
(function (C2U) {
  C2U.initParse = defaultMod.initParse;
  C2U.ParseACL = defaultMod.ParseACL;
  C2U.ParseGeoPoint = defaultMod.ParseGeoPoint;
  C2U.ParseFile = defaultMod.ParseFile;
  C2U.ParseUser = defaultMod.ParseUser;
  C2U.ERoles = defaultMod.ERoles;
  C2U.Agenda = Agenda_1.Agenda;
  C2U.Amplitude = Amplitude_1.Amplitude;
  C2U.AppUpdates = AppUpdates_1.AppUpdates;
  C2U.Cloud = Cloud_1.Cloud;
  C2U.DoorDash = DoorDash_1.DoorDash;
  C2U.Elastic = Elastic_1.Elastic;
  C2U.Email = Email_1.Email;
  C2U.Google = Google_1.Google;
  C2U.ParseObjects = _ParseObjects;
  C2U.MenuLog = MenuLog_1.MenuLog;
  C2U.Messaging = Messaging_1.Messaging;
  C2U.Reports = Reports_1.Reports;
  C2U.Serviceability = Serviceability_1.Serviceability;
  C2U.Socket = Socket_1.Socket;
  C2U.Square = Square_1.Square;
  C2U.Uber = Uber_1.Uber;
  C2U.Interfaces = _Interfaces;
})(C2U || (C2U = {}));
exports.default = C2U;
