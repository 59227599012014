import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as amplitude from '@amplitude/analytics-browser';
import { AmplitudeEventKey, AmplitudeEventPropsMap, AmplitudeEvents } from './amplitude.events';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
  private route = inject(ActivatedRoute);

  private readonly silentMode = true;

  private userId?: string;
  private identity?: amplitude.Identify;

  public async init() {
    this.consoleLog("Init");
    await this.initAmplitude();
    this.watchQueryParams();
  }

  public setUserId(id: string | undefined) {
    this.userId = id;
    amplitude.setUserId(this.userId);
  }

  private async initAmplitude() {
    const appVersion = "web:" + (environment.hostIsShop ? "shop" : "rx");
    amplitude.init(environment.amplitudeKey, this.userId, { appVersion });
    this.identity = new amplitude.Identify();
    amplitude.identify(this.identity);
  }

  private watchQueryParams() {
    this.route.queryParams.subscribe(params => {
      const keys = Object.keys(params);
      for (const key of keys) {
        this.addUserProp(key, params[key]);
      }
    });
  }

  // Overload for events with required props
  public track<K extends keyof AmplitudeEventPropsMap>(eventKey: K, props: AmplitudeEventPropsMap[K]): void;
  // Overload for events without props
  public track<K extends Exclude<AmplitudeEventKey, keyof AmplitudeEventPropsMap>>(eventKey: K): void;

  public track<K extends AmplitudeEventKey>(
    eventKey: K,
    props?: K extends keyof AmplitudeEventPropsMap ? AmplitudeEventPropsMap[K] : never
  ): void {
    try {
      const event = AmplitudeEvents[eventKey as keyof typeof AmplitudeEvents];
      this.consoleLog("Track Event", event, props);
      amplitude.track(event, props)
    } catch (err) {
      this.consoleLog("Track Event Error", err);
    }
  }

  public async addUserProp(prop: string, value: any): Promise<void>{
    if (!this.identity) return;
    this.consoleLog("Add User Prop", prop, value);
    this.identity.set(prop, value);
    await amplitude.identify(this.identity);
  }

  public async resetUser(): Promise<void> {
    try {
      this.consoleLog("Reset User");
      amplitude.setUserId(undefined);
      amplitude.setSessionId(Date.now());
    } catch (err) {
      this.consoleLog("Reset User Error", err);
    }
  }

  private consoleLog(...args: any[]) {
    if (this.silentMode) return;
    console.log("[AmplitudeService]", ...args);
  }

}