<app-title-block heading="Create medical profile"></app-title-block>
<div class="mt-2">
  <div class="text-primary text-lg font-semibold leading-7">Clinical information</div>
  <div class="text-secondary text-base leading-6 mt-2">Some questions from our pharmacist.</div>
</div>
<div class="mt-8">
  <app-radio-select #otherMedsInp
    label="Are you currently taking any other medications?"
    help="Prescription or non-prescription"
    [required]="true"
    [options]="[{ label: 'Yes', value: true }, { label: 'No', value: false }]"
  />
  @if (otherMedsInp.value() === true) {
    <app-longtext-input [(ngModel)]="otherMeds" class="mt-3" label="Other medications" [required]="true"></app-longtext-input>
  }
</div>
<div class="mt-8">
  <app-radio-select #allergiesInp
    label="Do you have any allergies?"
    [required]="true"
    [options]="[{ label: 'Yes', value: true }, { label: 'No', value: false }]"
  />
  @if (allergiesInp.value() === true) {
    <app-longtext-input [(ngModel)]="allergies" class="mt-3" label="Allergies" [required]="true"></app-longtext-input>
  }
</div>