import { CommonModule } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { ScriptSliderComponent } from "../../../../components/script-slider/script-slider.component";
import { InfoBoxComponent } from "../../../../components/info-box/info-box.component";
import { RxThemeService } from '../../services/rx-theme.service';
import { AmplitudeService } from '../../../../services/amplitude.service';
import { Cloud } from '@chemist2u/types-client/C2U/Cloud';

@Component({
  selector: 'step-intro',
  standalone: true,
  imports: [CommonModule, ScriptSliderComponent, InfoBoxComponent],
  templateUrl: './intro.step.html',
  styleUrl: './intro.step.scss'
})
export class IntroStep {
  private $amplitude = inject(AmplitudeService);
  public rxTheme = inject(RxThemeService);
  
  public partner = input.required<Cloud.FetchPartnerBrandResponse>();

  public heading = computed<string>(() => this.partner().title || "Fast and convenient prescription delivery");
  public description = computed<string | undefined>(() => this.partner().description);

  public infoBoxContent = computed<string>(() => {
    const pre = `Please note, we do not accept paper scripts.`;
    const link = this.partner()?.eScriptLink || undefined;
    const btn =  link ? `<br><a class="font-semibold cursor-pointer underline underline-offset-4" href="${link}" target="_blank">Need eScript?</a>` : '';
    return pre + btn;
  });

  constructor() {
    this.$amplitude.track('GENERIC_NAVIGATE', { to_page: 'intro' });
  }
}
