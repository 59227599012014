import { Component, input, signal } from '@angular/core';
import { RadioComponent } from '../radio/radio.component';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'app-radio-select',
  templateUrl: './radio-select.component.html',
  styleUrls: ['./radio-select.component.scss'],
  standalone: true,
  imports: [RadioComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RadioSelectComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: RadioSelectComponent
    }
  ]
})
export class RadioSelectComponent implements ControlValueAccessor, Validator {
  public touched = signal<boolean>(false);
  public value = signal<any>(undefined);
  public errorMessage = signal<string | undefined>(undefined);

  public label = input<string>("");
  public help = input<string | undefined>(undefined);
  public required = input<boolean>(false);
  public options = input<{ label: string, value: any }[]>([]);

  select(value: any) {
    this.markAsTouched();
    this.value.set(value);
    this.onChange(value);
  }

  setErrorMessage(errors: ValidationErrors) {
    const key = Object.keys(errors)[0];
    let msg = "Invalid field";
    switch (key) {
      case "required":
        msg = "This field is required.";
        break;
    }
    this.errorMessage.set(msg);
  }

  // Form Control

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: string) {
    this.value.set(value);
  }
  
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched()) {
      this.onTouched();
      this.touched.set(true);
    }
  }
  
  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const errors: ValidationErrors = {
      ...(this.required() && value == undefined && {
        required: true
      }),
    };
    if (Object.keys(errors).length) {
      this.setErrorMessage(errors);
      return errors;
    }
    this.errorMessage.set(undefined);
    return null;
  }

}
