import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroXMark } from '@ng-icons/heroicons/outline';

type Klass = string | string[] | Set<string> | { [klass: string]: any; } | null | undefined;

@Component({
  selector: 'app-simple-modal',
  standalone: true,
  imports: [CommonModule, NgIconComponent],
  providers: [provideIcons({ heroXMark })],
  templateUrl: './simple-modal.component.html',
  styleUrl: './simple-modal.component.scss'
})
export class SimpleModalComponent {
  public styleClass = input<Klass>();

  public heading = input<string>();
  public close = output<void>();
}
