const IS_SHOP = false;

const environment_prod = {
    production: true,
    hostIsShop: IS_SHOP,
    hostIsRx: !IS_SHOP,
    parseAppId: '47civNnjdoUKsukw0xxS',
    parseServerUrl: 'https://manage1.chemist2u.com.au/parse',
    squareAppId: 'sq0idp-uk651ep5-We8yTt8xWN1zg',
    squareLocationId:'MNKH3V06R5XGZ',
    amplitudeKey: 'b35dccd7802be1e7415a97c13baba7e9',
    sentryDsn: 'https://dc5d1b481c5c0aa6d43ec97ed33f797a@o1407837.ingest.us.sentry.io/4508408218255360',
    appUrl: 'https://app.chemist2u.com.au/tabs/home',
    appBaseUrl: 'https://app.chemist2u.com.au',
    rxBaseUrl: 'https://rx.c2u.co',
};

const environment_stag = {
    production: false,
    hostIsShop: IS_SHOP,
    hostIsRx: !IS_SHOP,
    parseAppId: '47civNnjdoUKsukw0xxS',
    parseServerUrl: 'https://stagingmanage.c2u.co/parse',
    squareAppId: 'sandbox-sq0idb-VGA_t-QZabpjfo65c9SJHg',
    squareLocationId:'GA50T8BTNEPXG',
    amplitudeKey: '0a9ff8e4068702ff98437ef81e6c9438',
    sentryDsn: 'https://dc5d1b481c5c0aa6d43ec97ed33f797a@o1407837.ingest.us.sentry.io/4508408218255360',
    appUrl: 'https://app.chemist2u.com.au/tabs/home',
    appBaseUrl: 'https://app.chemist2u.com.au',
    rxBaseUrl: 'https://rx-v2.c2u.co',
};

export const environment = environment_stag;