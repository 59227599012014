<div class="content">
  <div class="bg" [class.visible]="shown()"></div>
  <div class="modal" [class.visible]="shown()">
    <div class="w-full flex justify-center border-b">
      <div class="header max-w-screen-sm w-full px-6">
        <div class="button" (click)="close()">
          <ng-icon name="heroXMark"></ng-icon>
        </div>
      </div>
    </div>
    <div class="max-w-screen-sm w-full px-6">
      <div class="mt-2 mb-4 text-primary">
        <div class="text-xl font-semibold leading-8">You haven't added a Medicare number</div>
        <div class="mt-2 text-sm leading-5">If you don't add a Medicare number, you won't receive government PBS subsidies so medicine may be more expensive.<br>If you do have a card, we'd strongly encourage you to add it before the next step</div>
      </div>
      <div class="mt-2 py-2.5">
        <app-button (click)="close()" [buttonStyleOverrides]="rxTheme.buttonStyleOverrides()" label="Add Medicare number" />
        <app-button class="mt-3" (click)="continue()" [buttonStyleOverrides]="rxTheme.outlinedButtonStyleOverrides()" label="Skip" iconPosition="right" icon="heroArrowRight" />
      </div>
    </div>
  </div>
</div>