import { CommonModule } from '@angular/common';
import { Component, output, inject } from '@angular/core';
import { TitleBlockComponent } from '../../../../components/title-block/title-block.component';
import { AddressType, PlaceAutocompleteResult, PlaceData } from '@googlemaps/google-maps-services-js';
import { AddressSearchComponent } from '../../../../components/address-search/address-search.component';
import { AmplitudeService } from '../../../../services/amplitude.service';

@Component({
  selector: 'step-address-search',
  standalone: true,
  imports: [CommonModule, TitleBlockComponent, AddressSearchComponent],
  templateUrl: './address-search.step.html',
  styleUrl: './address-search.step.scss'
})
export class AddressSearchStep {
  private $amplitude = inject(AmplitudeService);

  public addressSelect = output<{ prediction: PlaceAutocompleteResult, placeData: PlaceData }>();

  constructor() {
    this.$amplitude.track('GENERIC_NAVIGATE', { to_page: 'address_search' });
  }

  public async onAddressSelect(data: { prediction: PlaceAutocompleteResult, placeData: PlaceData }) {
    this.addressSelect.emit({ prediction: data.prediction, placeData: data.placeData });
  }

}
