export interface AmplitudeEventProps {
	[key: string]: string | number | string[] | number[]
}

export type AmplitudeEventPropsMap = {
	// Authentication Flow
	AUTH_LOGIN_PHONE_ENTERED: { mobileNumber: string };
	AUTH_LOGIN_SUCCESS: { mobileNumber: string };
	AUTH_OTP_SEND_START: { mobileNumber: string };
	AUTH_OTP_SEND_SUCCESS: { mobileNumber: string };
	AUTH_OTP_SEND_FAIL: { mobileNumber: string, error: string };
	AUTH_OTP_VERIFY_START: { mobileNumber: string };
	AUTH_OTP_VERIFY_SUCCESS: { mobileNumber: string };
	AUTH_OTP_VERIFY_FAIL: { mobileNumber: string, error: string };

	// Order Flow
	ORDER_CREATE_START: { partnerId: string };
	ORDER_CREATE_SUCCESS: { orderId: string };
	ORDER_CREATE_FAIL: { error: string };
	ORDER_UPDATE_START: { orderId: string };
	ORDER_UPDATE_SUCCESS: { orderId: string };
	ORDER_UPDATE_FAIL: { orderId: string, error: string };
	ORDER_PAY_START: { orderId: string };
	ORDER_PAY_SUCCESS: { orderId: string };
	ORDER_PAY_FAIL: { orderId: string, error: string };
	ORDER_VALIDATE_START: { orderHash: string };
	ORDER_VALIDATE_SUCCESS: { orderId: string };
	ORDER_VALIDATE_FAIL: { error: string };

	// Script Flow
	ADD_SCRIPT_TOKEN_TYPE: { token: string };
	ADD_SCRIPT_TOKEN_PASTE: { token: string };
	ADD_SCRIPT_TOKEN_VALIDATION_SUCCESS: { token: string };
	ADD_SCRIPT_TOKEN_VALIDATION_FAIL: { token: string, error: string };
	ADD_SCRIPT_DELETE: { token: string };

	// Address Events
	ADDRESS_FULFILLMENT_METHOD: { methodName: string };
	ADDRESS_SELECTED_SEARCH: { postalCode: string };
	ADDRESS_DELIVERY_NOTES: { deliveryNotes: string };

	// Navigation & UI
	GENERIC_NAVIGATE: { to_page: string };
	SIDEMENU_TOGGLE: { state: 'open' | 'close' };

	// Doctor/Concierge Flow
	DOCTOR_ORDER_START: { mobileNumber: string };
	DOCTOR_ORDER_SUCCESS: { mobileNumber: string };
	DOCTOR_ORDER_FAIL: { mobileNumber: string, error: string };
	DOCTOR_TOKEN_ADD_START: { token: string };
	DOCTOR_TOKEN_ADD_SUCCESS: { token: string };
	DOCTOR_TOKEN_ADD_FAIL: { token: string, error: string };
	DOCTOR_TOKEN_DELETE: { token: string };
	DOCTOR_TOKEN_PASTE: { token: string };
	DOCTOR_FORM_CLEAR: { mobileNumber: string };
	DOCTOR_MODAL_SHOW: { modalType: 'delivery' | 'privacy' | 'terms' };
	DOCTOR_MODAL_CLOSE: { modalType: 'delivery' | 'privacy' | 'terms' };

	// QR Code Scanning Events
	ADD_SCRIPT_QR_TOKEN_SCAN_SUCCESS: { token: string };
	ADD_SCRIPT_QR_TOKEN_SCAN_FAIL: { error: string };
};

export type AmplitudeEventKey = keyof typeof AmplitudeEvents;

export const AmplitudeEvents = {
	// Authentication Events
	AUTH_LOGIN_PHONE_ENTERED: "User entered phone number",
	AUTH_LOGIN_SUCCESS: "Login successful",
	AUTH_OTP_SEND_START: "OTP send started",
	AUTH_OTP_SEND_SUCCESS: "2FA code sent",
	AUTH_OTP_SEND_FAIL: "OTP send failed",
	AUTH_OTP_VERIFY_START: "OTP verification started",
	AUTH_OTP_VERIFY_SUCCESS: "2FA code success",
	AUTH_OTP_VERIFY_FAIL: "2FA code failed",
	AUTH_SESSION_EXPIRED: "Session expired",

	// Order Flow Events
	ORDER_CREATE_START: "Order creation started",
	ORDER_CREATE_SUCCESS: "Order created successfully",
	ORDER_CREATE_FAIL: "Order creation failed",
	ORDER_UPDATE_START: "Order update started",
	ORDER_UPDATE_SUCCESS: "Order updated successfully",
	ORDER_UPDATE_FAIL: "Order update failed",
	ORDER_PAY_START: "Order payment started",
	ORDER_PAY_SUCCESS: "Order payment successful",
	ORDER_PAY_FAIL: "Order payment failed",
	ORDER_VALIDATE_START: "Order hash validation started",
	ORDER_VALIDATE_SUCCESS: "Order hash validation successful",
	ORDER_VALIDATE_FAIL: "Order hash validation failed",
	CARD_TOKENIZATION_FAIL: "Card tokenization failed",

	// Script Events
	ADD_SCRIPT_TOKEN_TYPE: "Script token typed",
	ADD_SCRIPT_TOKEN_PASTE: "Script token pasted",
	ADD_SCRIPT_TOKEN_VALIDATION_SUCCESS: "Script token validation successful",
	ADD_SCRIPT_TOKEN_VALIDATION_FAIL: "Script token validation failed",
	ADD_SCRIPT_DELETE: "Script token deleted",

	// Address Events
	ADDRESS_FULFILLMENT_METHOD: "User selected a fulfillment method",
	ADDRESS_SELECTED_SEARCH: "Address search successful",
	ADDRESS_DELIVERY_NOTES: "User changed their delivery notes",

	// Navigation & UI Events
	GENERIC_NAVIGATE: "User navigated to page",
	SIDEMENU_TOGGLE: "User toggled sidemenu",
	MEDICARE_MODAL_SHOW: "Medicare confirmation modal shown",
	MEDICARE_MODAL_SKIP: "User skipped Medicare entry",

	// Doctor/Concierge Events
	DOCTOR_ORDER_START: "Doctor order creation started",
	DOCTOR_ORDER_SUCCESS: "Doctor order created successfully",
	DOCTOR_ORDER_FAIL: "Doctor order creation failed",
	DOCTOR_TOKEN_ADD_START: "Doctor token add started",
	DOCTOR_TOKEN_ADD_SUCCESS: "Doctor token added successfully",
	DOCTOR_TOKEN_ADD_FAIL: "Doctor token add failed",
	DOCTOR_TOKEN_DELETE: "Doctor token deleted",
	DOCTOR_TOKEN_PASTE: "Doctor token pasted",
	DOCTOR_FORM_CLEAR: "Doctor form cleared",
	DOCTOR_MODAL_SHOW: "Doctor modal shown",
	DOCTOR_MODAL_CLOSE: "Doctor modal closed",

	// QR Code Scanning Events
	ADD_SCRIPT_QR_TOKEN_SCAN_START: "Script QR code scan started",
	ADD_SCRIPT_QR_TOKEN_SCAN_SUCCESS: "Script QR code scan successful",
	ADD_SCRIPT_QR_TOKEN_SCAN_FAIL: "Script QR code scan failed",
};