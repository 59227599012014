import { CommonModule } from '@angular/common';
import { Component, computed, input, model, output, signal, inject } from '@angular/core';
import { TitleBlockComponent } from "../../../../components/title-block/title-block.component";
import { InputComponent } from "../../../../components/input/input.component";
import { provideIcons } from '@ng-icons/core';
import { heroDevicePhoneMobile } from '@ng-icons/heroicons/outline';
import { PasswordRulesComponent } from "../../../../components/password-rules/password-rules.component";
import { toObservable } from '@angular/core/rxjs-interop';
import { AmplitudeService } from '../../../../services/amplitude.service';

@Component({
  selector: 'step-password',
  standalone: true,
  imports: [CommonModule, TitleBlockComponent, InputComponent, PasswordRulesComponent],
  providers: [provideIcons({ heroDevicePhoneMobile })],
  templateUrl: './password.step.html',
  styleUrl: './password.step.scss'
})
export class PasswordStep {
  private $amplitude = inject(AmplitudeService);

  public mode = input<"check" | "set">("check");
  public validation = output<boolean>();

  public password = model<string>("");
  public confirmPassword = signal<string>("");
  public validPattern = signal<boolean>(false);

  public isValid = computed<boolean>(() => this.mode() == "set" ? this.validPattern() && this.password() == this.confirmPassword() : this.password().length >= 8);

  constructor() {
    toObservable(this.isValid).subscribe(v => this.validation.emit(v));
    this.$amplitude.track('GENERIC_NAVIGATE', { to_page: 'password' });
  }
}
