import { CommonModule, Location } from '@angular/common';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { User } from '@chemist2u/types-client/C2U/ParseObjects';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroBars3, heroCheck, heroXMark } from '@ng-icons/heroicons/outline';
import { heroArrowLeftMini, heroArrowRightMini } from '@ng-icons/heroicons/mini';

import { CloudService } from '../../services/cloud.service';
import { RxStateService } from './services/rx-state.service';
import { RxStep, RxStepService } from './services/rx-step.service';
import { RxOrderService } from './services/rx-order.service';
import { ButtonComponent } from '../../components/button/button.component';
import { StepperScaffoldComponent } from '../../components/stepper-scaffold/stepper-scaffold.component';
import { SpinnerComponent } from '../../components/spinner/spinner.component';
import { PartnerLogoComponent } from '../../components/partner-logo/partner-logo.component';
import { IntroStep } from './steps/intro/intro.step';
import { AddressSearchStep } from './steps/address-search/address-search.step';
import { AddressDetailsStep } from './steps/address-details/address-details.step';
import { MobileStep } from './steps/mobile/mobile.step';
import { MobileConfirmStep } from './steps/mobile-confirm/mobile-confirm.step';
import { ScriptStep } from './steps/script/script.step';
import { MedicalProfilePersonalStep } from './steps/medical-profile-personal/medical-profile-personal.step';
import { MedicalProfileClinicalStep } from './steps/medical-profile-clinical/medical-profile-clinical.step';
import { ReviewStep } from './steps/review/review.step';
import { PaymentStep } from './steps/payment/payment.step';
import { ThankYouStep } from './steps/thank-you/thank-you.step';
import { PasswordStep } from './steps/password/password.step';
import { MedicareConfirmComponent } from '../../components/medicare-confirm/medicare-confirm.component';
import { TOrderFulfillmentMethod } from '@chemist2u/types-client/C2U/Interfaces';
import { toObservable } from '@angular/core/rxjs-interop';
import { RxThemeService } from './services/rx-theme.service';
import { ToastService } from '../../services/toast.service';
import { environment } from '../../../environments/environment';
import { AmplitudeService } from '../../services/amplitude.service';
import { Cloud } from '@chemist2u/types-client/C2U/Cloud';
import { Parse } from '@chemist2u/types-client/C2U/local-parse';

@Component({
  selector: 'app-rx',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ButtonComponent,
    StepperScaffoldComponent,
    NgIconComponent,
    SpinnerComponent,
    PartnerLogoComponent,
    IntroStep,
    AddressSearchStep,
    AddressDetailsStep,
    MobileStep,
    MobileConfirmStep,
    ScriptStep,
    MedicalProfilePersonalStep,
    MedicalProfileClinicalStep,
    ReviewStep,
    PaymentStep,
    ThankYouStep,
    PasswordStep,
    MedicareConfirmComponent
],
  providers: [
    provideIcons({ heroBars3, heroXMark, heroCheck, heroArrowLeftMini, heroArrowRightMini }),
    RxStateService,
    RxStepService,
    RxOrderService,
    RxThemeService,
  ],
  templateUrl: './rx.component.html',
  styleUrl: './rx.component.scss'
})
export class RxComponent implements OnInit {
  private $amplitude = inject(AmplitudeService);
  private $location = inject(Location);
  private $route = inject(ActivatedRoute);
  private $cloud = inject(CloudService);
  private $toast = inject(ToastService);
  public rxState = inject(RxStateService);
  public rxStep = inject(RxStepService);
  public rxOrder = inject(RxOrderService);
  public rxTheme = inject(RxThemeService);
  
  public readonly APP_URL = environment.appUrl;

  // UI State
  public isLoading = signal<boolean>(false);
  public opaqueLoading = signal<boolean>(true);
  public partner = signal<Cloud.FetchPartnerBrandResponse | undefined>(undefined);
  public passwordMode = signal<"set" | "check">("check");
  public password = signal<string>("");

  // OTP Management
  public otpLoading = signal<boolean>(false);
  public otpLastSent = signal<Date>(new Date());

  // Modal State
  public medicareConfirm = signal<boolean>(false);
  public sidemenuActive = signal<boolean>(false);
  public squareCard = signal<any>(undefined);
  public fulfilmentMethod = signal<TOrderFulfillmentMethod | undefined>(undefined);

  constructor() {
    this.$route.params.subscribe(async params => this.initPartner(params['partnerShortId'], params['orderHash']));
    toObservable(this.rxOrder.orderHash).subscribe(hash => {
      const partnerShortId = this.partner()?.shortId;
      if (!partnerShortId) return;
      this.$location.replaceState(['', partnerShortId, hash].join('/'));
    });
  }

  private async initPartner(partnerShortId: string, orderHash: string | undefined) {
    this.opaqueLoading.set(true);
    try {
      const partnerDetails = await this.$cloud.fetchPartnerBrand({ partnerShortId });
      if (!partnerDetails.found) window.location.href = this.APP_URL;
      this.partner.set(partnerDetails);
      this.rxTheme.setPartner(partnerDetails);
      this.rxOrder.orderHash.set(orderHash);
      this.opaqueLoading.set(false);
    } catch (error) {
      console.log((error as Parse.Error).code);
      if ((error as Parse.Error).code == Parse.Error.INVALID_SESSION_TOKEN) {
        await this.initPartner(partnerShortId, orderHash);
      } else {
        window.location.href = this.APP_URL;
      }
    }
  }

  async ngOnInit() {
    const orderHash = this.$route.snapshot.params['orderHash'];
    if (orderHash) {
      this.$amplitude.track('ORDER_VALIDATE_START', { orderHash });
    }
    this.rxOrder.orderHash.set(orderHash);
    const user = await User.currentAsync();
    const mobileNumber = user?.getUsername();
    if (mobileNumber) {
      this.rxStep.isLoggedIn.set(true);
      await this.validateOrderHash(mobileNumber, false);
      if (this.rxOrder.orderStatus() != "incomplete" && this.rxOrder.orderHash()) {
        this.nextStep();
      }
    }
    this.checkTokenInUrl();
  }

  private async checkTokenInUrl() {
    const queryParams = this.$route.snapshot.queryParams;
    const token = queryParams['mkt'];
    if (!token) return;
    try {
      const newTokenData = await this.$cloud.getErxData(token);
      this.rxState.onTokenDataChange([{ ...newTokenData, newMedication: false, subGenerics: false }]);
      this.rxStep
    } catch (error) {
      const partnerShortId = this.partner()?.shortId;
      if (!partnerShortId) return;
      this.$location.replaceState(['', partnerShortId].join('/'));
    }
  }

  // App Store
  public detectDevice(): 'android' | 'ios' | 'other' {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return 'android';
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'ios';
    } else {
      return "other";
    }
  }

  public storeLink(): string | undefined {
    const device = this.detectDevice();
    if (device == 'android') {
      return "https://play.google.com/store/apps/details?id=com.chemist2u.app";
    } else if (device == "ios") {
      return "https://apps.apple.com/au/app/chemist2u/id1501448500?mt=8";
    } else {
      return undefined;
    }
  }

  // Navigation Methods
  public prevStep() {
    const prevStep = this.rxStep.getPrevStep();
    if (prevStep) this.rxStep.setStep(prevStep);
  }

  public async nextStep(skipResolve = false) {
    const nextStep = this.rxStep.getNextStep();
    if (!nextStep) return;

    if (!skipResolve) {
      const resolved = await this.resolveStep(this.rxStep.step());
      if (!resolved) return;
    }
    this.rxStep.setStep(nextStep);
  }

  private async resolveStep(currentStep: RxStep): Promise<boolean> {
    switch (currentStep) {
      case "mobile":
        return await this.sendOtp();
      case "mobile_confirm":
        const verified = await this.verifyOtp();
        if (verified) await this.preparePasswordStep();
        return verified;
      case "password":
        return await this.handlePasswordStep();
      case "medical_profile_personal":
        return await this.checkMedicare();
      case "medical_profile_clinical":
        return await this.createOrder();
      case "review":
        return await this.updateOrder();
      case "payment":
        return await this.payForOrder();
      default:
        return true;
    }
  }

  // Authentication Methods
  private async preparePasswordStep() {
    const mobileNumber = this.rxState.state().mobileNumber!;
    const userExists = await this.$cloud.doesUsernameExist(mobileNumber);
    this.passwordMode.set(userExists ? "check" : "set");
  }

  private async handlePasswordStep(): Promise<boolean> {
    try {
      this.isLoading.set(true);
      const mobileNumber = this.rxState.state().mobileNumber!;
      
      if (this.passwordMode() === "set") {
        await this.$cloud.signUp(mobileNumber, this.password());
      } else {
        await this.$cloud.logIn(mobileNumber, this.password());
      }

      this.rxStep.isLoggedIn.set(true);
      const valid = await this.validateOrderHash(mobileNumber, true);
      if (valid) {
        setTimeout(() => this.nextStep(true), 100);
        return false;
      }
      return true;
    } catch (error) {
      this.$toast.show("error", "Error invalid mobile/password!");
      return false;
    } finally {
      this.isLoading.set(false);
    }
  }

  // OTP Methods
  public onOtpChange(value: string) {
    if (this.rxState.otp().length == 5 && value.length == 6) {
      this.rxState.otp.set(value);
      this.nextStep();
    } else {
      this.rxState.otp.set(value);
    }
  }

  public async sendOtp(): Promise<boolean> {
    if (this.rxState.state().mobileNumberVerified) return true;
    const mobileNumber = this.rxState.state().mobileNumber!;
    this.$amplitude.track('AUTH_OTP_SEND_START', { mobileNumber });
    try {
      this.rxState.otp.set("");
      this.otpLastSent.set(new Date());
      this.isLoading.set(true);
      await this.$cloud.generateAuthyCode(mobileNumber);
      this.$amplitude.track('AUTH_OTP_SEND_SUCCESS', { mobileNumber });
      return true;
    } catch (error) {
      this.$amplitude.track('AUTH_OTP_SEND_FAIL', { mobileNumber, error: (error as Error).message });
      this.$toast.show("error", "Error sending OTP!");
      return false;
    } finally {
      this.isLoading.set(false);
    }
  }

  public async verifyOtp(): Promise<boolean> {
    if (this.rxState.state().mobileNumberVerified) return true;
    const mobileNumber = this.rxState.state().mobileNumber!;
    this.$amplitude.track('AUTH_OTP_VERIFY_START', { mobileNumber });
    try {
      this.otpLoading.set(true);
      const verifyResult = await this.$cloud.verifyAuthyCode(this.rxState.otp(), mobileNumber);
      if (verifyResult === "approved") {
        this.$amplitude.track('AUTH_OTP_VERIFY_SUCCESS', { mobileNumber });
        this.rxState.updateState({ mobileNumberVerified: true });
        return true;
      } else {
        this.$toast.show("error", "Error incorrect OTP!");
        this.$amplitude.track('AUTH_OTP_VERIFY_FAIL', { mobileNumber, error: "Incorrect code" });
        return false;
      }
    } catch (error) {
      this.$amplitude.track('AUTH_OTP_VERIFY_FAIL', { mobileNumber, error: (error as Error).message });
      this.$toast.show("error", "Error verifying OTP!");
      return false;
    } finally {
      this.otpLoading.set(false);
    }
  }

  // Atl
  public atlDetails = computed(() => ({
    atl: this.rxOrder.order()?.shipping?.atl || false,
    instructions: this.rxOrder.order()?.shipping?.deliveryNote || undefined,
  }));

  public async onAtlDetailsChange(event: { atl: boolean, instructions: string | undefined }) {
    try {
      this.isLoading.set(true);
      await this.rxOrder.setOrderAtl(event.atl, event.instructions);
      this.$toast.show("success", "Authority to leave updated!");
    } catch (error) {
      this.$toast.show("error", "Error setting authority to leave!");
      console.error(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  // Order Methods
  private async validateOrderHash(mobileNumber: string, keepUser: boolean): Promise<boolean> {
    const orderHash = this.rxOrder.orderHash();
    if (!orderHash) return false;

    try {
      const result = await this.$cloud.validateOrderHash({ mobileNumber, orderHash });
      if (result.valid) {
        this.$amplitude.track('ORDER_VALIDATE_SUCCESS', { orderId: result.order?.id || "unknown" });
        this.rxOrder.order.set(result.order);
        return true;
      }
    } catch (error) {
      this.$amplitude.track('ORDER_VALIDATE_FAIL', { error: (error as Error).message });
    }

    if (keepUser) {
      this.rxOrder.orderHash.set(undefined);
    } else {
      await this.$cloud.logOut();
      this.rxStep.isLoggedIn.set(false);
    }
    return false;
  }

  private async createOrder() {
    try {
      this.opaqueLoading.set(true);
      await this.rxOrder.createOrder(this.rxState.state(), this.partner()?.shortId || "c2u");
      this.opaqueLoading.set(false);
      setTimeout(() => this.nextStep(true));
    } catch (error) {
      this.$toast.show("error", "Error completing order!");
      console.error(error);
    } finally {
      this.opaqueLoading.set(false);
      return false;
    }
  }
  
  private async updateOrder() {
    try {
      this.isLoading.set(true);
      await this.rxOrder.updateFulfillmentMethod(this.fulfilmentMethod()!);
      this.isLoading.set(false);
      return true;
    } catch (error) {
      this.$toast.show("error", "Error confirming order shipping details!");
      this.isLoading.set(false);
      return false;
    }
  }

  private async payForOrder() {
    try {
      this.isLoading.set(true);
      await this.rxOrder.payForOrder(this.squareCard());
      return true;
    } catch (error) {
      this.$toast.show("error", "Error paying for order!");
      console.error(error);
      return false;
    } finally {
      this.isLoading.set(false);
    }
  }

  // Medicare Confirm
  private async checkMedicare(): Promise<boolean> {
    const medicalProfile = this.rxState.state().medicalProfilePersonal!;
    const medicareProvided = medicalProfile.medicare?.medicareCardNumber && medicalProfile.medicare?.irn;
    this.$amplitude.track('MEDICARE_MODAL_SHOW');
    if (medicareProvided) return true;
    this.medicareConfirm.set(true);
    return false;
  }

  public onMedicareConfirmSkip() {
    this.$amplitude.track('MEDICARE_MODAL_SKIP');
    this.nextStep(true);
    this.medicareConfirm.set(false);
  }

  // UI Methods
  public openSidemenu() {
    this.sidemenuActive.set(true);
  }

  public closeSidemenu() {
    this.sidemenuActive.set(false);
  }
}
