import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, output, signal } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroExclamationTriangle, heroInformationCircle, heroQuestionMarkCircle, heroXMark } from '@ng-icons/heroicons/outline';

@Component({
  selector: 'app-info-box',
  standalone: true,
  imports: [CommonModule, NgIconComponent],
  providers: [provideIcons({ heroExclamationTriangle, heroInformationCircle, heroQuestionMarkCircle, heroXMark })],
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
})
export class InfoBoxComponent {

  private sanitizer = inject(DomSanitizer);

  public type = input<'info' | 'help' | 'warning'>('info');
  public title = input<string | undefined>();
  public label = input.required<string>();
  public closeable = input<boolean>(false);
  public color = input<'blue' | 'yellow' | 'green' | 'promo'>('blue');
  public hasIcon = input<boolean>(true);
  public sanitized = input<boolean>(false);
  public visible = signal<boolean>(true);


  public actionEvent = output<void>();

  public iconName = computed(() => {
    if (this.type() === 'warning') {
      return 'heroExclamationTriangle';
    }
    return this.type() === 'info' ? 'heroInformationCircle' : 'heroQuestionMarkCircle';
  });

  public sanitizedHTML = computed(() => {
    if (this.sanitized()) {
      return this.sanitizer.bypassSecurityTrustHtml(this.label());
    }
    return this.label();
  });

  public action() {
    this.actionEvent.emit();
  }

  public close() {
    this.visible.set(false);
  }


}