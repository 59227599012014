"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Pharmacy = void 0;
const User_1 = require("./User");
const _BaseParseObject_1 = require("./_BaseParseObject");
const config_1 = require("../config");
const local_parse_1 = require("../local-parse");
class Pharmacy extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("Pharmacy", attributes);
  }
  get getContactEmail() {
    var _a;
    return (_a = this.contact) === null || _a === void 0 ? void 0 : _a.email;
  }
  static async getByUser(user) {
    return await new local_parse_1.Parse.Query(Pharmacy).equalTo("user", user).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async getByUserID(userId, useMasterKey, includes = []) {
    const query = new local_parse_1.Parse.Query(Pharmacy).equalTo("user", User_1.User.pointer(userId));
    if (includes.length > 0) {
      for (let include of includes) {
        query.include(include);
      }
    }
    return await query.first({
      useMasterKey: config_1.C2UConfig.useMasterKey && useMasterKey
    });
  }
  static pointer(id) {
    return {
      __type: "Pointer",
      className: "Pharmacy",
      objectId: id
    };
  }
}
exports.Pharmacy = Pharmacy;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "businessName", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "companyName", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "ABN", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "ACN", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "website", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "address", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "heartBeat", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "primaryPhone", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "contact", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "user", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "status", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Array)], Pharmacy.prototype, "deliveryWindows", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "pharmacyItems", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "selfManaged", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "processingFee", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "googlePlaceID", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "googlePlaceDetails", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "sfOTC", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "sfScripts", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "sfScriptsFreight", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "categoriesActive", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "subcategoriesActive", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "canonical", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "location", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "openingHours", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "serviceability", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "timeZoneId", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "meta", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "googleAddress", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "doordashItems", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "pharmacyLicence", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "deliveryPartner", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "fulfillmentMethods", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Pharmacy.prototype, "tags", void 0);
local_parse_1.Parse.Object.registerSubclass("Pharmacy", Pharmacy);
