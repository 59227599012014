<div #el>
    <app-input [debounceTime]="300" [value]="writeValue()" (valueChange)="onComplete($event)" type="text" icon="heroMagnifyingGlass" placeholder="Enter delivery address"></app-input>
    @if (errorMessage()) {
        <div class="mt-2 text-sm text-red">{{ errorMessage() }}</div>
    }
    @if (showPredictions()) {
        <div class="bg-white shadow-lg rounded-md overflow-hidden mt-3">
            @for (prediction of predictions(); track $index) {
                <app-address-row [first]="$first" [structuredFormatting]="prediction.structured_formatting" (click)="selectAddress($event, prediction)" />
            }
        </div>
    }
    <div class="spinner" [ngClass]="{ hide: !isLoading() }">
      <app-spinner size="small"></app-spinner>
    </div>
</div>
