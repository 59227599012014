{
  "name": "@googlemaps/google-maps-services-js",
  "version": "3.4.0",
  "description": "Node.js client library for Google Maps API Web Services",
  "keywords": [
    "google",
    "maps",
    "googlemaps",
    "geo",
    "geocode",
    "timezone",
    "api",
    "client",
    "roads",
    "directions",
    "navigation"
  ],
  "homepage": "https://github.com/googlemaps/google-maps-services-js",
  "bugs": {
    "url": "https://github.com/googlemaps/google-maps-services-js/issues"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/googlemaps/google-maps-services-js.git"
  },
  "license": "Apache-2.0",
  "author": "Google Inc.",
  "contributors": [
    {
      "name": "Justin Poehnelt",
      "email": "jpoehnelt@google.com"
    }
  ],
  "main": "./dist/index.js",
  "files": [
    "dist",
    "src"
  ],
  "scripts": {
    "build": "tsc",
    "docs": "rm -rf docs/ && typedoc src/index.ts",
    "prepack": "npm run build",
    "pretest": "npm run build",
    "test": "jest --runInBand --collectCoverage ./src/* && npm run test:loading",
    "test:loading": "./test-module-loading.sh",
    "test:e2e": "jest --runInBand ./e2e/*",
    "test:all": "jest --runInBand",
    "format": "eslint . --fix",
    "lint": "eslint ."
  },
  "dependencies": {
    "@googlemaps/url-signature": "^1.0.4",
    "agentkeepalive": "^4.1.0",
    "axios": "^1.5.1",
    "query-string": "<8.x",
    "retry-axios": "<3.x"
  },
  "devDependencies": {
    "@types/jest": "^29.5.5",
    "@types/node": "^20.1.1",
    "@typescript-eslint/eslint-plugin": "^6.7.4",
    "@typescript-eslint/parser": "^6.7.4",
    "eslint": "^8.51.0",
    "eslint-config-prettier": "^9.0.0",
    "eslint-plugin-jest": "^27.4.2",
    "eslint-plugin-prettier": "^5.0.0",
    "jest": "^29.7.0",
    "nock": "^13.3.4",
    "prettier": "^3.0.3",
    "ts-jest": "^29.1.1",
    "typedoc": "^0.25.0",
    "typescript": "^5.2.2"
  },
  "publishConfig": {
    "registry": "https://wombat-dressing-room.appspot.com",
    "access": "public"
  },
  "prettier": {
    "trailingComma": "es5"
  }
}
