"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CustomerMedicalProfile_New = void 0;
const _BaseParseObject_1 = require("./_BaseParseObject");
const local_parse_1 = require("../local-parse");
class CustomerMedicalProfile_New extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("CustomerMedicalProfile_New", attributes);
  }
  calculateAge(date) {
    const today = new Date();
    const diffMs = today.getTime() - date.getTime();
    const ageYears = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365.25));
    return ageYears;
  }
  get age() {
    return this.calculateAge(this.dateOfBirth);
  }
  get is_child() {
    return this.age < 13;
  }
}
exports.CustomerMedicalProfile_New = CustomerMedicalProfile_New;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerMedicalProfile_New.prototype, "customer", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerMedicalProfile_New.prototype, "medicareCardNumber", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerMedicalProfile_New.prototype, "irn", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Date)], CustomerMedicalProfile_New.prototype, "dateOfBirth", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerMedicalProfile_New.prototype, "medicareExpiry", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerMedicalProfile_New.prototype, "medicareCardColor", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], CustomerMedicalProfile_New.prototype, "name", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerMedicalProfile_New.prototype, "weight", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerMedicalProfile_New.prototype, "safetyNetNumber", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerMedicalProfile_New.prototype, "concessionCardNumber", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Boolean)], CustomerMedicalProfile_New.prototype, "isUsersProfile", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerMedicalProfile_New.prototype, "gender", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Boolean)], CustomerMedicalProfile_New.prototype, "hasOtherMedications", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerMedicalProfile_New.prototype, "otherMedications", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Boolean)], CustomerMedicalProfile_New.prototype, "hasAllergies", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerMedicalProfile_New.prototype, "allergies", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Boolean)], CustomerMedicalProfile_New.prototype, "hasMedicalConditions", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerMedicalProfile_New.prototype, "medicalConditions", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], CustomerMedicalProfile_New.prototype, "medicinePreference", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Number)], CustomerMedicalProfile_New.prototype, "version", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Boolean)], CustomerMedicalProfile_New.prototype, "locked", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerMedicalProfile_New.prototype, "previous", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerMedicalProfile_New.prototype, "next", void 0);
local_parse_1.Parse.Object.registerSubclass("CustomerMedicalProfile_New", CustomerMedicalProfile_New);
