"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.startJob = startJob;
const local_parse_1 = require("../local-parse");
function startJob(name, params) {
  return local_parse_1.Parse.Cloud.startJob(name, params);
}
