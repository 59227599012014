<div class="wrapper" [ngClass]="buttonClasses()">
  <button [ngClass]="buttonStyleClass()" [ngStyle]="buttonStyleOverrides()" [disabled]="disabled()" (click)="handleClick($event)">
    @if (icon() && iconPosition() == "left") {
      <span class="icon"><ng-icon [name]="icon()" [ngClass]="iconStyleClass()"></ng-icon></span>
    }
    {{ label() }}
    @if (icon() && iconPosition() == "right") {
      <span class="icon"><ng-icon [name]="icon()" [ngClass]="iconStyleClass()"></ng-icon></span>
    }
    @if (textRight()) {
      <span class="right">{{ textRightDisplay() }}</span>
    }
  </button>  
</div>