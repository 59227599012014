<div class="flex flex-col justify-start items-center px-6">
    <div class="max-w-screen-sm w-full min-h-screen flex flex-col items-center gap-4">
        <div class="flex flex-col items-center justify-center gap-6 pt-8">
            <img class="w-80 object-contain" src="./assets/logo.svg" alt="Chemist2U Logo" role="img"> 
            <div class="leading-5 text-lg text-primary font-semibold text-center">Fast and Convenient Prescription Delivery</div>
        </div>
        <div class="w-full flex flex-col items-center justify-center text-center gap-3">
            <div>
                <div class="text-lg font-semibold">Upload prescriptions for delivery via Chemist2U</div>
                <div class="text-light-brand font-bold uppercase mt-1">Valid for e-Scripts only.</div>
            </div>
            <div class="w-full flex gap-4 items-stretch justify-center">
                <div class="w-52 p-4 rounded-lg shadow-md border text-center">
                    <div class="text-lg text-primary font-semibold">Step 1</div>
                    <div class="mt-2 text-secondary">Enter the patients mobile number.</div>
                </div>
                <div class="w-52 p-4 rounded-lg shadow-md border text-center">
                    <div class="text-lg text-primary font-semibold">Step 2</div>
                    <div class="mt-2 text-secondary">Enter the script token(s).</div>
                </div>
            </div>
            <div class="font-semibold">
                <div class="text-primary text-lg">Add one token at a time.<br>Repeat this process for each patient.</div>
                <p class="text-secondary text-xs mt-1">The patient will receive a secure link via sms to complete their order.</p>
            </div>
        </div>
        <div class="w-full">
            <div>
                <app-input
                    type="tel"
                    label="Patient Mobile #"
                    icon="heroDevicePhoneMobile"
                    placeholder="e.g. 0400 000 000"
                    [showClearButtonOption]="true"
                    [externalError]="mobileError()"
                    [disabled]="isLoading()"
                    [(value)]="mobile" />
            </div>
            <div class="flex items-start gap-2 mt-2">
                <div class="w-full">
                    <app-input label="Enter the eRX tokens below" [disabled]="isLoading()" [(value)]="token" [showClearButtonOption]="true"></app-input>
                </div>
                <div class="flex flex-col gap-2">
                    <div class="leading-6 h-6 flex items-center justify-end gap-2 cursor-pointer text-brand" (click)="pasteToken()">
                        <div class="text-sm font-medium">Paste</div>
                        <ng-icon class="text-xl" name="heroClipboard"></ng-icon>
                    </div>
                    <app-button (onClick)="addToken()" buttonStyleClass="min-h-12 whitespace-nowrap" label="Add Token" [disabled]="token().length < 14 || isLoading()"></app-button>
                </div>
            </div>
        </div>
        <div class="w-full">
            @for (token of tokenData(); track $index) {
                <div class="rounded-md shadow-md border p-4">
                    <div class="text-lg text-primary font-semibold">{{ token.medicationName }}</div>
                    <div class="text-sm text-secondary">
                        <div>Prescription for: <span>{{ token.electronPrescriptionFor }}</span></div>
                        <div>Supplies remaining: <span>{{ token.supplyRemaining }}</span></div>
                        <div>Prescription Date: <span>{{ token.prescriptionDate }}</span></div>
                    </div>
                    <div class="absolute top-4 right-4 text-brand cursor-pointer" (click)="removeToken($index)">
                        <ng-icon class="text-xl" name="heroTrash"></ng-icon>
                    </div>
                    <div class="absolute bottom-4 right-4 cursor-pointer">
                        <a [href]="token.url" target="_blank">
                            <app-button buttonStyleClass="min-h-12 whitespace-nowrap" buttonStyleClass="max-h-10" label="Open"></app-button>
                        </a>
                    </div>
                </div>
            }
        </div>

        <!-- This is to make sure absolutes dont get scrolled on --> <div class="h-10"></div>
        <div class="sticky bottom-10 left-0 right-0 w-full p-3 shadow-md rounded-md bg-white border flex items-center justify-between">
            <div class="text-secondary text-sm">Complete all fields before proceeding</div>
            <div class="flex items-center justify-end gap-3">
                <app-button (onClick)="clearAll()" [outlined]="true" size="default" label="Clear"></app-button>
                <app-button (onClick)="submitForm()" size="default" label="Submit" [disabled]="invalidForm() || disabledForm()"></app-button>
            </div>
        </div>
        <div class="absolute bottom-0 left-0 right-0 h-10 leading-10 text-xs text-light-brand font-semibold">
            <div class="flex items-center gap-8">
                <div class="cursor-pointer" (click)="showModal('delivery')">Delivery Info</div>
                <div class="cursor-pointer" (click)="showModal('privacy')">Privacy Policy</div>
                <div class="cursor-pointer" (click)="showModal('terms')">Terms of service</div>
            </div>
        </div>
    </div>
</div>

@if (isLoading()) {
    <div class="fixed inset-x-0 inset-y-0 bg-white/20 flex items-center justify-center">
        <app-spinner></app-spinner>
    </div>
}

@if (showingModal()) {
    <app-simple-modal [heading]="modalHeading()" (close)="closeModal()">{{ modalContent() }}</app-simple-modal>
}