import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'app-title-block',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './title-block.component.html',
  styleUrl: './title-block.component.scss'
})
export class TitleBlockComponent {
  public heading = input.required<string>();
  public description = input<string>();
}
