export const deliveryModal = {
    heading: "Delivery Info",
    content: `
<p>Estimated delivery times shown in the app are subject to the pharmacy’s confirmation of your order and
are provided as a guideline only.</p>
<p>If an order is confirmed,&nbsp;Chemist&nbsp;2U&nbsp;will use reasonable endeavours to deliver your order
to the relevant address by the time and date indicated in the app, though we cannot guarantee this.</p>
<p>Factors that may change the delivery time include when an order is placed (e.g. if it is placed outside
of designated delivery or order hours), or any adverse weather or traffic conditions. The pharmacy will
contact you as soon as possible in the event of an unavoidable delay to make alternative arrangements.
</p>
<p>You should not rely on Chemist 2U’s predicted delivery times or service availability to guarantee access
to your health and medical needs.</p>
<p>Any medication that is critical to your health or required to be taken on a strict schedule should be
ordered well ahead of time, and you should not rely on&nbsp;Chemist&nbsp;2U for urgent deliveries.</p>
<p>Chemist 2U is a delivery service provider and does not directly dispense therapeutic goods.</p>
`
};

export const privacyModal = {
    heading: "Privacy Info",
    content: `
<h2>Effective as of 1 August2019</h2>
<p>This policy (‘Privacy Policy’) explains how Chemist 2U Australia
    Pty Ltd (ABN 632 961 552) (‘C2U’ or ‘us’) seeks to protect the Personal Information of individuals. C2U is committed
    to protecting the safety and security of the Personal Information of individuals whose information C2U has access
    to, including members and users of the C2U Platform (defined below) and other persons with whom C2U interacts (each
    a ‘User’ or ‘you’).</p>
<p>The Privacy Policy has been developed in accordance with the
    Privacy Act 1988 (Cth)(‘Act’). Under the Act, “Personal Information”is defined as: “Information or an opinion about
    an identified individual, or an individual who is reasonably identifiable:</p>
<p>(a) whether the information or opinion is true or not; and&nbsp;(b) whether the information or opinion is recorded in
    a material form or not.”</p>
<p>Please read this Privacy Policy carefully in order to understand
    how your Personal Information is collected, held, used, or otherwise processed by us.</p>
<p>C2U reserves the right to make changes or updates to this Privacy
    Policy from time to time. If this happens we will update this Privacy Policy and notify you of any changes, most
    likely via email or in-app notification. However, you should also periodically check this Privacy Policy for any
    updates.</p>
<h2>1. ABOUT CHEMIST 2U</h2>
<p>C2U is a distribution provider that operates a website, mobile
    applications, an online platform and related services that connect members with pharmacies to ensure the convenient
    and dependable delivery of a range of goods to members, including without limitation therapeutic goods pursuant to
    medical prescriptions (collectively ‘C2U Platform’).</p>
<p>In providing the C2U Platform, we are sensitive to Users’ concerns
    about the safety of their Personal Information.</p>
<p>In essence, C2U will typically only:</p>
<ul>
    <li>collect, use or share your Personal Information with your consent (unless it is not
        reasonable in the circumstances to obtain your consent andit is legally permissible for us to do so) or when
        required by a legal obligation; and</li>
</ul>
<p>interact with your Personal Information in order to (a) provide you
    with the C2U Platformand information regarding associated goods and services; and (b) help us improve and develop
    the C2U Platform and our service offering generally.</p>
<p>C2U has developed our privacy framework to assist Users, and to
    comply with privacy legislation and regulations applicable to us and our management of your Personal Information.
</p>
<h2>2. HOW CHEMIST 2U COLLECTS YOUR
    PERSONAL INFORMATION</h2>
<p>C2U collects Personal Information from individuals in one of three
    main ways:</p>
<p>(a) Directly from Users, when they interact with C2U or the C2U
    platform (e.g. provide feedback or register a User account);
</p>
<p>(b) Passively from Users, when they interact with and use the C2U
    Platform;</p>
<p>(c) From third-partiesin certain, specific circumstances (e.g. if
    you sign up to the C2U Platform through a third-party service or platform they may provide us with information you
    have consented to them sharing).</p>
<p>The types of Personal Information collected in each situation are
    discussed further below.</p>
<h2>3. WHEN CHEMIST 2U COLLECTS
    INFORMATION FROM USERS AND WHAT WE COLLECT</h2>
<p>(a) Personal Information collected directly</p>
<p>When Users sign up to components of the C2U Platform we collect the
    following types of Personal Information directly and consensually:</p>
<p>Basic User information, including your name, email and date of
    birth, and your gender;</p>
<p>Basic account information, including any display picture or other
    information you choose to associate with your account;</p>
<p>If you access the C2U Platform throughathird-party service or
    platform(e.g. Facebook), we will collect information that is made available to C2U bythose services or platforms.
    You can generally control the information we receive from these sources by using the privacy settings on the
    third-party services or platforms.</p>
<p>We will collect the following types of information from Users as
    they use the C2U Platform:</p>
<p>Basic account preferences, such as your settings (e.g. whether you
    use Touch ID or our repeats feature);</p>
<p>Content thatyou post and submit to the C2U Platform and oursocial
    media pages, which includesany content from third-party platforms(e.g. a profile picture you may use).</p>
<p>If a User decides to make a purchase over the C2U Platform we will
    collect the following types of Personal Information directly and consensually from you:</p>
<ul>
    <li>Purchase delivery information, such as your preferred delivery date and time, your full
        address and contact number;</li>
    <li>Information about transactions, such as records of your purchases, deliveries and any
        preferences relevant to the online store component of the C2U Platform; and</li>
    <li>Payment related informationthat is required to support your ability to make purchases over
        the C2U Platform (e.g. PayPal account information).</li>
</ul>
<p>If a User decides to purchase a product that is subject to
    governmental health benefit schemes (e.g. Medicare), we may collect relevant health benefit identifier
    informationdirectly and consensually from you (e.g. your Medicare card number and expiry date).</p>
<p>If a User decides to purchase a product that requires a valid
    doctor’s prescription(‘Pharmacy Product’), will additionally collect the following types of Personal Information
    related to the purchase directly and consensually from you:
</p>
<p>Prescription Information, such details contained in the relevant
    prescription, a scan of the relevant prescription, and the hardcopy original of the prescription; and</p>
<p>Identity Confirmation Information, such as a photograph or scan of
    valid photo ID (e.g. your Driver Licence), or other associated information contained on it;</p>
<p>(NB– In the case that you are lawfully making a purchase for a
    minor these types of Personal Information will relate to the minor, and not yourself.)</p>
<p>When a User make an enquiry or sends us unsolicited feedbackwe may
    collect the following types of Personal Information directly and consensually:</p>
<ul>
    <li>Basic contact information, including your name and email;and</li>
    <li>Feedback information and the details of your interactions with us, including
        communications with customer support or other C2U personnel (e.g. the contents of an in-app “contact us”
        message, or an email sent to support@chemist2u.com.au) or other information provided by you regarding your
        enquiry. </li>
</ul>
<p>When you respond to a surveywe may directly and consensually
    collect the Personal Information disclaimed on the survey form.
</p>
<p>When you make an application for employment at C2U, we may collect
    any Personal Information provided within that application, such as the contents of a personal statement made in
    support of your application.</p>
<p>(b) Personal Information collected passively</p>
<p>As you interact with the C2U Platform or advertisements, we may
    collect the following types of Personal Information about your usage:</p>
<ul>
    <li>Content that you post in-app, including search terms;</li>
    <li>Background account information, such as your notification settings; and</li>
    <li>The following types of browser, system and device information regarding devices you link
        to the C2U Platform or use to access our digital content:</li>
    <li>Locational information,regarding which country you’re accessing the platform from, (e.g.
        the form of the IP address from which you access the C2U Platform);</li>
    <li>Web data tracking information, such asdata from cookies stored on your device, including
        cookie IDs and settings, as well as logs of your access of the C2U Platform;</li>
    <li>Device informationprovided by devices you use to access your C2U Platform accounts (e.g.
        device information from a smartphone); and</li>
    <li>System usage information, such as logs of your searches and navigation across the C2U
        Platform.</li>
</ul>
<p>(c) Personal Information collected from third-parties</p>
<p>In certain specific situations, C2U may collect Personal
    Information about you from third-parties. The types of Personal Information that may be collected include:</p>
<ul>
    <li>Third-party account information made available to us if you register with C2U through a
        third-party service or platform; and</li>
    <li>Web data tracking informationprovided to us that fits certain parameters of who we think
        could become C2U Users (e.g. information about the interests of individuals in the demographic of C2U Users ).
        Ordinarily the web data tracking informationwe may collect or receive from third parties will be pseudonymised
        (as outlined below).</li>
</ul>
<h2>4. WHY CHEMIST 2U COLLECTS YOUR
    PERSONAL INFORMATION AND WHAT WE USE IT FOR</h2>
<p>Although C2U collects Personal Information from Users in a number
    of circumstances, C2U will only collect this information in order to provide and develop the C2U Platform. Here are
    the main ways we use Personal Information to achieve these objectives:</p>
<p>Communicating with Users</p>
<p>C2U will use basic User, account and contact information to
    communicate with individuals about their feedback or issues with the C2U Platform.</p>
<p>If Users have consented, C2U will also use these types of Personal
    Information to share relevant news and updates about C2U and the C2U Platform.</p>
<p>Administration and delivery of C2U Platform and products</p>
<p>C2U will use basicUser and account information, as well as other
    basic preferences to provide you with the baseline experience over the C2U Platform (e.g. allowing you to access and
    browse the C2U platform).</p>
<p>If you have registered using a third-party service or platform
    informationC2U will also use this for the same reasons.</p>
<p>C2U will use your basic User informationfor simple administrative
    tasks, such as resetting account passwords.</p>
<p>C2U will use your paymentrelatedinformationto facilitate your
    access to the online store and purchase of products.</p>
<p>C2U will use your health benefit identifier informationto organise
    the purchase of relevant products from pharmacies. In the case of Pharmacy Products, C2U will also use your
    prescription information and identity confirmation informationfor this purpose.</p>
<p>C2U will use your basic Userand purchase deliveryinformationto
    facilitate delivery of your products. In the case of Pharmacy Products, C2U will also use your prescription
    information and identity confirmation informationfor this purpose.</p>
<p>C2U will use your account preferencesand information about
    transactionsto provide you with a tailored experience when using the C2U Platform.</p>
<p>Ensuring User safety</p>
<p>C2U will also use any type of information collected to prevent and
    address risks to all Users (e.g. C2U will use information to investigate suspicious or threatening activity).</p>
<p>Research and development</p>
<p>C2U will use the following types of information to develop, test
    and improve the C2U Platform:</p>
<ul>
    <li>Survey andfeedback information, as well as any content that is submitted in relation to
        products or features of the C2U Platform;</li>
    <li>Basic account and online store preferences;</li>
    <li>Content you submit, either directly through the C2U Platform or through third-party
        platforms or services.</li>
    <li>Background account, browser, system and device information; and</li>
    <li>Third-party web tracking information.</li>
</ul>
<p>Together these types of Personal Information are used to provide us
    with an overview of how the C2U Platform is being used, any shortcomings it may have, and subsequently to highlight
    what will be the best means of improving the experience for all Users.</p>
<p>C2U’s preference will be to de-identify these types information
    first, and then use it for this purpose in conjunction with de-identified browser and device information(see section
    6 below for an explanation of what we mean by “de-identified”).
</p>
<p>Marketing</p>
<p>Where Users have consented, C2U or our third-party service
    providers will use basic contact, enquiry, account, third-party account and web data tracking informationto provide
    Users with relevant marketing materials and offers about C2U and the C2U platform. Users can always opt out of this
    through the functionality provided in each marketing communication (e.g. by clicking “unsubscribe” at the bottom of
    an email).</p>
<p>In some instances, where Users have expressly consented, we will
    also use these types of information to provide Users with personalised health intervention content and messages.</p>
<h2>5. CHEMIST 2U’S DISCLOSURE OF
    PERSONAL INFORMATION</h2>
<p>Generally, C2U does not disclose Personal Information to any
    third-parties except:</p>
<ul>
    <li>Pharmaciesandservice providers(e.g. delivery personnel and Payment Card Industry Data
        Security Standard (‘PCI DSS’) compliant third-party payment operators)C2U engagestohelp us provide and develop
        the C2U Platformand process the Personal Information we collect; and</li>
    <li>Law enforcement agencies, or another party that has a legitimate legal rightto access the
        information.</li>
</ul>
<p>The above disclosures will only be made in circumstances where the
    recipient has provided an undertaking that they will maintain the confidentiality of the information and that they
    recognise the appropriate limitations placed on the use of the information. Disclosures will also always be in
    accordance with this Privacy Policy.</p>
<p>Overseas Disclosure</p>
<p>Some of the third-parties C2U discloses Personal Information to are
    located overseas. This is particularly the case for our third-party software and cloud service providers which are
    currently located in the United States.</p>
<p>As with disclosures to third-party service providers, overseas
    disclosures are always made once C2U has taken all reasonable steps to determine the information will be treated at
    least as favourably under the Act and other applicable privacy laws.</p>
<h2>6. CHEMIST 2U’S TREATMENT AND STORAGE
    OF INFORMATION</h2>
<p>C2U’s general approach</p>
<p>C2U will keep your Personal Information confidential and not sell
    or knowingly divulge User information to any external third-parties, unless:</p>
<p>We believe, in good faith, that we are required to share the
    Personal Information with a third party in order to comply with legitimate legal obligations;</p>
<p>The disclosure is to a third-party processor of Personal
    Information that acts on our behalf and/or under our instruction in order to enable us to develop and deliver the
    C2U Platform (e.g. a cloud service provider or local marketing and development partner);</p>
<p>Other entities acquire ownership or operation of C2U or the C2U
    Platform; and/or</p>
<p>We need to protect the safety of Users, and the security of our C2U
    Platform.</p>
<p>C2U seeks the informed and voluntary consent of individuals
    whenever it collects their Personal Information, or as soon as possible after.</p>
<p>Users can always refuse or revoke this consent, but sometimes this
    will affect C2U’s ability to provide them with the C2U Platform and other offerings. C2U will advise Users if this
    is the case.</p>
<p>De-identification</p>
<p>De-identified informationrefers to information that cannot
    reasonably be used to identify a particular individual.</p>
<p>De-identified information that will neverbe able to personally
    identify particular individuals is referred to as anonymised information(e.g. statistics that show 90% of Users were
    happy with the C2U Platform). Additionally, de-identified information that can identify individuals only if it is
    combined with another, separate piece of information is referred to as pseudonymised information (e.g. account ID
    numbers).</p>
<p>Where possible C2U will aim to collect, store and use anonymised
    information as a first preference, and if not, then pseudonymised information.</p>
<p>However, sometimes it will be impractical for User information to
    be de-identified or treated in this way, and in this case, C2U will continue to use and hold the information in a
    personally identifiable state. For example, if C2U needs to reply to a User enquiry we will have to use the contact
    information provided.</p>
<p>Security</p>
<p>C2U is committed to information security. We will use all
    reasonable endeavours to keep the Personal Information we collect, hold and use in a secure environment. To this end
    we have implemented technical, organisational and physical security measures that are designed to protect Personal
    Information, and to respond appropriately if it is ever breached (e.g. C2U has developed an extensive Data Breach
    Response Plan which we use to prepare and respond to data breaches).</p>
<p>When information collected or used by C2U is stored on third-party
    service providers (e.g. Azure or AWS cloud servers), C2U takes reasonable steps to ensure these third-parties use
    industry standard security measures that meet the level of information security C2U owes Users.</p>
<p>As part of our privacy framework we endeavour to routinely review
    these security procedures and consider the appropriateness of new technologies and methods.</p>
<p>Data Breaches
</p>
<p>In the circumstances where C2U suffers a data breach that contains
    Personal Information, we will endeavour to take all necessary steps to comply with the Notifiable Data Breach
    Schemeoutlined under the Act.</p>
<h2>7. CHEMIST 2U’S RETENTION OF
    INFORMATION</h2>
<p>C2U retains Personal Information until it is no longer needed to
    provide or develop the C2U Platform, or until the individual who the Personal Information concerns asks us to delete
    it, whichever comes first. It may take up to 30 days to delete Personal Information from our systems following a
    valid request for deletion.</p>
<p>However, C2U will retain:</p>
<ul>
    <li>Personal Informationin circumstances where we have legal and regulatory obligationsto do
        so (e.g. for law enforcement purposes, employment law, corporate or tax record keeping, or where the information
        is relevant to legitimate legal proceedings); and</li>
    <li>anonymised informationfor analytic and service development purposes.</li>
</ul>
<p>The information we retain will be handled in accordance with this
    PrivacyPolicy.</p>
<h2>8. MANAGING YOUR PERSONAL
    INFORMATION</h2>
<p>Accessing and ensuring the accuracy of Personal Information</p>
<p>C2U takes reasonable steps to ensure that the Personal Information
    we collect and hold is accurate, up to date and complete.</p>
<p>Users have a right to access and request the correction of any of
    their Personal Information we hold about them at any time. Any such requests should be made by directly contacting
    us at the details set out below. C2U will grant access to the extent required or authorised by the Act and
    applicable laws, and will take all reasonable steps to correct the relevant Personal Information where
    appropriate.</p>
<p>There may be circumstances in which C2U cannot provide Users with
    access to information. We will advise you of these reasons if this is the case.</p>
<p>Contacting C2U
</p>
<p>C2U has appointed a Privacy Officer to be the first point of
    contact for all privacy related matters and to assist in ensuring our compliance with our privacy obligations.</p>
<p>Privacy Officer
</p>
<p>Privacy Officer&nbsp;Email:
    privacy@chemist2u.com.au&nbsp;702, 10-14 Waterloo Street Surry Hills NSW 2010</p>
<p>If you have any queries or wish to make a complaint about a breach
    of this Privacy Policy or the Act you can contact or lodge a complaint to our Privacy Officer using the contact
    details above. You will need to provide sufficient details regarding your complaint as well as any supporting
    evidence and/or information.</p>
<p>The Privacy Offer will respond to your query or complaint as
    quickly as possible. C2U will contact you if we require any additional information from you and will notify you in
    writing (which includes electronic communication via email) of the relevant determination. If you are not satisfied
    with the determination you can contact us to discuss your concerns or complain to the Australian Privacy
    Commissionervia www.oaic.gov.au.</p>
<h2>This
    Privacy Policy was last updated on 1 August2019.</h2>
`
};

export const termsModal = {
    heading: "Terms & Conditions",
    content: `
<p>Chemist 2U Australia Pty Ltd (ACN 632 961 552) (‘C2U’ or ‘we’) operates an online platform
    and related service that connects members with pharmacies for the purchase and delivery of a range of
    goods to
    members, including without limitation therapeutic goods pursuant to medical prescriptions
    (‘C2UService’).</p>
<p>Below are our terms and conditions of use in respect of the C2U Service (‘Terms’).</p>
<p>Your use of the C2U Platform or the C2U Service confirms that you
    agree to be bound by these Terms, and that you are over the age of 18 years.</p>
<h2>1 KEY DEFINITIONS</h2>
<h2>In these Terms:</h2>
<p>‘C2U Mobile Applications’ means the latest iOS and/or Android
    version of the C2U mobile application for the Compatible Mobile Device that provides various mobile
    services as part
    of the C2U Platform.</p>
<p>‘C2U Platform’ means the C2U Site and the C2U Mobile
    Applications.</p>
<p>‘C2U Services’ means an online platform that connects members with
    pharmacies for the purchase and delivery of a range of goods to members, including without limitation
    therapeutic
    goods pursuant to medical prescriptions.</p>
<p>‘C2U Product’means any products made available over the C2U
    Platform, or through C2U Services, including without limitation Pharmacy Products.</p>
<p>‘C2U Site’ means the C2U websitelocated at www.chemist2u.com.au.</p>
<p>‘Confidential Information’means all confidential and proprietary
    information of C2U, whether disclosed to you orally or in writing, that is designated as confidential or
    that
    reasonably should be understood to be confidential given the nature of the information and the
    circumstances of
    disclosure, including the terms and conditions of this Agreement, business, financial, product and
    technical
    information.</p>
<p>‘Delivery’ means a delivery of C2U Products made in fulfilment of
    an Order, and pursuant to the relevant Delivery Instructions.
</p>
<p>‘Delivery Instructions’ means instructions regarding the specific
    details of a Delivery requested by a User, including without limitation details such as the location,
    time and date
    at which a Delivery is requested to be made by a Member.</p>
<p>‘Deliverer’ means an independent contractor engaged to pick up and
    deliver C2U Products from Supplier on behalf of the User</p>
<p>‘Delivery Zone’ means The geographic area within which the C2U
    platform allows order to be delivered. For full delivery zones see the C2U site.</p>
<p>‘Order’means a collection of C2U products chosen by the user and
    paid for via the c2UPlatform</p>
<p>‘Intellectual Property’means intellectual property owned by a
    party, of every sort, whether or not registered or registrable in any country, including intellectual
    property of
    kinds coming into existence after today; and including without limitation patents, trade marks,
    unregistered marks,
    designs, copyrights, software, domain names, discoveries, creations and inventions, together with all
    rights which
    are derived from those rights.</p>
<p>‘Intellectual Property Rights’or ‘IPRs’means all industrial and
    intellectual property rights including but not limited to copyright (both present and future),
    Confidential
    Information (including know-how and trade secrets), moral rights, patents, designs and trade marks, any
    suis generis
    database protection rights and in each case whether registered or not.</p>
<p>‘User’means an individual located in Australia who wishes to place
    an Order through the C2U Platform and C2U Service.</p>
<p>‘Member Account’ means a C2U account established by a User in
    accordance with clause 2.</p>
<p>‘Pharmacy Products’ are C2U Products comprised of substances listed
    in Schedule 2 (“Pharmacy Medicine”), Schedule 3 (“Pharmacist Only Medicine”), and Schedule 4
    (“Prescription Only
    Medicine”) of the Poisons Standard June 2019(Cth) under the Therapeutic Goods Act 1989 (Cth).
<h2>Schedule 8 items (“Drugs of Dependence”) can not be ordered via C2u.</h2>
<p>‘Supplier’means a licensed pharmacy that offers to supply C2U
    Products through the C2U Platform or C2U Services .</p>
<p>‘Supply Contract’ means any agreement entered into between the
    Supplier and the User to transact C2U Products through the C2U Platform.</p>
<p>‘Third Party Account’ or ‘TPA’ means a Member Account that is
    created using an existing user account for approved third-party platform.</p>
<p>‘User’ means an individual or person who visits, views, downloads
    or uses the C2U Platform in whole or part..</p>
<p>‘User Content’ means all content that a User posts, uploads,
    publishes, submits, transmits, or includes in their Member Account or C2U promotional campaign
    to be made available
    through the C2U Platform and C2U Service.</p>
<h2>2 MEMBER ACCOUNTS</h2>
<p>2.1 In order to access certain features of the C2U Platform, and to
    use the C2U Service, a person must become a Member by registering to create a Member Account.
</p>
<p>2.2 Registering and creating a Member Account with C2U is free.
    However, to create a Member Account and use the C2U Service you must be able to form a legally
    binding contract
    under applicable law. The C2U Service is not available to persons under 18 years of age. If you
    do not qualify to
    use the C2U Service, you must not use the C2U Service.</p>
<p>2.3 A User may create a Member Account by registering their details
    with the C2u plaform directly or by logging in via an approved third-party platform account
    (‘Third Party Account’
    or ‘TPA’), via the Mobile Application, as described below and validly completing a registration.
</p>
<p>2.4 As part of the functionality of the C2U Platform, you may link
    your Member Account with TPAs, by either: (i) providing your TPA login information to C2U
    through the C2U Platform;
    or(ii) allowing C2U to access your TPA, as permitted under the applicable terms and conditions
    that govern your use
    of each TPA.</p>
<p>2.5 You represent that you are entitled to disclose the TPA login
    information to C2U and/or grant C2U access to your TPA (including, but not limited to, for use
    for the purposes
    described herein), without breach by you of any of the terms and conditions that govern your use
    of the applicable
    TPA and without obligating C2U to pay any fees or making C2U subject to any usage limitations
    imposed by such
    third-party service providers.</p>
<p>2.6 By granting C2U access to any TPA, you understand that C2U will
    access, make available and store (if applicable) any specifically authorised content that you
    have provided to and
    stored in your TPA (‘TPAContent’) so that it is available on and through the C2U Platform via
    your Member Account.
    Unless otherwise specified in these Terms, all TPA Content, if any, will be considered User
    Content for all purposes
    of these Terms.</p>
<p>2.7 Depending on the TPAs you choose and subject to the privacy
    settings that you have set in such TPAs, personally identifiable information that you post to
    your TPAs will be
    available on and through your Member Account on the C2U Platform. If a TPA or associated service
    becomes unavailable
    or C2U’s access to such TPA is terminated by the third-party service provider, then TPA Content
    will no longer be
    available on and through the C2U Platform. Please note that your relationship with the
    third-party service providers
    associated with your TPAs is governed solely by your agreement(s) with such third-party service
    providers. C2U makes
    no effort to review any TPA Content for any purpose, including but not limited to for accuracy,
    legality or
    non-infringement and C2U is not responsible for any TPA Content.
</p>
<p>2.8 A Member Account can only be created in the name of an
    individual.</p>
<p>2.9 While you are registered with C2U you must maintain control of
    your Member Account. You may not deal with your Member Account in any way (including by allowing
    others to use your
    Member Account or by transferring or selling the Member Account or any of its content to another
    person).</p>
<p>2.10 At its absolute discretion, C2U may refuse to allow any User
    to register or create a Member Account with C2U or cancel or suspend any existing Member
    Account.</p>
<p>2.11 Your Member Account will be created for your use of the C2U
    Platform and is based upon the personal information you provide to us or that we obtain via a
    TPA as described
    above. you may not have more than one (1) Member Account. You agree to provide accurate, current
    and complete
    information during the registration process and to update such information to keep it accurate,
    current and
    complete. C2U reserves the right to suspend or terminate your Member Account and your access to
    the C2U Platform and
    C2U Service if you create more than one (1) Member Account, or if any information provided
    during the registration
    process or thereafter proves to be inaccurate, fraudulent, not current, incomplete, or otherwise
    in violation of
    these Terms.</p>
<p>2.12 You are responsible for safeguarding your password to your
    Member Account. You agree that you will not disclose your password to any third party.</p>
<p>2.13 You are not permitted to share your Member Account with anyone
    or allow others to access or use your Member Account.</p>
<p>2.14 You expressly agree that C2U has no responsibility and makes
    no warranty as to the truth, accuracy or completeness of any aspect of any information provided
    by Suppliers. C2U
    does not verify this information.</p>
<p>2.15 You agree that C2U may contact you by telephone or text
    messages at any of the phone numbers provided by you or on your behalf in connection with a
    Member Account or
    providing the Services, including for marketing purposes.</p>
<p>2.16 You agree that a Supplier (Pharmacist) may contact you via
    telephone or text messages in order to discuss any items you have ordered from them.</p>
<p>2.17 You agree that a Deliverermay contact you via telephone or
    text messages in order to locate you when making a Delivery.
</p>
<p>2.18 If C2U determines at its sole discretion that you have
    breached any obligation under these Terms it reserves the rights to cancel or suspend your
    Member Account, and thus
    your Membership.</p>
<h2>3 C2U SERVICE DESCRIPTION AND
    ORDERS</h2>
<p>3.1 C2U makes available an online platform or marketplace through
    which Members can purchase a range of C2U Products, which are then delivered to Members’
    designated address. C2U is
    not a pharmacy. Unless explicitly specified otherwise in the C2U Platform, C2U’s
    responsibilities are limited to
    facilitating the availability of the C2U Platform and C2U Service.</p>
<p>3.2 A Member wishing to use the C2U Service must first create a
    Member Account.</p>
<p>3.3 C2U will from time to time display C2U Products for purchase by
    Customers via the C2U Platform.</p>
<p>3.4 To create an Order for C2U Products with a Supplier using the
    C2U Platform, the following process shall apply:</p>
<p>(a) The Member makes an offer to buy certain C2U Products through
    the C2U Platform on the terms and conditions described in the Order;</p>
<p>(b) C2U refers the Order to a Supplier and Supplier confirms
    whether the Supplier can supply the Order on the terms and conditions described in the Order;
</p>
<p>(c) Where the Supplier confirms through the C2U Platform that an
    Order is able to be supplied and is accepted, and the Member pays for the C2U Products, a Supply
    Contract is formed
    and the Supplier gets the Order ready for collection and delivery to the Member by a Deliverer.
</p>
<p>3.5 C2U reserves the right to in its absolute discretion to cancel
    any Orders for any reason, including without limitation, an error in the price or the
    description of C2U Products or
    a Customer error in placing the Order.</p>
<p>3.6 In order to purchase C2U products that are prescription-based
    (e.g. Pharmacy Products), the Member must have provide proof of the relevant pharmaceutical
    prescriptions, and
    upload such proof at the time of placing the Order.</p>
<p>3.7 The Order process will require you to provide Delivery
    Instructions in respect of the Order. C2U will take your Delivery Instructions into account when
    organising your
    Delivery, but the final details of the Delivery will be as confirmed by C2U over email or
    through in-app
    notifications.</p>
<p>3.8 Orders will not be accepted if the relevant Delivery
    Instructions includes a Delivery location outside of the Delivery Zones.</p>
<p>3.9 The C2U Platform allows users to order at any time of the day,
    however the delivery of those orders is only available during the Delivery Times outlined on the
    C2U site</p>
<p>3.10 With respect to the completion of Deliveries:</p>
<p>(a) You must ensure that you are able to accept the Delivery
    without undue delay and at the time and place reasonably confirmed by C2U, and you must
    reasonably ensure the place
    of Delivery is safe (e.g. there are no hazards, and any pets are controlled).</p>
<p>(b) In the case of Pharmacy Products you must ensure you have valid
    Australianphoto identification should this be required based on the C2U products you have
    ordered, as well as the
    original, hard copy prescription(s) ,which you uploaded during the Order process that is
    required to be provided to
    a pharmacy.</p>
<p>(c) Deliverers will not commit to completing the Delivery if the
    Delivery location is unsafe, or beyond the front door of the Delivery address (i.e. a point
    beyond which entry into
    the interior of the building occurs).</p>
<p>(d) If a Deliverer deems it safe and practical they may, following
    your request, agree to complete the Delivery beyond the front door, and in these circumstances
    you agree to
    continually indemnify and hold harmless C2U from any Loss suffered or incurred through the
    completion of the
    Delivery beyond your front door.</p>
<p>(e) If a Deliverer reasonably deems a requested Delivery location
    to be unsafe, the order will be returned to the dispatch address and redelivery fees may apply.
</p>
<p>3.11 Once the Deliverer has Delivered an Order in accordance with
    clause 3.4above, and any relevant prescriptions have been collected, the Deliverer will notify
    C2U that the Delivery
    is complete. A Delivery will be deemed to be completed upon C2U’s receipt of this notification
    from the
    Deliverer.</p>
<p>3.12 You acknowledge and agree that, where the C2U Product is
    prescription-based (e.g. a Pharmacy Product), you, not C2U, will be responsible for providing a
    valid prescription
    and any other documentation required for both the purchase and Delivery of Pharmacy Products.
</p>
<p>3.13 The description of the C2U Service may be reviewed and updated
    from time to time, and any updates will be provided here.</p>
<h2>4. END USER LICENCE AGREEMENT</h2>
<p>4.1 C2U Mobile Applications are made available via the Apple App
    Store and Google Play Store and are licensed, not sold, to you. Your licence to each of the C2U
    Mobile Applications
    is subject to your prior acceptance of this End User Licence Agreement (‘EULA’) and these
    Customer Terms. C2U as
    licensor reserves all rights in and to the C2U Mobile Applications not expressly granted to you
    under this EULA.</p>
<p>4.2 C2U grants to you a non-transferable licence to use the C2U
    Mobile Applications on any Apple-branded or Android products that you own or control and as
    permitted by the Apple
    Usage Rules and these Customer Terms (‘Licensed Application’).
</p>
<p>This EULA will govern any content, materials, or services
    accessible from the Licensed Application, as well as upgrades provided by C2U that replace or
    supplement the
    original Licensed Application, unless such upgrade is accompanied by its own licence.</p>
<p>Except as provided in the Apple Usage Rules or equivalent rules in
    respect of Android phones, you may not distribute or make the Licensed Application available
    over a network where it
    could be used by multiple devices at the same time. You may not transfer, redistribute or
    sublicense the Licensed
    Application and, if you sell your device to a third party, you must remove the Licensed
    Application from the device
    before doing so.</p>
<p>You may not copy (except as permitted by this licence and the Apple
    Usage Rules), reverse-engineer, disassemble, attempt to derive the source code of, modify, or
    create derivative
    works of the Licensed Application, any updates, or any part thereof (except as and only to the
    extent that any
    foregoing restriction is prohibited by applicable law or to the extent as may be permitted by
    the licensing terms
    governing use of any open-sourced components included with the Licensed Application).</p>
<p>4.3 If you accessed or downloaded C2U Mobile Applications from the
    App Store, then you agree to use the App only: (a) on a compatible and fully working
    Apple-branded product or device
    that runs iOS (Apple’s proprietary operating system software); and (b) as permitted by the
    “Usage Rules” set forth
    in the Apple Store Terms of Service.</p>
<p>4.4 If you accessed or downloaded C2U Mobile Applications from any
    app store or distribution platform (like the Apple App Store or Google Play Store) (each, an
    ‘App Provider’), then
    you acknowledge and agree that:</p>
<p>(a) These Customer Terms are concluded between you and C2U, and not
    with App Provider, and that, as between us and the App Provider, C2U is solely responsible for
    C2U Mobile
    Applications. App Provider has no obligation to furnish any maintenance and support services
    with respect to C2U
    Mobile Applications and or Competitions.</p>
<p>(b) In the event of any failure of C2U Mobile Applications to
    conform to any applicable warranty, you may notify App Provider, and App Provider will refund
    the purchase price for
    C2U Mobile Applications to you (if applicable) and, to the maximum extent permitted by
    applicable law, App Provider
    will have no other warranty obligation whatsoever with respect to the App. Any other claims,
    losses, liabilities,
    damages, costs, or expenses attributable to any failure of an App to conform to any warranty
    will be the sole
    responsibility of C2U. App Provider is not responsible for addressing any claims you have or any
    claims of any third
    party relating to the App or your possession and use of C2U Mobile Applications, including but
    not limited to: (i)
    product liability claims; (ii) any claim that C2U Mobile Applications fails to conform to any
    applicable legal or
    regulatory requirement; and (iii) claims arising under consumer protection or similar
    legislation.</p>
<p>(c) In the event of any third-party claim that a C2U Mobile
    Application or your possession and use of a C2U Mobile Application infringes that third party’s
    intellectual
    property rights, C2U will be solely responsible for the investigation, defence, settlement, and
    discharge of any
    such intellectual property infringement claim to the extent required by these Mobile App Terms
    and Conditions.</p>
<p>(d) App Provider and its subsidiaries are third-party beneficiaries
    of these Customer Terms as related to your licence of C2U Mobile Applications, and that, upon
    your acceptance of
    these Customer Terms, App Provider will have the right (and will be deemed to have accepted the
    right) to enforce
    these Customer Terms as related to your licence of C2U Mobile Applications against you as a
    third-party beneficiary
    thereof.</p>
<p>4.5 You must also comply with all applicable third-party terms of
    service when using C2U Mobile Applications.</p>
<p>4.6 You agree to comply with all Australian laws and regulations to
    ensure that neither C2U Mobile Applications nor any technical data related thereto nor any
    direct product thereof is
    exported or re-exported directly or indirectly in violation of, or used for any purposes
    prohibited by, such laws
    and regulations. By using C2U Mobile Applications, you represent and warrant that: (i) you are
    not located in a
    country that is subject to a Government embargo, or that has been designated as a “terrorist
    supporting” country;
    and (ii) you are not listed on any list of prohibited or restricted parties.</p>
<h2>5 CHARGES AND PAYMENT PROCESS</h2>
<p>5.1 In consideration for an Order, Members will be charged fees per
    Order (‘Service Fees’) as displayed at the time of transaction that is based on the specifics of
    that Order,
    including without limitation with respect to what C2U Products are provided and the details of
    the Delivery. Members
    agree to pay C2U the Service Fees, and will be required to enter their payment details in order
    to complete the
    Order Process.</p>
<p>5.2 All payments of Service Fees will be enabled by C2U via a third
    party service provider (‘Payment Provider’) to provide payment services and act as a payment
    facilitator and escrow
    agent on behalf of the Member, Supplier and C2U, and subject to any terms of the Payment
    Provider using the
    preferred payment method designated in your Member Account.
</p>
<p>5.3 C2U does not store your financial information, and as a
    consequence is not required to maintain PCI compliance. After each Order has been processed
    Members will receive:</p>
<p>(a) a confirmation communication summarising your confirmed Order
    and Delivery details; and</p>
<p>(b) a receipt in respect of the Order.</p>
<h2>6. THIRD PARTY SERVICES</h2>
<p>6.1 By registering and creating a Member Account with C2U, you
    agree to be bound by the Payment Provider’s terms and conditions and Privacy Policy which are
    located at eWay.com.au
    and hereby consent and authorise C2U and the Payment Provider to share any information and
    payments instructions you
    provide with one another and, to the extent required to complete your transaction, with any
    other third party
    service provider(s).</p>
<p>6.2 C2U may from time to time include on the C2U Platform
    promotions for and links to services offered by the Payment Provider and other third parties
    (‘Third Party
    Services’). These Third Party Services are not provided by C2U.
</p>
<p>6.3 Third Party Services are offered to you pursuant to terms and
    conditions offered by the third party. If you engage with any Third Party Service provider your
    agreement will be
    directly between you and that Third Party Service provider.
</p>
<p>6.4 C2U makes no representation or warranty as to the Third Party
    Services.</p>
<h2>7. CANCELLATIONS AND REFUNDS</h2>
<p>7.1 Once an Order has been processed and payment made, a Member
    cannot cancel the Order.</p>
<p>7.2 In the event that you are delivered the wrong C2U Products as
    part of the Order, then you must advise us as soon as possible together with relevant details
    and a customer service
    person will be in touch with respect to further steps, which may include a refund of all or part
    of the relevant
    Service Fee.</p>
<h2>8. USER’S OBLIGATIONS</h2>
<p>8.1 You agree that at all times:</p>
<p>(a) you will comply with these Terms (including all C2U Policies)
    and all applicable laws and regulations;</p>
<p>(b) you will post only accurate information on the C2U Platform;</p>
<p>(c) you will promptly and efficiently perform all your obligations
    to Users under a Charge Contract and to C2U in accordance with these Terms;</p>
<p>(d) all content (whether provided by C2U, a User or a third party)
    on the C2U Platform may not be used on third party sites or for other business purposes without
    C2U’s prior
    permission; and</p>
<p>(e) you will ensure that you are aware of any laws that apply to
    you as a Member or Supplier, or in relation to any other way(s) that you use the C2U Platform.
</p>
<p>8.2 You must not use the C2U Platform for any illegal or immoral
    purpose.</p>
<p>8.3 You grant to C2U an unrestricted, worldwide, royalty-free
    licence to use, reproduce, modify and adapt any content and information posted on the C2U
    Platform for the purpose
    of including that material and information on or through the C2U Platform and as otherwise may
    be required to
    provide the C2U Service, for the general promotion of the C2U Service and as permitted by these
    Terms.</p>
<p>8.4 Any information posted on the C2U Platform must not, in any way
    whatsoever, be potentially or actually harmful to C2U or any other person. “Harm” includes, but
    is not limited to,
    economic loss that will or may be suffered by C2U. Without limiting any provision of these
    Terms, any information
    you supply to C2U, through the C2U Platform or to any other User must be up to date and kept up
    to date and must
    not:</p>
<p>(a) be false, inaccurate or misleading or deceptive;</p>
<p>(b) be fraudulent or involve the sale of counterfeit or stolen
    items;</p>
<p>(c) infringe any third party’s intellectual property rights;</p>
<p>(d) violate any applicable law, statute, ordinance or
    regulation;</p>
<p>(e) be defamatory, libellous, threatening or harassing;</p>
<p>(f) be obscene or contain any material that, in C2U’s sole and
    absolute discretion, is in any way inappropriate or unlawful, including, but not limited to
    obscene, inappropriate
    or unlawful images;</p>
<p>(g) contain any malicious code, data or set of instructions that
    intentionally or unintentionally causes harm or subverts the intended function of any C2U
    Platform, including, but
    not limited to viruses, Trojan horses, worms, time bombs, cancelbots, Easter eggs or other
    computer programming
    routines that may damage, modify, delete, detrimentally interfere with, surreptitiously
    intercept, access without
    authority or expropriate any system, data or personal information.</p>
<h2>9 FEEDBACK</h2>
<p>9.1 C2U is entitled to suspend or terminate your Member Account at
    any time if C2U, in its sole and absolute discretion, is concerned by any feedback about you, or
    considers your
    feedback rating to be problematic for other C2U Users.</p>
<p>9.2 To continue to improve our C2U Platform, please let us know of
    any issue that you experience using a Third Party Service by contacting us via the customer
    service details listed
    on our website at www.chemist2u.com.au</p>
<h2>10 LIMITATION OF LIABILITY</h2>
<p>10.1 Except for liability in relation to breach of any implied
    condition, warranty or guarantee, including under the Competition and Consumer Act 2010 (Cth),
    the exclusion of
    which from a contract would contravene any statute or cause any part of these Terms to be void
    (‘Non-excludable
    Condition’), to the extent permitted by law C2U specifically disclaims all liability for any
    loss or damage (actual,
    special, direct, indirect and consequential) of every kind and nature, known and unknown,
    suspected and unsuspected,
    disclosed and undisclosed (including, without limitation, loss or damage relating to any
    inaccuracy of information
    provided, or the lack of fitness for purpose of the transaction between Member and Supplier).
</p>
<p>10.2 Except for liability in relation to a breach of any
    Non-excludable Condition, to the extent permitted by law, C2U specifically disclaims all
    liability for any loss or
    damage (actual, special, direct, indirect and consequential) of every kind and nature, known and
    unknown, suspected
    and unsuspected, disclosed and undisclosed (including, without limitation, loss or damage
    relating to any inaccuracy
    of information provided, or the lack of fitness for purpose of any goods or service supplied),
    arising out of or in
    any way connected with any transaction between you and any Third Party Provider who may be
    included from time to
    time on the C2U Platform.</p>
<p>10.3 Except for liability in relation to a breach of any
    Non-excludable Condition, C2U’s liability to any User of this service is limited to the total
    amount of $100.</p>
<p>10.4 C2U’s liability to you for a breach of any Non-excludable
    Condition (other than a Non-excludable Condition that by law cannot be limited) is limited, at
    our option to any one
    of resupplying, replacing or repairing, or paying the cost of resupplying, replacing or
    repairing goods in respect
    of which the breach occurred, or supplying again or paying the cost of supplying again, services
    in respect of which
    the breach occurred.</p>
<p>10.5 C2U operates a distribution service for Suppliers. It is not
    party to a Supply Contract between a Supplier and a User. C2U accepts no liability for any
    aspect of a User’s
    interaction with a Supplier or a Deliverer, and C2U has no obligation to any User to assist or
    involve itself in any
    way in any dispute between a User and a Supplier or a User and a Deliverer.</p>
<p>10.6 Except for liability in relation to any Non-excludable
    Condition, the C2U Service is provided on an “as is” basis, and without any warranty or
    condition, express or
    implied. To the extent permitted by law, we and our suppliers specifically disclaim any implied
    warranties of title,
    merchantability, fitness for a particular purpose and non-infringement to the extent allowed by
    law.</p>
<h2>11 DISCLAIMERS</h2>
<p>11.1 If you choose to use the C2U Platform or C2U Services you do
    so at your sole risk. You acknowledge and agree that C2U does not have an obligation to conduct
    background or
    registered sex offender checks on any Deliverer. We may conduct such background or registered
    sex offender checks,
    in our sole discretion, to the extent permitted by applicable laws and if we have sufficient
    information to identify
    a Deliverer. If we choose to conduct such checks, to the extent permitted by applicable law, we
    disclaim warranties
    of any kind, either express or implied, that such checks will identify prior misconduct by a
    Deliverer or guarantee
    that a Deliverer will not engage in misconduct in the future.
</p>
<p>11.2 C2U explicitly disclaims any warranties of merchantability,
    satisfactory quality, fitness for a particular purpose, quiet enjoyment or non-infringement, and
    any warranties
    arising out of the course of dealing or usage of trade. C2U makes no warranty thatthe C2U
    Platform or C2U
    Servicewill meet your requirements or be available on an uninterrupted, secure, or error-free
    basis.</p>
<p>11.3 Based on pharmacy business hours, C2U will use reasonable
    endeavours to Deliver your Order within 3 hours of Order confirmation, though we cannot
    guarantee this. This is
    subject to C2U’s confirmation of your Order and is a guideline only. Factors that may change the
    delivery time
    include when an order is placed (e.g. if it is placed outside of designated Delivery Zone), or
    any adverse weather
    or traffic conditions. We will contact you as soon as possible in the event of an unavoidable
    delay to make
    alternative arrangements. You should not rely on C2U’s predicted delivery times or service
    availability to guarantee
    access to your health and medical needs. Any medication that is critical to your health or
    required to be taken on a
    strict schedule should be ordered well ahead of time, and you should not rely on C2U for urgent
    deliveries. C2U is a
    distribution provider and does not sell therapeutic goods.</p>
<p>11.4 No advice or information, whether oral or written, obtained
    from C2U or through the C2U Services, C2U Platform or collective content, will create any
    warranty not expressly
    made herein.</p>
<p>11.5 You are solely responsible for all of your communications and
    interactions over C2U Platform, and with other persons with whom you communicate or interact as
    a result of your use
    of the C2U Services or C2U Platform including, but not limited to, any Deliverers or other
    Users. You understand
    that C2U does not make any attempt to verify the statements of Users on the C2U Services or C2U
    Platform. C2U makes
    no representations or warranties as to the conduct of Users on the C2U Services or C2U Platform
    or their
    compatibility with any current or future Users of the C2U Services or C2U Platform. You agree to
    take reasonable
    precautions in all communications and interactions with Deliverers or other Users on C2U
    Platform and with other
    persons with whom you communicate or interact as a result of your use of the C2U Services or C2U
    Platform,
    including, but not limited to Deliverers. C2U explicitly disclaims all liability for any act or
    omission of any
    guest or other third party.</p>
<h2>12 DEFAULT IN TRANSACTIONS</h2>
<p>If you fail to complete a transaction and do not have a lawful
    excuse for such failure, then in addition to C2U’s rights in accordance with these Terms
    (including any C2U policy)
    to suspend (temporarily or indefinitely) or terminate the Member Account.</p>
<h2>13 PRIVACY</h2>
<p>13.1 C2U’s Privacy Policy, which is available on our website at
    www.chemist2u.com.au , applies to all Users and forms part of these Terms. Use of the C2U
    Platform confirms that you
    consent to, and authorise, the collection, use and disclosure of your personal information in
    accordance with C2U’s
    Privacy Policy.</p>
<p>13.2 Third Party Service providers will provide their service
    pursuant to their own privacy policy. Prior to acceptance of any service from a third party you
    must review and
    agree to their terms of service including their privacy policy.
</p>
<p>13.3 Users are required by these Terms to provide accurate
    information. Although C2U may, for transparency or fraud prevention or detection purposes,
    directly or through third
    parties, ask you to provide a form of government identification, your date of birth, and other
    information, or
    undertake additional checks and processes designed to help verify or check the identities or
    backgrounds of Users
    and/or screen User information against third party databases or other sources, we do not make
    any representations
    about, confirm, or endorse any User or the User’s purported identity or background.</p>
<p>13.4 By registering for a Member Account, you agree that C2U may,
    but is not obligated to, request a consumer report on you from a Consumer Reporting Agency. If
    we do request a
    consumer report, we’ll request and use it in compliance with applicable law.</p>
<h2>14 MODIFICATIONS TO THESE TERMS</h2>
<p>14.1. C2U may modify these Terms and/or the Policies (and update
    the C2U pages on which they are displayed) from time to time. C2U will send notification of such
    modifications to
    your Member Account. You should check that account regularly.
</p>
<p>14.2. If you do not agree with any changes to these Terms (or any
    of our Policies), you must terminate your Member Account and stop using the C2U Service. Except
    as specifically
    described in or authorised by these Terms, the Terms and any C2U Policy cannot be amended except
    in writing signed
    by you and C2U.</p>
<h2>15 NO AGENCY</h2>
<p>15.1 No agency, partnership, joint venture or other similar
    relationship is created by these Terms. In particular, you have no authority to bind C2U, its
    related entities or
    affiliates in any way whatsoever. C2U confirms that all Third Party Services that may be
    promoted on the C2U Service
    are provided solely by such Third Party Service providers. To the extent permitted by law, C2U
    specifically
    disclaims all liability for any loss or damage incurred by you in any manner due to the
    performance or no-
    performance of such Third Party Service.</p>
<h2>16 INTELLECTUAL PROPERTY</h2>
<p>16.1 The C2U Platform and C2U Service are protected by copyright,
    trade mark, and other laws of Australia and other countries. You acknowledge and agree that the
    C2U Platform and C2U
    Service, including all associated Intellectual Property Rights, are the exclusive property of
    C2U and its licensors.
    You will not remove, alter or obscure any Intellectual Property incorporated in or accompanying
    the C2U Platform or
    C2U Service.</p>
<p>16.2 By making available any User Content on or through the C2U
    Platform and C2U Service, or through C2U promotional campaigns, you hereby grant to C2U a
    worldwide, irrevocable,
    perpetual (or for the term of the protection), non-exclusive, transferable, royalty-free
    licence, with the right to
    sublicense, to use, view, copy, adapt, translate, modify, distribute, licence, sell, transfer,
    publicly display,
    publicly perform, transmit, stream, broadcast, access, view, and otherwise exploit such User
    Content on, through, by
    means of or to promote or market the C2U Platform and C2U Service. C2U does not claim any
    ownership rights in any
    such User Content and nothing in these Terms will be deemed to restrict any rights that you may
    have to use and
    exploit any such User Content.</p>
<h2>17 TAXES</h2>
<p>17.1 You acknowledge that a Charge Contract between a Supplier and
    a Member constitutes a supply by the Supplier to the Member which may attract GST. C2U is not a
    party to this
    contract of supply and has no obligations or liability in respect of any applicable GST (if
    any).</p>
<h2>18 GENERAL</h2>
<p>18.1 These Terms are governed by the laws of New South Wales,
    Australia. You and C2U submit to the exclusive jurisdiction of the courts of New South Wales,
    Australia.</p>
<p>Last revised 22 July 2019</p>
`
};
