"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Role = void 0;
const config_1 = require("../config");
const default_1 = require("../default");
const _BaseParseObject_1 = require("./_BaseParseObject");
const local_parse_1 = require("../local-parse");
class Role extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("_Role", attributes);
  }
  static async getAdmin() {
    const admin = await new local_parse_1.Parse.Query(local_parse_1.Parse.Role).equalTo("name", default_1.ERoles.Admin).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
    if (!admin) throw new local_parse_1.Parse.Error(local_parse_1.Parse.Error.OBJECT_NOT_FOUND, "Admin role not found in system.");
    return admin;
  }
  static async getCustomer() {
    const customer = await new local_parse_1.Parse.Query(local_parse_1.Parse.Role).equalTo("name", default_1.ERoles.Customer).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
    if (!customer) throw new local_parse_1.Parse.Error(local_parse_1.Parse.Error.OBJECT_NOT_FOUND, "Customer role not found in system.");
    return customer;
  }
  static async getPharmacy() {
    const pharmacy = await new local_parse_1.Parse.Query(local_parse_1.Parse.Role).equalTo("name", default_1.ERoles.Pharmacy).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
    if (!pharmacy) throw new local_parse_1.Parse.Error(local_parse_1.Parse.Error.OBJECT_NOT_FOUND, "Pharmacy role not found in system.");
    return pharmacy;
  }
  static async getRoleByName(roleName) {
    return await new local_parse_1.Parse.Query(Role).equalTo("name", roleName).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async getUsersForRole(roleName) {
    var _a;
    const role = await this.getRoleByName(roleName);
    const users = await ((_a = role === null || role === void 0 ? void 0 : role.users) === null || _a === void 0 ? void 0 : _a.query().findAll({
      useMasterKey: config_1.C2UConfig.useMasterKey
    }));
    return users;
  }
  static async addUserToRole(user, roleName) {
    const role = await Role.getRoleByName(roleName);
    if (!role) throw new local_parse_1.Parse.Error(local_parse_1.Parse.Error.OBJECT_NOT_FOUND, `'${roleName}' role not found in system.`);
    const relation = role.users;
    if (relation) {
      relation.add(user);
    } else {
      throw new local_parse_1.Parse.Error(local_parse_1.Parse.Error.OBJECT_NOT_FOUND, "Could not add user to relation as relation is missing");
    }
    return await role.save(null, {
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static pointer(id) {
    return {
      __type: "Pointer",
      className: "_Role",
      objectId: id
    };
  }
}
exports.Role = Role;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], Role.prototype, "name", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)({
  write: false
}), __metadata("design:type", Object)], Role.prototype, "users", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)({
  write: false
}), __metadata("design:type", Object)], Role.prototype, "roles", void 0);
local_parse_1.Parse.Object.registerSubclass("_Role", Role);
