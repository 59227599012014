"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Item = void 0;
const config_1 = require("../config");
const _BaseParseObject_1 = require("./_BaseParseObject");
const local_parse_1 = require("../local-parse");
class Item extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("Item", attributes);
  }
  static async getCount() {
    return new local_parse_1.Parse.Query(Item).count({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async getAll() {
    return new local_parse_1.Parse.Query(Item).findAll({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async getByAPN(apn) {
    return await new local_parse_1.Parse.Query(Item).equalTo("apn", apn).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async getMasterCatalogItems(limit) {
    return await new local_parse_1.Parse.Query(Item).limit(limit).equalTo("masterCatalog", true).find({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async fetchByIds(ids, allowedFields) {
    const query = this.Query().containedIn("objectId", ids);
    if (allowedFields) {
      query.select(allowedFields);
    }
    return query.find({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static pointer(id) {
    return {
      __type: "Pointer",
      className: "Item",
      objectId: id
    };
  }
}
exports.Item = Item;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "apn", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "basePrice", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "brand", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "canonical", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "category", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "deletedAt", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "description", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "dimensions", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "discount", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "featuredImage", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "featuredImageThumb", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "featuredImages", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "images", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "isFeatured", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "isNewArrival", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "isPostable", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "likeCount", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "likes", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "masterCatalog", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "name", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "netPrice", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "price", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "ratingAvg", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "ratingCount", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "ratingTotal", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "relatedItems", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "salePrice", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "schedule", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "slug", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "status", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "subcategory", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "tags", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "view", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Item.prototype, "views", void 0);
local_parse_1.Parse.Object.registerSubclass("Item", Item);
