<app-title-block heading="Payment" description="Add your payment details and submit order."></app-title-block>

<div class="py-4">
    <form id="payment-form" [ngClass]="{ invisible: isLoading() }">
      <div id="rx-flow-payment-step"></div>
    </form>
    @if (isLoading()) {
        <div class="h-[142px] bg-white flex justify-center pt-12">
            <app-spinner [color]="rxTheme.primaryColor()"></app-spinner>
        </div>
    }
</div>

<div class="border-t"></div>

<div class="h-2"></div>
<app-order-totals [order]="order()"></app-order-totals>
