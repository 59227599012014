import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, inject, output, signal, ViewChild } from '@angular/core';
import { provideIcons } from '@ng-icons/core';
import { heroMagnifyingGlass } from '@ng-icons/heroicons/outline';
import { InputComponent } from '../input/input.component';
import { CloudService } from '../../services/cloud.service';
import { AddressType, PlaceAutocompleteResult, PlaceData } from '@googlemaps/google-maps-services-js';
import { AddressRowComponent } from './address-row/address-row.component';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'app-address-search',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    AddressRowComponent,
    SpinnerComponent,
  ],
  providers: [provideIcons({ heroMagnifyingGlass })],
  templateUrl: './address-search.component.html',
  styleUrl: './address-search.component.scss'
})
export class AddressSearchComponent {
  @ViewChild('el') elRef!: ElementRef<HTMLElement>;

  private $cloud = inject(CloudService);

  public select = output<{ prediction: PlaceAutocompleteResult, placeData: PlaceData }>();

  public isLoading = signal<boolean>(false);
  public writeValue = signal<string>("");
  public errorMessage = signal<string | undefined>(undefined);
  public searchValue = signal<string>("");
  public predictions = signal<PlaceAutocompleteResult[]>([]);
  public showPredictions = signal<boolean>(false);

  public onComplete(value: string | number | Date) {
    this.searchValue.set(value as string);
    this.commitSearch();
  }

  private async commitSearch() {
    this.isLoading.set(true);
    const value = this.searchValue();
    if (value.length > 3) {
      const result = await this.$cloud.getGooglePlacesPredictions(value);
      const predictions = result.predictions;
      this.predictions.set(predictions);
      this.showPredictions.set(true);
    } else {
      this.predictions.set([]);
      this.showPredictions.set(false);
    }
    this.isLoading.set(false);
  }

  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent) {
    const elClick = this.elRef.nativeElement.contains(event.target as Node);
    if (elClick) {
      if (!this.showPredictions() && this.searchValue().trim().length > 3 && !this.isLoading()) this.showPredictions.set(true);
    } else {
      this.showPredictions.set(false);
    }
  }

  private showError(message: string) {
    this.errorMessage.set(message);
    setTimeout(() => {
      this.errorMessage.set(undefined);
    }, 3000);
  }

  public async selectAddress(event: MouseEvent, prediction: PlaceAutocompleteResult) {
    event.stopPropagation();
    this.writeValue.set(prediction.description);
    this.showPredictions.set(false);
    this.isLoading.set(true);
    const placeResult = await this.$cloud.getGooglePlaceDetails(prediction.place_id);
    const placeData = placeResult.result as PlaceData;
    const addressComponents = placeData?.address_components || [];
    const hasStreetNumber = addressComponents.some(comp => comp.types.includes(AddressType.street_number));
    this.isLoading.set(false);
    if (!hasStreetNumber) {
      this.showError("Please select a valid address with a street number");
      return;
    }
    this.select.emit({ prediction, placeData });
  }

}
