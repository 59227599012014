import { CommonModule } from '@angular/common';
import { Component, input, signal } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'app-longtext-input',
  templateUrl: './longtext-input.component.html',
  styleUrls: ['./longtext-input.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: LongtextInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: LongtextInputComponent
    }
  ]
})
export class LongtextInputComponent implements ControlValueAccessor, Validator {
  public touched = signal<boolean>(false);
  public value = signal<string | undefined>(undefined);
  public errorMessage = signal<string | undefined>(undefined);

  public label = input<string>("");
  public required = input<boolean>(false);

  onValueChange(value: any) {
    this.markAsTouched();
    this.value.set(value);
    this.onChange(value);
  }

  setErrorMessage(errors: ValidationErrors) {
    const key = Object.keys(errors)[0];
    let msg = "Invalid field";
    switch (key) {
      case "required":
        msg = "This field is required.";
        break;
    }
    this.errorMessage.set(msg);
  }

  // Form Control

  onChange: any = () => {
    this.validate();
  };
  onTouched: any = () => {};

  writeValue(value: string) {
    if (!value) return;
    this.value.set(value);
  }
  
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched()) {
      this.validate();
      this.onTouched();
      this.touched.set(true);
    }
  }
  
  validate(): ValidationErrors | null {
    const value = this.value();
    const errors: ValidationErrors = {
      ...(this.required() && (value == undefined || value.length == 0) && {
        required: true
      }),
    };
    if (Object.keys(errors).length) {
      this.setErrorMessage(errors);
      return errors;
    }
    this.errorMessage.set(undefined);
    return null;
  }

}
