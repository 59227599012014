import { CanActivateFn } from '@angular/router';
import { environment } from '../../environments/environment';

export const rxRedirectGuard: CanActivateFn = (route, state) => {
  const baseUrl = environment.rxBaseUrl;
  const partnerShortId = route.paramMap.get('partnerShortId');
  const orderHash = route.paramMap.get('orderHash') || '';
  window.location.href = `${baseUrl}/${partnerShortId}/${orderHash}`;
  return false;
};

export const appRedirectGuard: CanActivateFn = (route, state) => {
  const baseUrl = environment.appBaseUrl;
  const path = route.url.map(segment => segment.path).join('/');
  const queryParams = route.queryParamMap.keys.map(key => `${key}=${route.queryParamMap.get(key)}`).join('&');
  window.location.href = `${baseUrl}/${path}${queryParams ? '?' + queryParams : ''}`;
  return false;
};

export const orderTrackRedirectGuard: CanActivateFn = (route, state) => {
  window.location.href = environment.appUrl;
  return false;
};

export const legacyRxRedirectGuard: CanActivateFn = (route, state) => {
  const baseUrl = environment.rxBaseUrl;
  const appUrl = environment.appUrl;
  const partnerShortId = route.queryParamMap.get('pid');
  window.location.href = partnerShortId ? `${baseUrl}/${partnerShortId}` : appUrl;
  return false;
};