import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, model, output, signal } from '@angular/core';
import { TitleBlockComponent } from "../../../../components/title-block/title-block.component";
import { OtpComponent } from "../../../../components/otp/otp.component";
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroArrowPath } from '@ng-icons/heroicons/outline';
import { toObservable } from '@angular/core/rxjs-interop';
import { RxThemeService } from '../../services/rx-theme.service';
import { AmplitudeService } from '../../../../services/amplitude.service';

@Component({
  selector: 'step-mobile-confirm',
  standalone: true,
  imports: [CommonModule, TitleBlockComponent, OtpComponent, NgIconComponent],
  providers: [provideIcons({ heroArrowPath })],
  templateUrl: './mobile-confirm.step.html',
  styleUrl: './mobile-confirm.step.scss'
})
export class MobileConfirmStep {
  private $amplitude = inject(AmplitudeService);
  public rxTheme = inject(RxThemeService);
  
  public readonly sendBufferTime = 5000;
  
  public isLoading = model<boolean>(false, { alias: 'loading' });
  public inputOtp = input<string>("", { alias: 'otp' });
  public mobileNumber = input.required<string>();
  public lastSent = input.required<Date>();

  public otpChange = output<string>();
  public resendOtp = output<void>();

  public otp = signal("");
  public cantSend = signal<boolean>(false);

  constructor() {
    this.$amplitude.track('GENERIC_NAVIGATE', { to_page: 'mobile_confirm' });
    toObservable(this.inputOtp).subscribe(v => this.otp.set(v));
    toObservable(this.otp).subscribe(v => this.otpChange.emit(v));
    toObservable(this.lastSent).subscribe(lastSent => {
      let interval = setInterval(() => {
        const cantSend = lastSent.getTime() + this.sendBufferTime > new Date().getTime();
        this.cantSend.set(cantSend);
        if (!cantSend) clearInterval(interval);
      });
    });
  }

  public onResendOtp() {
    if (this.cantSend()) return;
    this.resendOtp.emit();
  }
}
