<div class="medicare">
  <app-input class="main"
    [value]="medicareNumberValue()"
    (valueChange)="medicareNumberValueChange($event)"
    label="Medicare number"
    [externalError]="errors().length > 0 ? errors()[0].data.message : undefined"
    [required]="false" />
  <app-input class="irn"
    [value]="irnValue()"
    (valueChange)="irnValueChange($event)"
    label="Individual ref"
    [required]="false" />
</div>