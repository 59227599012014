<div class="input" [class.focused]="isFocused()" [class.disabled]="isDisabled()">
  <div class="top">
    @if (label()) {
      <label>
        {{ label() }}
        @if (isRequired()) {
          <span class="required">*</span>
        }
      </label>
    }
    @if (hasAction()) {
      <div class="action" (click)="action()">
        @if (actionLabel()) {
          <p>{{ actionLabel() }}</p>
        }
        @if (actionIcon()) {
          <ng-icon [name]="actionIcon()"></ng-icon>
        }
      </div>
    }
  </div>
  @if (help()) {
    <p class="help">{{ help() }}</p>
  }
  @if (label() || hasAction() || help()) {
    <div class="h-2"></div>
  }
  <div [ngClass]="containerClass()">
    @if (icon()) {
      <div class="icon" (click)="clickedIcon($event)">
        <ng-icon [name]="icon()"></ng-icon>
      </div>
    }
    <input #inputElement
      [autocomplete]="autocompleteValue()"
      [type]="inputType"
      [value]="displayValue()"
      (input)="onInput($event)"
      (paste)="onInput($event)"
      (blur)="onBlur()"
      (focus)="onFocus()"
      [attr.disabled]="isDisabled() ? '' : null"
      [attr.required]="isRequired() ? '' : null"
      [attr.placeholder]="placeholder()"
      [attr.step]="step()"
      [attr.min]="min()"
      [attr.max]="max()"
    >
    @if (showPasswordEye()) {
      <button class="clear-button" (click)="togglePasswordPeek()">
        <ng-icon [name]="passwordPeekActive() ? 'heroEyeSlash' : 'heroEye'"></ng-icon>
      </button>
    }
    @if (showClearButton()) {
      <button class="clear-button" (click)="clear()" (touchstart)="onClearTouchStart($event)">
        <ng-icon name="heroXCircle"></ng-icon>
      </button>
    }
  </div>
  <div class="error-message">
    @if (showExternalError() || showError()) {
      {{ externalError() || errorMessage() }}&nbsp;
    }
  </div>
</div>