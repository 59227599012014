import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'app-wave-bg',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './wave-bg.component.html',
  styleUrl: './wave-bg.component.scss'
})
export class WaveBgComponent {
  public heightClass = input<string>('h-32');
  public bgClass = input<string>('bg-white');
}
