import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroXMark } from '@ng-icons/heroicons/outline';

interface Toast {
  id: number;
  message: string;
  type: 'success' | 'error';
  timeout: NodeJS.Timeout;
}

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  imports: [CommonModule, NgIconComponent],
  providers: [provideIcons({ heroXMark })],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {
  public toasts = signal<Toast[]>([]);

  showToast(message: string, type: 'success' | 'error', duration = 3000) {
    const toasts = JSON.parse(JSON.stringify(this.toasts())) as Toast[];
    const id = Math.round(Math.random() * 1000);
    toasts.push({
      id,
      message,
      type,
      timeout: setTimeout(() => this.removeToast(id), duration),
    });
    this.toasts.set(toasts);
  }

  removeToast(id: number) {
    const toasts = JSON.parse(JSON.stringify(this.toasts())) as Toast[];
    const index = toasts.findIndex(t => t.id == id)!;
    const toast = toasts.splice(index, 1)[0];
    clearTimeout(toast.timeout);
    this.toasts.set(toasts);
  }
}
