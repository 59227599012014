<div>
  @if (order().totals) {
    <div>
      @for (lt of totals(); track $index) {
        @if (lt.condition && lt.canDisplay) {
          <div class="row">
            <div class="label" [class.title]="lt.title">{{ lt.label }}</div>
            <div class="value" [class.discount]="lt.isDiscount">
              {{ lt.isReady ? (lt.price | currency) : "Price pending" }}
            </div>
          </div>
        }
      }
    </div>
  }
  <div class="border-t text-secondary text-sm flex items-center gap-3 py-2">
    <div>Payments are encrypted</div>
    <ng-icon name="heroLockClosedSolid" />
  </div>
</div>