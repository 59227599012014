import { Routes } from '@angular/router';
import { appRedirectGuard, legacyRxRedirectGuard, orderTrackRedirectGuard, rxRedirectGuard } from './guards/redirect.guard';
import { RxComponent } from './pages/rx/rx.component';
import { DoctorOrderComponent } from './pages/doctor-order/doctor-order.component';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

const IS_SHOP = environment.hostIsShop;

export const shopRoutes: Routes = [
    { path: 'doctor-order', component: DoctorOrderComponent },
    // Redirects to Rx
    { path: 'rx/:partnerShortId', canActivate: [rxRedirectGuard], component: RxComponent },
    { path: 'rx/:partnerShortId/:orderHash', canActivate: [rxRedirectGuard], component: RxComponent },
    // Redirects to App
    { path: 'order-track', canActivate: [orderTrackRedirectGuard], component: AppComponent },
    { path: 'order-track/:id', canActivate: [orderTrackRedirectGuard], component: AppComponent },
    { path: '**', canActivate: [appRedirectGuard], component: AppComponent },
];

export const rxRoutes: Routes = [
    { path: ':partnerShortId', component: RxComponent },
    { path: ':partnerShortId/:orderHash', component: RxComponent },
    // Redirects old Rx format to new
    { path: '', canActivate: [legacyRxRedirectGuard], component: AppComponent },
];

export const routes: Routes = IS_SHOP ? shopRoutes : rxRoutes;
