"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CustomerSessionDetails = void 0;
const _BaseParseObject_1 = require("./_BaseParseObject");
const config_1 = require("../config");
const local_parse_1 = require("../local-parse");
class CustomerSessionDetails extends _BaseParseObject_1.BaseParseObject {
  get addressGeoPoint() {
    if (this.address && this.address.address && this.address.address.geometry && this.address.address.geometry.location) {
      let location = this.address.address.geometry.location;
      return new local_parse_1.Parse.GeoPoint(location.lat, location.lng);
    } else {
      return undefined;
    }
  }
  constructor(attributes) {
    super("CustomerSessionDetails", attributes);
  }
  static async getSessionForCustomer(customer) {
    return new local_parse_1.Parse.Query(CustomerSessionDetails).include("customer").descending("createdAt").equalTo("customer", customer).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async createSessionForUser(user) {
    const customerSessionDetails = new CustomerSessionDetails();
    customerSessionDetails.customer = user;
    return await customerSessionDetails.save(null, {
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
}
exports.CustomerSessionDetails = CustomerSessionDetails;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerSessionDetails.prototype, "pharmacyId", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerSessionDetails.prototype, "pharmacy", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerSessionDetails.prototype, "address", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerSessionDetails.prototype, "customer", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerSessionDetails.prototype, "customerAddress", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerSessionDetails.prototype, "prefFulfillmentMethod", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], CustomerSessionDetails.prototype, "fulfillmentMethod", void 0);
local_parse_1.Parse.Object.registerSubclass("CustomerSessionDetails", CustomerSessionDetails);
