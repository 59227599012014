"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PharmacyServiceability = void 0;
const _BaseParseObject_1 = require("./_BaseParseObject");
const config_1 = require("../config");
const local_parse_1 = require("../local-parse");
class PharmacyServiceability extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("PharmacyServiceability", attributes);
  }
  static async getByPharmacyObject(pharmacy) {
    return await new local_parse_1.Parse.Query(PharmacyServiceability).equalTo("pharmacy", pharmacy).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async getPharmaciesAroundGeoPoint(location, range, ignoreActive) {
    const query = new local_parse_1.Parse.Query(PharmacyServiceability).withinKilometers("location", location, range, true).include("pharmacy").include("pharmacyUser");
    if (ignoreActive === "Active") query.equalTo("status", ignoreActive);
    return await query.find({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
}
exports.PharmacyServiceability = PharmacyServiceability;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], PharmacyServiceability.prototype, "serviceType", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Number)], PharmacyServiceability.prototype, "radius", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], PharmacyServiceability.prototype, "polygon", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], PharmacyServiceability.prototype, "pharmacy", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], PharmacyServiceability.prototype, "postCodes", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], PharmacyServiceability.prototype, "location", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], PharmacyServiceability.prototype, "priorityMultiplier", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], PharmacyServiceability.prototype, "hub", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], PharmacyServiceability.prototype, "status", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], PharmacyServiceability.prototype, "pharmacyUser", void 0);
local_parse_1.Parse.Object.registerSubclass("PharmacyServiceability", PharmacyServiceability);
