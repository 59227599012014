"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderSettlement = void 0;
const _BaseParseObject_1 = require("./_BaseParseObject");
const config_1 = require("../config");
const local_parse_1 = require("../local-parse");
class OrderSettlement extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("OrderSettlement", attributes);
  }
  static async getByOrderId(order) {
    return await new local_parse_1.Parse.Query(OrderSettlement).equalTo("order", order).first({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
  static async getByPharmacyId(pharmacyId) {
    return await new local_parse_1.Parse.Query(OrderSettlement).equalTo("pharmacy", {
      __type: "Pointer",
      className: "Pharmacy",
      objectId: pharmacyId
    }).findAll({
      useMasterKey: config_1.C2UConfig.useMasterKey
    });
  }
}
exports.OrderSettlement = OrderSettlement;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], OrderSettlement.prototype, "order", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], OrderSettlement.prototype, "pharmacy", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Boolean)], OrderSettlement.prototype, "settled", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Date)], OrderSettlement.prototype, "fulfilledAt", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], OrderSettlement.prototype, "settledAt", void 0);
local_parse_1.Parse.Object.registerSubclass("OrderSettlement", OrderSettlement);
