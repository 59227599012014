<div class="h-screen flex flex-col items-center">
    <!-- <div class="flex flex-col justify-start items-center px-6">
        <div class="max-w-screen-sm w-full min-h-screen flex flex-col items-center gap-4"> -->

    <div class="w-full flex justify-center sticky top-0 left-0 bg-white py-4 z-10">
        <div class="max-w-screen-sm w-full">
            <ng-content select="#header"></ng-content>
        </div>
    </div>

    <div class="w-full flex justify-center flex-grow overflow-y-auto">
        <div class="max-w-screen-sm w-full">
            <ng-content select="#content"></ng-content>
        </div>
    </div>

    <div class="w-full flex justify-center sticky bottom-0 left-0 bg-white border-t px-5 pt-4 pb-6">
        <div class="max-w-screen-sm w-full">
            <ng-content select="#footer"></ng-content>
        </div>
    </div>
</div>