<div>
  <label>
    {{ label() }}
    @if (required()) { <span class="required">*</span> }
  </label>
  @if (help()) {
    <div class="help">{{ help() }}</div>
  }
  <div class="mt-3">
    @for (option of options(); track $index) {
      <span class="radio-label" (click)="select(option.value)">
        <app-radio [checked]="value() === option.value" [canToggle]="false" /> {{ option.label }}
      </span>
    }
  </div>
  @if (errorMessage() && touched()) {
    <span class="error-message">{{ errorMessage() }}</span>
  }
</div>