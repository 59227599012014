"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Promotion = void 0;
const _BaseParseObject_1 = require("./_BaseParseObject");
const local_parse_1 = require("../local-parse");
class Promotion extends _BaseParseObject_1.BaseParseObject {
  get promoRule() {
    let classOTC = this.get('classOTC');
    let classScript = this.get('classScript');
    let classFee = this.get('classFee');
    let type = this.get('type');
    let value = this.get('value');
    let returnString = '';
    if (type == 'amount') {
      returnString += '$' + value;
    }
    if (type == 'percentage') {
      returnString += value + '%';
    }
    returnString += ' off ';
    if (classOTC && !classScript && !classFee) {
      returnString += 'all OTC items';
    }
    if (classOTC && classScript && !classFee) {
      returnString += 'all items (not including shipping)';
    }
    if (classOTC && classScript && classFee) {
      returnString += 'entire order';
    }
    if (!classOTC && classScript && !classFee) {
      returnString += 'all scripts';
    }
    if (!classOTC && classScript && classFee) {
      returnString += 'all scripts & shipping';
    }
    if (!classOTC && !classScript && classFee) {
      returnString += 'shipping';
    }
    if (!classOTC && !classScript && classFee && type == 'percentage' && value == 100) {
      returnString = 'Free shipping';
    }
    return returnString;
  }
  constructor(attributes) {
    super("Promotion", attributes);
  }
}
exports.Promotion = Promotion;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "expiry", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "nrOfUses", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "maxNrUses", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "maxAmountDiscounted", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Number)], Promotion.prototype, "value", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "overallValueDiscounted", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "pharmacySpecific", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "zoneSpecific", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "customerSpecific", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "pharmacy", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "zone", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "customer", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], Promotion.prototype, "code", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "active", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], Promotion.prototype, "type", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "maxOverallValueDiscount", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "classOTC", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "classScript", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "classFee", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], Promotion.prototype, "adminOverride", void 0);
local_parse_1.Parse.Object.registerSubclass("Promotion", Promotion);
