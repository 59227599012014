import { CommonModule } from '@angular/common';
import { Component, computed, inject, output, signal, viewChild } from '@angular/core';
import { TitleBlockComponent } from "../../../../components/title-block/title-block.component";
import { toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { LongtextInputComponent } from '../../../../components/longtext-input/longtext-input.component';
import { RadioSelectComponent } from '../../../../components/radio-select/radio-select.component';
import { AmplitudeService } from '../../../../services/amplitude.service';

export type TMedicalProfileClinicalValue = {
  hasOtherMeds: boolean;
  hasAllergies: boolean;
  otherMeds?: string;
  allergies?: string;
};

@Component({
  selector: 'step-medical-profile-clinical',
  standalone: true,
  imports: [
    CommonModule,
    TitleBlockComponent,
    FormsModule,
    LongtextInputComponent,
    RadioSelectComponent,
  ],
  templateUrl: './medical-profile-clinical.step.html',
  styleUrl: './medical-profile-clinical.step.scss'
})
export class MedicalProfileClinicalStep {
  private $amplitude = inject(AmplitudeService);

  public otherMedsCheck = viewChild<RadioSelectComponent>('otherMedsInp');
  public allergiesCheck = viewChild<RadioSelectComponent>('allergiesInp');

  public valueChange = output<{ value: TMedicalProfileClinicalValue, valid: boolean }>();

  public otherMeds = signal<string | Date | number>("");
  public allergies = signal<string | Date | number>("");

  public values = computed<TMedicalProfileClinicalValue>(() => {
    return {
      hasOtherMeds: this.otherMedsCheck()?.value(),
      hasAllergies: this.allergiesCheck()?.value(),
      ...(this.otherMedsCheck()?.value() == true && {
        otherMeds: this.otherMeds() as string,
      }),
      ...(this.allergiesCheck()?.value() == true && {
        allergies: this.allergies() as string,
      }),
    };
  });

  constructor() {
    this.$amplitude.track('GENERIC_NAVIGATE', { to_page: 'medical_profile_clinical' });
    toObservable(this.values).subscribe(v => {
      this.valueChange.emit({ value: v, valid: this.checkValidity() });
    });
  }

  public checkValidity() {
    const values = this.values();
    const hasOtherMedsValid = values.hasOtherMeds === false || values.hasOtherMeds === true;
    const hasAllergiesValid = values.hasAllergies === false || values.hasAllergies === true;
    const otherMedsValid = !values.hasOwnProperty("otherMeds") || (values.otherMeds as string).trim().length > 0;
    const allergiesValid = !values.hasOwnProperty("allergies") || (values.allergies as string).trim().length > 0;
    return hasOtherMedsValid && hasAllergiesValid && otherMedsValid && allergiesValid;
  }

}
