"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SlideImage = void 0;
const _BaseParseObject_1 = require("./_BaseParseObject");
const local_parse_1 = require("../local-parse");
class SlideImage extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("SlideImage", attributes);
  }
  get new() {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return new Date(this.createdAt) > oneWeekAgo;
  }
}
exports.SlideImage = SlideImage;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Number)], SlideImage.prototype, "order", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", local_parse_1.Parse.File)], SlideImage.prototype, "image", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], SlideImage.prototype, "backgroundColor", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", String)], SlideImage.prototype, "htmlContent", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Boolean)], SlideImage.prototype, "isActive", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], SlideImage.prototype, "url", void 0);
local_parse_1.Parse.Object.registerSubclass("SlideImage", SlideImage);
