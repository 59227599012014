"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = this && this.__metadata || function (k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GooglePostCodeCenters = void 0;
const config_1 = require("../config");
const _BaseParseObject_1 = require("./_BaseParseObject");
const local_parse_1 = require("../local-parse");
class GooglePostCodeCenters extends _BaseParseObject_1.BaseParseObject {
  constructor(attributes) {
    super("GooglePostCodeCenters", attributes);
  }
  static async getStateForPostcode(postcode) {
    try {
      const result = await new local_parse_1.Parse.Query(GooglePostCodeCenters).equalTo("postCode", postcode).first({
        useMasterKey: config_1.C2UConfig.useMasterKey
      });
      if (result) {
        return result.get("state");
      }
      return undefined;
    } catch (error) {
      throw error;
    }
  }
}
exports.GooglePostCodeCenters = GooglePostCodeCenters;
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], GooglePostCodeCenters.prototype, "geopoint", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], GooglePostCodeCenters.prototype, "found", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], GooglePostCodeCenters.prototype, "locality", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], GooglePostCodeCenters.prototype, "postCode", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], GooglePostCodeCenters.prototype, "category", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], GooglePostCodeCenters.prototype, "comment", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], GooglePostCodeCenters.prototype, "state", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], GooglePostCodeCenters.prototype, "pharmacyUser", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], GooglePostCodeCenters.prototype, "serviceability", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], GooglePostCodeCenters.prototype, "pharmacy", void 0);
__decorate([(0, _BaseParseObject_1.Accessor)(), __metadata("design:type", Object)], GooglePostCodeCenters.prototype, "regional", void 0);
local_parse_1.Parse.Object.registerSubclass("GooglePostCodeCenters", GooglePostCodeCenters);
