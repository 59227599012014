import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { InputComponent } from '../input/input.component';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
  selector: 'app-medicare-input',
  templateUrl: './medicare-input.component.html',
  styleUrls: ['./medicare-input.component.scss'],
  standalone: true,
  imports: [CommonModule, InputComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MedicareInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: MedicareInputComponent
    }
  ]
})
export class MedicareInputComponent implements ControlValueAccessor, Validator {
  public touched = signal<boolean>(false);
  public medicareNumberValue = signal<string>("");
  public irnValue = signal<string>("");
  public errors = signal<{ key: string, data: any }[]>([]);

  medicareNumberValueChange(value: any) {
    this.markAsTouched();
    const val = String(value);
    this.medicareNumberValue.set(val);
    setTimeout(() => {
      this.triggerEmit();
    });
  }

  irnValueChange(value: any) {
    this.markAsTouched();
    const val = String(value);
    this.irnValue.set(val);
    setTimeout(() => {
      this.triggerEmit();
    });
  }

  triggerEmit() {
    const medicareCardNumber = Number(this.medicareNumberValue());
    const irn = Number(this.irnValue());
    this.onChange({ medicareCardNumber, irn });
    const errors = this.validate();
    if (errors) {
      const errorArray: { key: string, data: any }[] = [];
      Object.keys(errors).forEach(key => {
        errorArray.push({ key, data: errors[key] });
      });
      this.errors.set(errorArray);
    } else {
      this.errors.set([]);
    }
  }

  validateMedicareNum(): ValidationErrors | null {
    const val = this.medicareNumberValue();
    const valIrn = this.irnValue();
    if (val.length == 0 && valIrn.length == 0) return null;
    if (val.length == 0 && valIrn.length > 0) {
      return {
        length: {
          message: "Medicare number is required.",
          actual: val.length,
        },
      };
    }

    if (val.length != 10) {
      return {
        length: {
          message: "Medicare number should be 10 digits.",
          actual: val.length,
        },
      };
    }

    const patternMatch = /^(\d{8})(\d)/.exec(val);
    if (patternMatch) {
      let base = patternMatch[1];
      let checkDigit = patternMatch[2];
      let total = 0;
      let multipliers = [1, 3, 7, 9, 1, 3, 7, 9];
      for (var i = 0; i < multipliers.length; i++) {
        total += +base[i] * multipliers[i];
      }
      const isValid = (total % 10) === Number(checkDigit);
      if (!isValid) {
        return {
          format: {
            message: "Invalid Medicare number.",
          },
        };
      }
    }
    return null;
  }

  validateIrn(): ValidationErrors | null {
    const val = this.irnValue();
    const valMedicare = this.medicareNumberValue();
    if (val.length == 0 && valMedicare.length == 0) return null;
    if (val.length == 0 && valMedicare.length > 0) {
      return {
        length: {
          message: "Individual ref number is required.",
          actual: val.length,
        },
      };
    }
    const charValid = /^[1-9]$/.test(val);
    if (charValid) return null;
    return {
      format: {
        message: "Individual ref should be a single digit",
        format: "1-9",
        actual: val
      },
    };
  }

  // Form Control

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: { medicareCardNumber: number, irn: number }) {
    if (!value) return;
    this.medicareNumberValue.set(String(value.medicareCardNumber));
    this.irnValue.set(String(value.irn));
  }
  
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched()) {
      this.onTouched();
      this.touched.set(true);
    }
  }
  
  validate(control?: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {
      ...this.validateMedicareNum(),
      ...this.validateIrn(),
    };
    if (Object.keys(errors).length) {
      return errors;
    }
    return null;
  }

}
