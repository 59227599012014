import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, output, signal } from '@angular/core';
import { TitleBlockComponent } from "../../../../components/title-block/title-block.component";
import { InputComponent } from "../../../../components/input/input.component";
import { toObservable } from '@angular/core/rxjs-interop';
import { provideIcons } from '@ng-icons/core';
import { heroDevicePhoneMobile } from '@ng-icons/heroicons/outline';
import { AmplitudeService } from '../../../../services/amplitude.service';

@Component({
  selector: 'step-mobile',
  standalone: true,
  imports: [CommonModule, TitleBlockComponent, InputComponent],
  providers: [provideIcons({ heroDevicePhoneMobile })],
  templateUrl: './mobile.step.html',
  styleUrl: './mobile.step.scss'
})
export class MobileStep {
  private $amplitude = inject(AmplitudeService);

  public inputMobileNumber = input<string>("", { alias: 'mobileNumber' });
  public mobileNumberChange = output<{ value: string, valid: boolean }>();

  public mobileNumber = signal("");
  public mobileNumberError = signal<string | undefined>(undefined);
  public mobileNumberValid = computed(() => !this.mobileNumberError() && this.mobileNumber().length > 0);

  constructor() {
    this.$amplitude.track('GENERIC_NAVIGATE', { to_page: 'mobile' });
    toObservable(this.inputMobileNumber).subscribe(v => this.mobileNumber.set(v));
    toObservable(this.mobileNumber).subscribe(v => this.mobileNumberChange.emit({ value: v, valid: this.mobileNumberValid() }));
  }

  validateMobileNumber(mobile: string) {
    const AUSTRALIAN_MOBILE_REGEX = /^(?:\+61|0)[4-5]\d{8}$/;
    const valid = AUSTRALIAN_MOBILE_REGEX.test(mobile);
    return valid;
  }

  onMobileNumberChange(mobile: any) {
    if (mobile.length > 0) {
      this.mobileNumber.set(mobile);
      const validMobile = this.validateMobileNumber(mobile);
      this.mobileNumberError.set(validMobile ? undefined : 'Invalid mobile number');
    } else {
      this.mobileNumberError.set(undefined);
      this.mobileNumber.set(mobile);
    }
  }
}
