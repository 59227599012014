<app-title-block heading="Confirm order"></app-title-block>
<div class="mt-2">
    <div class="text-primary text-lg font-semibold leading-7">Delivery address</div>
    <div class="mt-4">
        <div class="flex flex-col border rounded-lg p-4 mb-6" [ngClass]="order().additionalNote ? 'pb-2' : 'pb-4'">
            <div class="flex items-start gap-2">
                <ng-icon class="text-2xl text-primary" name="heroMapPinSolid"></ng-icon>
                <div class="text-base text-primary">
                    <div class="font-semibold">{{ addressMainText() }}</div>
                    <div class="mt-1">{{ addressSecondaryText() }}</div>
                </div>
            </div>
            @if (order().additionalNote) {
                <div class="mt-2 pt-2 border-t text-secondary text-sm">{{ order().additionalNote }}</div>
            }
        </div>
    </div>
</div>
<div class="mt-6 text-primary">
    <div class="text-lg font-semibold leading-6">Prescriptions</div>
    <div class="text-base font-normal leading-6 mt-2">
        <span class="text-secondary">To make changes </span>
        <a [ngStyle]="{ color: rxTheme.linkColor() }" href="https://programs.chemist2u.com.au/my-account-wegovy/#faq"><span class="underline" [ngClass]="{ color: rxTheme.linkColor() }">contact us</span></a>
    </div>
    @for (script of order().prescriptions; track $index) {
        @for (item of script.items; track $index) {
            <div class="mt-4">
                <div class="p-4 border rounded-lg">
                    <div class="flex items-start gap-3">
                        <img src="./assets/script-icon.svg" alt="">
                        <div class="overflow-hidden flex-1">
                            <div class="leading-5 text-ellipsis overflow-hidden whitespace-nowrap">{{ item.productName }}</div>
                            <div class="mt-1 text-right font-semibold text-primary">{{ item.productQty * item.productPrice | currency }}</div>
                        </div>
                    </div>
                </div>
            </div>
        }
    }
</div>
@if (showShippingOptions()) {
    <div class="mt-6">
        <div class="text-lg font-semibold leading-6">Shipping options</div>
        @for (method of availableMethods(); track $index) {
            <app-delivery-method-option class="mt-4"
                [order]="order()"
                [fullFillmentMethod]="method"
                [active]="method.selectedMethod.method == fulfilmentMethod()?.selectedMethod?.method"
                (select)="selectMethod($event)"
                (shiftChange)="setShift($event, $index)"
                (atlChange)="setAtl($event)"
            ></app-delivery-method-option>
        }
    </div>
}
@if (rxOrder.orderHasWeightLossScriptItems()) {
    <div class="mt-6 text-primary">
        <div class="text-lg font-semibold leading-6">Shipping terms & conditions</div>
        <div class="mt-4 text-xs">
            Your order will be shipped following the manufacturer's guidelines. To ensure the required temperature range is maintained, your order will be shipped in an insulated box with a cold pack inside (subject to ambient temperatures), ensuring your medication arrives in perfect condition.
        </div>
        <div class="mt-4 text-xs flex items-center gap-2 leading-4 h-8">
            <app-checkbox #termsCheck [(ngModel)]="termsCheckbox"></app-checkbox>
            <div (click)="termsCheck.toggleValue()">I accept shipping and Chemist2u's <a [ngStyle]="{ color: rxTheme.linkColor() }" href="https://chemist2u.com.au/terms-conditions/" class="text-brand underline">terms and conditions</a></div>
        </div>
    </div>
}
<div class="h-4"></div>